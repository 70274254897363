import React from 'react'
//import Three from '../Menblog/Three'
// import Search from '../Menblog/Search'
import { useState,useEffect } from 'react'
import Blogchild from './api'
import axios from 'axios'
import ReactGA from "react-ga4";
import { ConsentContext } from '../../routes'
import { useContext } from 'react'
import { FloatingWhatsApp } from 'react-floating-whatsapp'
import whatsappheader from '../../assets/andent-data/whatsappimg.jpeg'
import { useTranslation } from 'react-i18next'
import InfiniteScroll from "react-infinite-scroll-component";
import { useLocation } from 'react-router-dom'
import { Helmet,HelmetProvider } from 'react-helmet-async'
import {getCanonicalUrl} from '../../utils/canonicalUrl'

function Main() {

  // const {page} = useParams();
  const {t}=useTranslation();
  let [page,setPage]=useState(1);
  let [pages,setPages]=useState(null);
  let [total,setTotal]=useState(null);
  let [blogData,setblogData]=useState([]);
  const value = useContext(ConsentContext)
  const location = useLocation();
  const canonicalUrl = getCanonicalUrl(location)
  const lang = 'en-US';

  const getMessage = (e) => {
    if(value===1){
     /* window.analytics.track("WhatsApp", {
        message : e.target[0].value
      });*/
      ReactGA.event({
        category: "WhatsApp",
        action: "WhatsApp Msg Sent",
        message : e.target[0].value
      });
      window.fbq('trackCustom', 'WhatsApp Msg Sent');
    }
  }

  const waClick = () => {
    if(value===1){
      ReactGA.event({
        category: "WhatsApp",
        action: "WhatsApp Opened",
      });
      window.fbq('trackCustom', 'WhatsApp Opened');
  /*    window.analytics.track("WhatsApp Opened", {
        openedOnPage: window.location.pathname
      });*/
    }
  }


  useEffect(() => {
    // let referrer = document.referrer;
     if(value===1){
       ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: "Blog Page" });
       window.fbq('trackCustom', 'Blog Page');
       /*window.analytics.page('Home',{
         referrer: referrer // Pass the referrer URL here
     });*/
     }
 
  },[value]);
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        const url="https://andent-backend.prodbuilds.com/api/blogs?sort=createdAt%3Adesc&pagination[page]=1&pagination[pageSize]=5"
        const response = await axios.get(url);
        setblogData(response.data.data);
        setPages(response.data.meta.pagination.pageCount);
        setTotal(response.data.meta.pagination.total);
      } catch (error) {
        console.log("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

const fetchMoreData = async () => {
  if (page<pages) {
    setPage(++page);
  }
  try {
    const url=`https://andent-backend.prodbuilds.com/api/blogs?sort=createdAt%3Adesc&pagination[page]=${parseInt(page)}&pagination[pageSize]=5`;
    const response = await axios.get(url);
    setblogData(blogData.concat(response.data.data));
    setPages(response.data.meta.pagination.pageCount);
    setTotal(response.data.meta.pagination.total);
  } catch (error) {
    console.log("Error fetching data:", error);
  }
}

  return (
    <>
    <HelmetProvider>
      <Helmet htmlAttributes={{lang}}>
            <link rel="canonical" href={canonicalUrl} />
            <link rel="alternate" href='https://andent.al/blog/' hrefLang="x-default" />
      </Helmet>
      <div className='andent-padding'>
        <InfiniteScroll
          dataLength={total}
          next={fetchMoreData}
          hasMore={page<pages}
        >
          <Blogchild blogData={blogData}/>
        </InfiniteScroll>
      </div>
      <div style={{height:"auto",width:"auto",zIndex:"1000",position:"absolute",padding:"50px"}}>
        <FloatingWhatsApp avatar={whatsappheader} accountName="Andent" phoneNumber={t("whatsAppNum")} chatMessage={t("whatsAppMsg")} darkMode="true" onClick={waClick} chatboxHeight={450} allowClickAway="true" allowEsc="true" onSubmit={getMessage} buttonStyle={{height:"70px",width:"70px"}}/>
      </div>
      </HelmetProvider>
    </>
  )
}

export default Main