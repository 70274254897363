import React from "react";
import Slider from 'react-slick'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useTranslation } from 'react-i18next';

function Main (){

    const images = require.context('../../assets/andent-data/final pictures', true);
    const imageList = images.keys().map(image => images(image));

    const settings = {
        dots: true,
        fade: true,
        infinite: true,
        autoplay: true,
        speed: 2000,
        autoplaySpeed: 2000,
        slidesToShow: 1,
        slidesToScroll: 1
      };


      const {t}=useTranslation();

      const alttags= [t("beforeafter1"),t("beforeafter2"),t("beforeafter3"),t("beforeafter4"),t("beforeafter5"),t("beforeafter6")
                    ,t("beforeafter7"),t("beforeafter8"),t("beforeafter9"),t("beforeafter10"),t("beforeafter11"),t("beforeafter12")
                    ,t("beforeafter13"),t("beforeafter14"),t("beforeafter15"),t("beforeafter16"),t("beforeafter17"),t("beforeafter18"),
                    t("beforeafter19"),t("beforeafter20")]



    return(
        <div>
        <div className="container before-after-pad">

            <div className="row align-items-center justify-content-center text-center andent-padding">
                <div className="col-lg-12 col-12">
                    <div className="text-center wow fadeInDown animated" data-animation="fadeInDown" data-delay=".4s" >
                    <h2 className='theme-dark mob-heading size-60'>{t("beforeandafter")}</h2>
                    </div>
                </div>
            </div>

            <div className="row justify-content-center text-center mob-bottom-pad">
            <div className="col-lg-12 col-md-12 justify-content-center">
                <Slider {...settings}>
                {imageList.map((image, index) => (
                    <div key={index} className="slider-image-wrapper">
                    <img src={image} alt={alttags[index]} title={alttags[index]} className="slider-image before-after-pic-pad" />
                    </div>
                ))}
                </Slider>
            </div>
            </div>
        </div>
        </div>
    )
}

export default Main;