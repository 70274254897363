import Welcome from '../Slider/Main';
import Review from '../reviews/trustpilot';
import Types from './Types'
import Benefitsnworks from './BenefitsnWorks'
import Exp from '../implants/Exp'
import Steps from './Steps'
import Contact from '../implants/Contactsm'
import Book from '../MenContact-Us/Make';
import { FloatingWhatsApp } from 'react-floating-whatsapp';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet,HelmetProvider } from 'react-helmet-async';
import { useContext } from 'react';
import { ConsentContext } from '../../routes';
import ReactGA from "react-ga4";
import whatsappheader from '../../assets/andent-data/whatsappimg.jpeg'
import { useLocation } from 'react-router-dom'
import {getCanonicalUrl} from '../../utils/canonicalUrl'

function Main (){

  const {t, i18n}=useTranslation();
  const value = useContext(ConsentContext);
  const location = useLocation();
  const canonicalUrl = getCanonicalUrl(location)
  const [lang, setLang] = useState(i18n.language);

  useEffect(() => {
    // Update the lang state whenever the language changes
    const handleLanguageChange = () => {
      setLang(i18n.language);
    };

    i18n.on('languageChanged', handleLanguageChange);

    return () => {
      i18n.off('languageChanged', handleLanguageChange);
    };
  }, [i18n]);

  useEffect(() => {
    if(value===1){
      //window.analytics.page('Orthodontics');
      ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: "Orthodontics Page" });
      window.fbq('trackCustom', 'Orthodontics Page');
    }
  },[value]);

  const getMessage = (e) => {
    if(value===1){
     /* window.analytics.track("WhatsApp", {
        message : e.target[0].value
      });*/
      ReactGA.event({
        category: "WhatsApp",
        action: "WhatsApp Msg Sent",
        message : e.target[0].value
      });
      window.fbq('trackCustom', 'WhatsApp Msg Sent');
    }
  }

  const waClick = () => {
    if(value===1){
      ReactGA.event({
        category: "WhatsApp",
        action: "WhatsApp Opened",
      });
      window.fbq('trackCustom', 'WhatsApp Opened');
  /*    window.analytics.track("WhatsApp Opened", {
        openedOnPage: window.location.pathname
      });*/
    }
  }

    return(
        <div>
          <HelmetProvider>
          <Helmet htmlAttributes={{lang}}>
            <meta name="description" content={t('orthdesc')} />
            <meta name='title' content={t('orthtitle')}/>
            <link rel="canonical" href={canonicalUrl} />
            <link rel="alternate" href='https://andent.al/orthodontics' hrefLang="x-default" />
            <link rel="alternate" href='https://andent.al/it/trattamenti-ortodontici' hrefLang="it" />
            <link rel="alternate" href='https://andent.al/sq/ortodoncia' hrefLang="sq" />
          </Helmet>
            <Welcome heading={t("orthheroh")} description={t("orthherop")}/>
            <Review/>
            <Types/>
            <Benefitsnworks/>
            <Exp/>
            <Steps/>
            <Contact/>
            <Book/>
            <div style={{height:"auto",width:"auto",zIndex:"1000",position:"absolute",padding:"50px"}}>
            <FloatingWhatsApp avatar={whatsappheader} accountName="Andent" phoneNumber={t("whatsAppNum")} chatMessage={t("whatsAppMsg")} darkMode="true" onClick={waClick} chatboxHeight={450} allowClickAway="true" allowEsc="true" onSubmit={getMessage} buttonStyle={{height:"70px",width:"70px"}}/>
            </div>
            </HelmetProvider>
        </div>

    )
}


export default Main;