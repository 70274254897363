import React , {useEffect} from 'react'
import {Route, Routes, useLocation} from 'react-router-dom';
import Header from '../components/Header/Main'
import Footer from '../components/Footer/Main'
import Home from '../components/Home/Main'
import About from '../components/About-Us/Main'
import Contact from '../components/Contact-Us/Main'
import PrivatePolicy from '../components/Policies/PrivatePolicy';
import Blog from '../components/Blog/Main';
import BlogDdeatils from '../components/Blogdetails/Main'
import Implants from '../components/implants/Main'
import BrdigesCrowns from '../components/dentalbridgescrowns/Main'
import GeneralDenstiry from '../components/generaldentistry/Main'
import Tourism from '../components/dentaltourism/Main';
import Orthodontic from '../components/Orthodontics/Main';
import Veneers from '../components/Veneers/Main'
import Dentures from '../components/dentures/Main'
import Testimonial from '../components/Testimonial/Main'
import CookiePolicy from '../components/Policies/CookiePolicy'
import { types,transitions, positions, Provider as AlertProvider } from 'react-alert'
import AlertTemplate from 'react-alert-template-basic'
import { useTranslation } from 'react-i18next';
import CookieConsent from 'react-cookie-consent';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import SitemapXML from '../utils/sitemap';
import BookApt from '../components/Magic-Link/magic';
import axios from 'axios';

export const ConsentContext = React.createContext();

function Index() {
  const location = useLocation();
  const [magic,setMagic] = useState(null);
  const [magicId,setMagicId] = useState(null);
  const [verifyMagic,setVerifyMagic] = useState(false)

  useEffect(()=>{
    if(window.location.pathname==='/privacy-policy-en' || window.location.pathname==='/privacy-policy-it' || window.location.pathname==='/privacy-policy-sq'){
      setMagicId(null)

    }
  },[])

  useEffect(()=>{
    const path = window.location.pathname;
    const paths = path.split("/");
    if (paths.includes('magic')){
        setMagic("english")
        setMagicId(paths[paths.length-1])
    }
    if (paths.includes('magic') && paths.includes('it')){
      setMagic("italian")
      setMagicId(paths[paths.length-1])
    }
    if (paths.includes('magic') && paths.includes('sq')){
      setMagic("albanian")
      setMagicId(paths[paths.length-1])
    }
  },[])

  useEffect(() => {
 
  let url = ""

  if(magicId){
    url = `https://office-backend.andent.al/clients/magic-link/verify/${magicId}`
  }
 
  const fetchData = async () => {
     try {
       const response = await axios.get(url);
       if (response && response.status === 200) {
        setVerifyMagic(true);
      } else {
        setVerifyMagic(false);
      }
     } catch (error) {
        console.log("Waiting for api response",error)
        if (error.response && error.response.status === 400) {
          // Redirect to '/'
          setVerifyMagic(false);
          window.location.href = '/';
        }
     }
   };
   if(magicId){
      fetchData();
   }
 }, [magicId]);

  const path = location.pathname
  useEffect(() => {
    window.scroll(0, 0)
}, [path]);

const options = {
  position: positions.BOTTOM_CENTER,
  timeout: 5000,
  offset: '30px',
  type: types.SUCCESS,
  transition: transitions.SCALE,
  containerStyle: {
    zIndex: 1000, // Set a higher value
  },
};

const [consent,setConsent] = useState((localStorage.getItem('consentValue'))? parseInt((localStorage.getItem('consentValue'))) : 0); // 0 for decline , 1 for accept
const [isOpen, setIsOpen] = useState((localStorage.getItem('consentValue'))? false : consent === 0? true : false);

const accept = () => {
  setConsent(1);
  localStorage.setItem('consentValue',1)
  setIsOpen(false);
}

const decline = () => {
  setConsent(0);
  localStorage.setItem('consentValue',0)
  setIsOpen(false);
}

const {t,i18n}=useTranslation();

const siteWithConsent = {
  opacity:"0.5",
  pointerEvents: "none"
}

const siteWithoutConsent = {
  opacity:"100%",
  pointerEvents :"auto"
}

const siteWithConsent2 = {
  opacity:"1 !important",
  pointerEvents :"none"
}

/*const navigate = useNavigate();

  const language = navigator.language.includes('it')
    ? 'it'
    : navigator.language.includes('en')
    ? 'en-US'
    : 'sq';

  useEffect(() => {
    if (language === 'it' && i18n.language==='it') {
      navigate('/it'); // Use navigate to change the route
    } else if (language === 'sq' && i18n.language==='sq') {
      navigate('/sq'); // Use navigate to change the route
    }
    else if ((language === 'sq' || language==='it') && i18n.language==='en-US') {
      navigate('/'); // Use navigate to change the route
    }
  }, [navigate, language,i18n.language]);*/

  return (
    <>
    {window.location.pathname==='/sitemap'? (
      <Routes>
      <Route path='/sitemap' element={<SitemapXML/>}/>
      </Routes>) : magic? (
        verifyMagic? (
            <Routes>
              {magic==="english"? (
              <Route path={`/magic/${magicId}`} element={<BookApt lang='en-US'/>}/>
              ) : magic==='italian'? (
              <Route path={`/it/magic/${magicId}`} element={<BookApt lang='it'/>}/>
              ) : (
              <Route path={`/sq/magic/${magicId}`} element={<BookApt lang='sq'/>}/>
              )}
              <Route path={`/${t('privacyPolLink')}`} element={<PrivatePolicy/>}/>
          </Routes>
        ) : (
          <div className='row justify-content-center align-items-center' style={{paddingTop:"50px"}}>
            <div className='col-12'>
              <h2>Loading Form....</h2>
            </div>
          </div>
          )
      ) : (
        <AlertProvider template={AlertTemplate} {...options}>
        <ConsentContext.Provider value={consent}>
        <div style={isOpen && location.pathname===`/${t('cookiePath')}`? siteWithConsent2 : isOpen? siteWithConsent : siteWithoutConsent}>
        <Header/>
        <Routes>
          <Route path={`/${t('homelink')}`} element={<Home />}/>
          <Route path={`/${t('dentalimplantsh')}`} element={<Implants/>}/>
          <Route path={`/${t('dentalbridgesh')}`} element={<BrdigesCrowns/>}/>
          <Route path={`/${t('generaldentistryh')}`} element={<GeneralDenstiry/>}/>
          <Route path={`/${t('dentaltourismh')}`} element={<Tourism/>}/>
          <Route path={`/${t('whyush')}`} element={<About />} />
          <Route path={`/${t('orthodonticsh')}`} element={<Orthodontic/>}/>
          <Route path={`/${t('dentalveneersh')}`} element={<Veneers/>}/>
          <Route path={`/${t('denturesh')}`} element={<Dentures/>}/>
          <Route path={`/${t('testimonialsh')}`} element={<Testimonial/>}/>s
          <Route path={`/${t('contactush')}`} element={ <Contact/>} />
          <Route path={`/${t('cookiePath')}`}  element={<CookiePolicy/>}/>
          <Route path={`/${t('privacyPolLink')}`} element={<PrivatePolicy/>}/>
          {/* <Route path="/blog/:page" element={ <Blog/>} />
          <Route path="/blogdetails/:id" element={ <BlogDdeatils/>} /> */}
          <Route path="/blog" element={ <Blog/>} />
          <Route path="/blog/:id" element={ <BlogDdeatils/>} />
        </Routes>
        <Footer/>
        </div>
        </ConsentContext.Provider>
        <CookieConsent 
        style={{zIndex:"1000000",height:"auto",backgroundColor:"#ffffff",color:"#000000",borderColor:"black",borderRadius:"5px",display:"block",border:"3px solid #afdad9"}}
        enableDeclineButton
        flipButtons
        buttonText="I Accept"
        declineButtonText="I Decline"
        onAccept={accept}
        onDecline={decline}
        declineButtonStyle={{color:"#ffffff",backgroundColor:"#F9665E",borderRadius:"5px"}}
        containerClasses='d-flex justify-content-center align-items-center text-center text-md-start' 
        buttonStyle={{color:"#ffffff",backgroundColor:"#3FAEAA",borderRadius:"5px",width:"83px"}}>
          {t("consent")} {i18n.language==="it"? (<Link to={`/${t('cookiePath')}`}> Politica sui cookie</Link>) : (<Link to={`/${t('cookiePath')}`}> Cookies Policy</Link>)}
        </CookieConsent>
        
     {/* Cookie Policy It : Politica sui cookie
                      sq,en-US : Cookies Policy
  
  
        <CookieConsent 
        style={{zIndex:"1000000",height:"auto",backgroundColor:"#afdad6",width:"60%",color:"#000000",borderColor:"black",borderRadius:"5px",position:"fixed",left:"50%",transform: "translate(-48%, -50%)"}}
        enableDeclineButton
        declineButtonStyle={{color:"#ffffff",backgroundColor:"#F9665E",borderRadius:"5px",position:"fixed",left:"73%"}}
        containerClasses='d-flex justify-content-center align-items-center' 
        buttonStyle={{color:"#ffffff",backgroundColor:"#F9665E",borderRadius:"5px"}}>
          {t("consent")}
        </CookieConsent> */}
      </AlertProvider>
      )
    }
    
    </>
  )
}

export default Index