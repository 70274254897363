import React from 'react'
import b1 from '../../assets/andent-data/b1.png'
import b2 from '../../assets/andent-data/b2.png'
import b3 from '../../assets/andent-data/b3.png'
import b4 from '../../assets/andent-data/b4.png'
import b5 from '../../assets/andent-data/b5.png'
import Slider from 'react-slick'
import { useState,useEffect } from 'react'
import { useTranslation } from 'react-i18next'

function Main (){

    const [isMobile, setIsMobile] = useState(false);
    const {t,i18n}=useTranslation();
    const [left,setLeft] = useState(0);
    const [left2,setLeft2] = useState(0);
    const [left3,setLeft3] = useState(0);
    const [pad,setPad]=useState(0);
    const [pad2,setPad2]=useState(0);
    const [pad3,setPad3]=useState(0);
    const [pad4,setPad4]=useState(0);

    

    // Choose the screen size 
    const handleResize = () => {
    if (window.innerWidth < 1400) {
        setIsMobile(true);
    } else {
        setIsMobile(false);
    }
    }

    // Create an event listener
    useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
        window.removeEventListener("resize", handleResize);
    };
    },[]);

    const customStyle={
        position : "relative",
        left : `${left}px`,
        lineHeight:"20px"
    }

    const customStyleTwo={
        position : "relative",
        left : `${left2}px` ,
        lineHeight:"20px"
    }

    const customStyleThree={
        position : "relative",
        left : `${left3}px`,
        lineHeight:"20px"
    }

    const paddStyle = {
        paddingTop:`${pad}px`
    }

    const paddStyle2 = {
        paddingTop:`${pad2}px`
    }

    const paddStyle3 = {
        paddingTop:`${pad3}px`
    }

    const paddStyle4 = {
        paddingTop:`${pad4}px`
    }

    useEffect(() => {
    //"translate(-5px,-5px)"
        const lng= i18n.language;

        if (lng==='it') {
            if(window.innerWidth>=1400){
                setLeft(30)
                setLeft2(30)
                setLeft3(5)
                setPad(30)
                setPad2(50)
                setPad3(35)
                setPad4(35)
            }
            else if (window.innerWidth>=786){
                setPad(65)
                setPad2(45)
                setPad3(50)
                setPad4(70)
            }
            else{

            }
            
        }
        else if ( lng==='sq'){
            if(window.innerWidth>=1400){
                setPad(80)
                setPad2(75)
                setPad3(35)
                setPad4(13)
                setLeft(30)
                setLeft2(80)
                setLeft3(20)
            }
            else if (window.innerWidth>=786){
                setPad(60)
                setPad2(40)
                setPad3(35)
                setPad4(13)
            }
            else{
                
            }
            
        }
        else if ( lng==='en-US' && window.innerWidth>=1400){
            setLeft(0)
            setLeft2(20)
            setPad(30)
            setPad2(45)
            setPad3(30)
            setPad4(25)
        }
        else{
            setLeft(0)
            setLeft2(0)
        }

    },[i18n,setLeft2,setLeft,isMobile,setLeft3]);

    const settings = {
    dots: true,
    swipeToSlide : true,
    infinite: true,
    autoplay: false,
    speed: 1000,
    autoplaySpeed: 4000,
    cssEase: "linear",
    pauseOnHover: false,
    slidesToShow: window.innerWidth <= 786 ? 1 : window.innerWidth < 1024 ? 2 : 3,
    slidesToScroll: window.innerWidth <= 786 ? 1 : window.innerWidth < 1024 ? 2 : 1
    };

    return(
        <div>
            <section className="andent-padding">
            <div className="container">
                <div className="row align-items-center">
                <div className="col-lg-12">
                    <div className="section-title center-align mb-50 text-center wow fadeInDown animated" data-animation="fadeInDown" data-delay=".4s" >
                    <h2 className='theme-dark mob-heading'>{t("implantsbenefits")}</h2>
                    </div>
                </div>
                </div>
                <div className="row justify-content0-around">
                <div style={{touchAction:"none"}}>
                <Slider {...settings}>
                <div className="col-lg-2 col-md-6 d-flex justify-content-center">
                    <div className="fadeInUp animated" data-animation="fadeInUp" data-delay=".4s" >
                        <div style={{paddingTop:"20px"}}>
                        <h3 className='theme-dark size-18' style={{textAlign:"center"}}>{t("impben1")}</h3>
                        </div>
                        <div className='andent-sq-icon-text slider-text-dim'>
                            <p className='theme-dark size-16 text-center offset-fix-1' style={customStyleTwo}>
                            {t("impben1p")}
                            </p>
                        </div>
                        <div className='d-flex justify-content-center upper-padding' style={paddStyle2}>
                        <img src={b1} alt="dt1" className='andent-icon-sq'/>
                        </div>
                    </div>
                </div>
                <div className="col-lg-2 col-md-6 d-flex justify-content-center">
                    <div className="fadeInUp animated" data-animation="fadeInUp" data-delay=".4s" >
                        <div style={{paddingTop:"20px"}}>
                        <h3 className='theme-dark size-18' style={{textAlign:"center"}}>{t("impben2")}</h3>
                        </div>
                        <div className='andent-sq-icon-text slider-text-dim'>
                            <p className='theme-dark size-16 text-center' style={{lineHeight:"20px"}}>
                            {t("impben2p")}
                            </p>
                        </div>
                        <div className='d-flex justify-content-center' style={paddStyle}>
                        <img src={b2} alt="dt1" className='andent-icon-sq'/>
                        </div>
                    </div>
                </div>
                <div className="col-lg-2 col-md-6 d-flex justify-content-center">
                    <div className="fadeInUp animated" data-animation="fadeInUp" data-delay=".4s" >
                        <div style={{paddingTop:"20px"}}>
                        <h3 className='theme-dark size-18' style={{textAlign:"center"}}>{t("impben3")}</h3>
                        </div>
                        <div className='andent-sq-icon-text slider-text-dim offset-fix-2' style={{justifyContent:"center !important"}} >
                            <p className='theme-dark size-16 text-center' style={customStyle}>
                            {t("impben3p")}
                            </p>
                        </div>
                        <div className='d-flex justify-content-center upper-padding-impben2'>
                        <img src={b3} alt="dt1" className='andent-icon-sq'/>
                        </div>
                    </div>
                </div>
                <div className="col-lg-2 col-md-6 d-flex justify-content-center">
                    <div className="fadeInUp animated" data-animation="fadeInUp" data-delay=".4s">
                        <div style={{paddingTop:"20px"}}>
                        <h3 className='theme-dark size-18' style={{textAlign:"center"}}>{t("impben4")}</h3>
                        </div>
                        <div className='andent-sq-icon-text slider-text-dim'>
                            <p className='theme-dark size-16 text-center ' style={customStyleThree}>
                            {t("impben4p")}
                            </p>
                        </div>
                        <div className='d-flex justify-content-center' style={paddStyle3}>
                        <img src={b4} alt="dt1" className='andent-icon-sq'/>
                        </div>
                    </div>
                </div>
                <div className="col-lg-2 col-md-6 d-flex justify-content-center">
                    <div className="fadeInUp animated" data-animation="fadeInUp" data-delay=".4s" >
                        <div style={{paddingTop:"20px"}}>
                        <h3 className='theme-dark size-18' style={{textAlign:"center"}}>{t("impben5")}</h3>
                        </div>
                        <div className='andent-sq-icon-text slider-text-dim' style={customStyleTwo}>
                            <p className='theme-dark size-16 text-center' style={{lineHeight:"20px"}}>
                            {t("impben5p")}
                            </p>
                        </div>
                        <div className='d-flex justify-content-center' style={paddStyle4}>
                        <img src={b5} alt="dt1" className='andent-icon-sq'/>
                        </div>
                    </div>
                </div>
                </Slider>
                </div>
                </div>
                </div>
        </section>
        </div>
    )
}

export default Main;