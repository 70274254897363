
import React from 'react'
import b1 from '../../assets/andent-data/b5.png'
import b2 from '../../assets/andent-data/b1.png'
import b3 from '../../assets/andent-data/s3.png'
import b5 from '../../assets/andent-data/s4.png'
import b6 from '../../assets/andent-data/b6.png'
import Slider from 'react-slick'
import { useState,useEffect } from 'react'
import { useTranslation } from 'react-i18next'

function Main (){

    const [isMobile, setIsMobile] = useState(false);
    const [isTablet, setIsTablet] = useState(false);
    const [left2,setLeft2] = useState(0);
    const {t,i18n}=useTranslation();
    const [left,setLeft] = useState(0);
    const [pad,setPad]=useState(30);
    const [padVal,setPadVal] = useState(0)
    const [padVal2,setPadVal2] = useState("")
    const [padVal3,setPadVal3] = useState("")
    const [padVal4,setPadVal4] = useState("")

    const customStyle={
        position : "relative",
        right : `${left}px`,
        lineHeight:"20px"
    }

    const customStyleTwo={
        position : "relative",
        left : `${left}px`,
        lineHeight:"20px"
    }

    const customStyleThree={
        position : "relative",
        left : `${left2}px`,
        lineHeight:"20px"
    }

    const padStyle = {
        paddingTop:`${pad}px`
    }

    useEffect(() => {
    //"translate(-5px,-5px)"
        const lng= i18n.language;

        if (lng==='it' && window.innerWidth>=1400) {
            setPadVal2("15px")
            setPadVal("20px")
            setPad(30)
            setLeft(35)
            setLeft2(15)
        }
        else if (lng==='sq' && window.innerWidth>=1400){
            setPadVal4("-15px")
            setLeft(0)
            setPad(70)
            setPadVal("20px")
        }
        else if (lng==='en-US' && window.innerWidth>=1400){
            setPadVal("15px")
            setPadVal2("-5px")
            setPadVal3("5px")
            setLeft(0)
            setPad(70)
        }
        else{
            setLeft(0)
        }

    },[i18n,isMobile]);

    // Choose the screen size
    const handleResize = () => {
        const screenWidth = window.innerWidth;
        if (screenWidth < 720) { // Mobile devices
        setIsMobile(true);
        setIsTablet(false);
        } else if (screenWidth < 1024) { // Tablets
        setIsMobile(false);
        setIsTablet(true);
        } else { // Large screens
        setIsMobile(false);
        setIsTablet(false);
        }
    };

    // Create an event listener
    useEffect(() => {
        handleResize(); // Initial check
        window.addEventListener('resize', handleResize);

        return () => {
        window.removeEventListener('resize', handleResize);
        };
    }, []);

    const settings = {
    dots: true,
    swipeToSlide : true,
    infinite: true,
    autoplay: false,
    speed: 1000,
    autoplaySpeed: 4000,
    cssEase: "linear",
    pauseOnHover: false,
    slidesToShow: window.innerWidth <= 786 ? 1 : isTablet ? 2 : 3,
    slidesToScroll: window.innerWidth <= 786 ? 1 : isTablet ? 2 : 1
    };

    return(
        <div>
            <section id="blog" className="andent-padding">
            <div className="container">
                <div className="row align-items-center">
                <div className="col-lg-12">
                    <div className="section-title center-align mb-50 text-center wow fadeInDown animated" data-animation="fadeInDown" data-delay=".4s" >
                    <h2 className='theme-dark mob-heading'>{t("bridgesimportance")}</h2>
                    </div>
                </div>
                </div>
                <div className="row justify-content-center">
                <div style={{touchAction:"none"}}>
                <Slider {...settings}>
                <div className="col-lg-2 col-md-6 d-flex justify-content-center">
                    <div className="fadeInUp animated" data-animation="fadeInUp" data-delay=".4s" >
                        <div style={{paddingTop:"20px"}}>
                        <h3 className='theme-dark size-18' style={{textAlign:"center"}}>{t("bridgesimp1")}</h3>
                        </div>
                        <div className='andent-sq-icon-text slider-text-dim'>
                            <p className='theme-dark size-16 text-center' style={customStyleTwo}>
                            {t("bridgesimp1p")}
                            </p>
                        </div>
                        <div className='d-flex justify-content-center upper-padding' >
                        <img src={b1} alt="dt1" className='andent-icon-sq' style={{position:"relative",bottom:`${padVal}`}}/>
                        </div>
                    </div>
                </div>
                <div className="col-lg-2 col-md-6 d-flex justify-content-center">
                    <div className="fadeInUp animated" data-animation="fadeInUp" data-delay=".4s" >
                        <div style={{paddingTop:"20px"}}>
                        <h3 className='theme-dark size-18' style={{textAlign:"center"}}>{t("bridgesimp2")}</h3>
                        </div>
                        <div className='andent-sq-icon-text slider-text-dim'>
                            <p className='theme-dark size-16 text-center' style={{lineHeight:"20px"}}>
                            {t("bridgesimp2p")}
                            </p>
                        </div>
                        <div className='d-flex justify-content-center' style={padStyle}>
                        <img src={b6} alt="dt1" className='andent-icon-sq'/>
                        </div>
                    </div>
                </div>
                <div className="col-lg-2 col-md-6 d-flex justify-content-center text-center">
                    <div className="fadeInUp animated" data-animation="fadeInUp" data-delay=".4s" >
                        <div style={{paddingTop:"20px"}}>
                        <h3 className='theme-dark size-18' style={{textAlign:"center"}}>{t("bridgesimp3")}</h3>
                        </div>
                        <div className='andent-sq-icon-text slider-text-dim text-center offset-fix'>
                            <p className='theme-dark size-16 text-center' style={customStyle}>
                            {t("bridgesimp3p")}
                            </p>
                        </div>
                        <div className='d-flex justify-content-center upper-padding'>
                        <img src={b3} alt="dt1" className='andent-icon-sq' style={{position:"relative",bottom:`${padVal4}`}}/>
                        </div>
                    </div>
                </div>
                <div className="col-lg-2 col-md-6 d-flex justify-content-center">
                    <div className="fadeInUp animated" data-animation="fadeInUp" data-delay=".4s" >
                        <div style={{paddingTop:"20px"}}>
                        <h3 className='theme-dark size-18' style={{textAlign:"center"}}>{t("bridgesimp4")}</h3>
                        </div>
                        <div className='andent-sq-icon-text slider-text-dim'>
                            <p className='theme-dark size-16 text-center' style={{lineHeight:"20px"}}>
                            {t("bridgesimp4p")}
                            </p>
                        </div>
                        <div className='d-flex justify-content-center upper-padding' style={{position:"relative",bottom:`${padVal2}`}}>
                        <img src={b2} alt="dt1" className='andent-icon-sq'/>
                        </div>
                    </div>
                </div>
                <div className="col-lg-2 col-md-6 d-flex justify-content-center">
                    <div className="fadeInUp animated" data-animation="fadeInUp" data-delay=".4s" >
                        <div style={{paddingTop:"20px"}}>
                        <h3 className='theme-dark size-18' style={{textAlign:"center"}}>{t("bridgesimp5")}</h3>
                        </div>
                        <div className='andent-sq-icon-text slider-text-dim'>
                            <p className='theme-dark size-16 text-center' style={customStyleThree}>
                            {t("bridgesimp5p")}
                            </p>
                        </div>
                        <div className='d-flex justify-content-center upper-padding ' style={{position:"relative",bottom:`${padVal3}`}}>
                        <img src={b5} alt="dt1" className='andent-icon-sq' />
                        </div>
                    </div>
                </div>
                    </Slider>
                    </div>
                </div>
                </div>
        </section>
        </div>
    )
}

export default Main;