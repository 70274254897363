import React from 'react'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
//import { useState } from 'react';
//import { useAlert } from 'react-alert';
import imgleft1 from '../../assets/andent-data/herosectionimg/Images/slider image 1.png'
import imgleft2 from '../../assets/andent-data/herosectionimg/Images/slider image 5.png'
import imgleft3 from '../../assets/andent-data/herosectionimg/Images/slider image 2.png'
import imgright3 from '../../assets/andent-data/herosectionimg/Images/slider image 7.png'
import filler from '../../assets/andent-data/herosectionimg/Images/last image 200px.png'
import newimgl1 from '../../assets/andent-data/herosectionimg/Images/newimgleft1.jpg'
import newimgl2 from '../../assets/andent-data/herosectionimg/Images/newimgleft2.png'
import newimgr1 from '../../assets/andent-data/herosectionimg/Images/newimgright1.jpg'
import newimgr2 from '../../assets/andent-data/herosectionimg/Images/newimgright2.jpg'
import newimgr3 from '../../assets/andent-data/herosectionimg/Images/newimgright3.jpg'
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useContext } from 'react';
import { ConsentContext } from '../../routes';
import ReactGA from "react-ga4";

function Main() {

    

   //   const [number,setNumber] = useState("")
   //   const [name,setName] = useState("")
    //  const alert=useAlert();

      const {t}=useTranslation();



    /*  const changeName = (e) => {
          setName(e.target.value)
      }
  
      const changeNum = (e) => {
          setNumber(e.target.value)
      }
  */
      const value = useContext(ConsentContext);
      const contactUs = async (e) => {
        e.preventDefault();
        if(value===1){
          /*  window.analytics.track("Link clicked", {
            buttonText: e.currentTarget.title,
            link: e.currentTarget.href,
            clickedOnPage: window.location.pathname
          });*/
          ReactGA.event({
            category: "Clicked on Link",
            action: `Link Clicked : ${e.currentTarget.title}`
          });
        }
    }


  return (
    <>
        <section id="home" className="slider-area fix p-relative hero-md-pad">
            <div className="slider-active">
            <div className="single-slider slider-bg d-flex align-items-center" style={{backgroundImage: "url(img/slider/slider_bg.png)", backgroundSize: "cover"}}>
                <div className="container">
                <div className="row justify-content-around align-items-end justify-content-md-start">
                    <div className="col-lg-6 col-md-11 hero-left-pos">
                    <div className="slider-content s-slider-content mt-20 text-center text-lg-start text-md-start">
                        <h5 data-animation="fadeInUp" data-delay=".4s" className='theme' style={{color:"#6DA7A2"}}>
                        {t("welcome")}
                        </h5>
                        <h1 data-animation="fadeInUp" data-delay=".4s" style={{color:"#4E4E50"}} className='mob-hero1'>
                        {t("testhero")}
                        </h1>
                        <h2 data-animation="fadeInUp" data-delay=".4s" style={{color:"#F9665E",fontSize:"60px"}} className='mob-hero2'>
                        {t("testherored")}
                        </h2>
                        <div>
                        <p data-animation="fadeInUp" data-delay=".6s" style={{color:"#000000",lineHeight:"17pt",fontSize:"16px"}}>
                        {t("testp1")} <br/><br/>
                        {t("testp3")}
                        </p>
                        </div>
                        <br/>
                        <br/>
                        <div className="col-lg-4 col-md-4 text-center text-md-start tourism-hero-pad hero-contact-botpad">
                        <button className="btn" style={{height:"66px"}} title='ContactUs' onClick={contactUs}>
                            <Link to={`/${t('contactush')}`}>
                            <p style={{ color: "white" }}>
                            {t("contactus")}
                            </p>
                            </Link>
                        </button>
                        </div>
                    </div>
                    </div>
                    <div className="col-lg-6 col-md-12 col-12 slider-container d-flex justify-content-lg-start upper-padding hero-right-pos" >
                        <div className="row justify-content-lg-center justify-content-start">
                        <div className="slider-content-new col-lg-6 col-6 text-lg-center slider-left justify-content-lg-center">
                            <img className='slider-content-dim' alt={t("heroimg5")} title={t("heroimg5")} src={newimgr1}></img>
                            <img className='slider-content-dim' alt={t("heroimg1")} title={t("heroimg1")} src={imgleft1}></img>
                            <video className='slider-content-vid-dim' autoPlay="autoplay" controls={false} loop muted style={{pointerEvents:"none"}} playsInline>
                                <source src="https://d2tn9nq6wb6bbc.cloudfront.net/andent-media/video+1.mov"  type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                            <img className='slider-content-dim' alt={t("heroimg1")} title={t("heroimg1")} src={newimgl2}></img>
                            <img className='slider-content-dim' alt={t("heroimg2")} title={t("heroimg2")} src={imgleft3}></img>
                            <video className='slider-content-vid-dim' autoPlay="autoplay" controls={false} loop muted style={{pointerEvents:"none"}} playsInline>
                                <source src="https://d2tn9nq6wb6bbc.cloudfront.net/andent-media/video+2.mov"  type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                            <img className='slider-content-dim' alt={t("heroimg3")} title={t("heroimg3")} src={imgleft2}></img>
                            <img className='slider-content-left-filler-dim' alt='imgrightfiller' src={filler}></img>
                        </div>
                        <div className="slider-content-new col-lg-6 col-6 text-lg-center slider-right justify-content-lg-center" >
                            <img className='slider-content-filler-dim' alt='imgrightfiller' src={filler}></img>
                            <video className='slider-content-vid-dim' autoPlay="autoplay" controls={false} loop muted style={{pointerEvents:"none"}} playsInline>
                                <source src="https://d2tn9nq6wb6bbc.cloudfront.net/andent-media/compressed/Slider+Video.mov" type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                            <img className='slider-content-dim' alt={t("heroimg1")} title={t("heroimg1")} src={newimgl1}></img>
                            <img className='slider-content-dim' alt={t("heroimg5")} title={t("heroimg5")} src={newimgr2}></img>
                            <video className='slider-content-vid-dim' autoPlay="autoplay" controls={false} loop muted style={{pointerEvents:"none"}} playsInline>
                                <source src="https://d2tn9nq6wb6bbc.cloudfront.net/andent-media/video+3.mov"  type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                            <img className='slider-content-dim' alt={t("heroimg8")} title={t("heroimg8")} src={imgright3}></img>
                            <img className='slider-content-dim' alt={t("heroimg5")} title={t("heroimg5")} src={newimgr3}></img>
                            <video className='slider-content-vid-dim' autoPlay="autoplay" controls={false} loop muted style={{pointerEvents:"none"}} playsInline>
                                <source src="https://d2tn9nq6wb6bbc.cloudfront.net/andent-media/video+4.mov"  type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                        </div>
                        </div>
                    </div>
                </div>
                </div>
            </div>
            </div>
        </section>
    </>
  )
}

export default Main