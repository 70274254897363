import './App.css';
import { BrowserRouter as Router } from "react-router-dom"
import Routing from './routes';
import ReactGA from "react-ga4";
import {RemoveTrailingSlash} from "./utils/RemoveTrailingSlash"

function App() {

  

  if (localStorage.getItem('selectedLanguage')) {
    // If the key is found, clear it
    localStorage.removeItem('selectedLanguage');
  }

  const TRACKING_ID="G-ZH339LTS7B"

  ReactGA.initialize(TRACKING_ID);



  return (
   <> 
      <Router >
          <RemoveTrailingSlash />
          <Routing />
      </Router>
   </>
  );
}

export default App;
