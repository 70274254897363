import Top from './Top'
import Testimonials from './Testimonials'
import BeforeAfter from './BeforeAfter'
import Book from '../MenContact-Us/Make'
import { FloatingWhatsApp } from 'react-floating-whatsapp'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Helmet,HelmetProvider } from 'react-helmet-async'
import { useContext } from 'react'
import { ConsentContext } from '../../routes'
import ReactGA from "react-ga4";
import whatsappheader from '../../assets/andent-data/whatsappimg.jpeg'
import { useLocation } from 'react-router-dom'
import {getCanonicalUrl} from '../../utils/canonicalUrl'

function Main (){

    const {t, i18n}=useTranslation();
    const value = useContext(ConsentContext);
    const location = useLocation();
    const canonicalUrl = getCanonicalUrl(location)
    const [lang, setLang] = useState(i18n.language)

    useEffect(() => {
      // Update the lang state whenever the language changes
      const handleLanguageChange = () => {
        setLang(i18n.language);
      };
  
      i18n.on('languageChanged', handleLanguageChange);
  
      return () => {
        i18n.off('languageChanged', handleLanguageChange);
      };
    }, [i18n]);

    useEffect(() => {
        if(value===1){
        //  window.analytics.page('Testimonials');
        ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: "Testimonials Page" });
        window.fbq('trackCustom', 'Testimonials Page');
        }
      },[value]);

      
      const getMessage = (e) => {
        if(value===1){
         /* window.analytics.track("WhatsApp", {
            message : e.target[0].value
          });*/
          ReactGA.event({
            category: "WhatsApp",
            action: "WhatsApp Msg Sent",
            message : e.target[0].value
          });
          window.fbq('trackCustom', 'WhatsApp Msg Sent');
        }
      }
    
      const waClick = () => {
        if(value===1){
          ReactGA.event({
            category: "WhatsApp",
            action: "WhatsApp Opened",
          });
          window.fbq('trackCustom', 'WhatsApp Opened');
      /*    window.analytics.track("WhatsApp Opened", {
            openedOnPage: window.location.pathname
          });*/
        }
      }

    return(
        <div>
          <HelmetProvider>
          <Helmet htmlAttributes={{lang}}>
            <meta name="description" content={t('testdesc')} />
            <meta name='title' content={t('testtitle')}/>
            <link rel="canonical" href={canonicalUrl} />
            <link rel="alternate" href='https://andent.al/testimonials/' hrefLang="x-default" />
            <link rel="alternate" href='https://andent.al/it/testimonianze/' hrefLang="it" />
            <link rel="alternate" href='https://andent.al/sq/dëshmitë/' hrefLang="sq" />
          </Helmet>
            <Top/>
            <Testimonials/>
            <BeforeAfter/>
            <Book/>
            <div style={{height:"auto",width:"auto",zIndex:"1000",position:"absolute",padding:"50px"}}>
            <FloatingWhatsApp avatar={whatsappheader} accountName="Andent" phoneNumber={t("whatsAppNum")} chatMessage={t("whatsAppMsg")} darkMode="true" onClick={waClick} chatboxHeight={450} allowClickAway="true" allowEsc="true" onSubmit={getMessage} buttonStyle={{height:"70px",width:"70px"}}/>
            </div>
            </HelmetProvider>
        </div>
    )
}

export default Main;