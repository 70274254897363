import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

// don't want to use this?
// have a look at the Quick start guide 
// for passing in lng and translations on init
const newLang = localStorage.getItem('selectedLanguage')
let language = newLang ? newLang : navigator.language.includes('it')? 'it' : navigator.language.includes('en')? 'en-US' : navigator.language;
const urlPath= window.location.pathname;
const pathSegments = urlPath.split('/');
const langParam= pathSegments[1]
if (langParam==="it"){
  language= 'it'
}

if (langParam==="sq"){
  language= 'sq'
}

if (langParam !== 'sq' && langParam !== 'it' && urlPath!=="/") {
  language= 'en-US'
}

i18n
  // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-http-backend
  // want your translations to be loaded from a professional CDN? => https://github.com/locize/react-tutorial#step-2---use-the-locize-cdn
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    fallbackLng: 'en',
    lng : language,
    debug: true,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources : {
        en : {
            translation: {"test" : "testing i18n translation thingy",
              "home" : "Home",
              "implants" : "Dental Implants",
              "dentalbridges" : "Dental Bridges",
              "aboutus" : "Why Us",
              "tourism" : "Dental Tourism",
              "testimonial" : "Testimonials",
              "blog" : "Blog",
              "otherserv" : "Other Services",
              "orthodontics" : "Orthodontics",
              "general" : "General Dentistry",
              "dentures" : "Dentures",
              "veneers" : "Dental Veneers",
              "contactus" : "Contact Us",
              "ourservices" : "Our Services",
              "welcome" : "Welcome To Andent",
              "homehero" : "Tirana's Top Dental Clinic for",
              "homehero2" : "",
              "homeheroh2" : "Quality and",
              "homeheroh22" : "Comfort",
              "homeherop" : "We are devoted to delivering remarkable results for all of our orthodontic treatments. Take the first step towards your dream smile with our personalized care",
              "yourphone" : "Your Phone Number...",
              "yourname" : "Your Name...",
              "callback" : "Get Call Back",
              "reviews" : "See What Others Have to Say",
              "servicesh3" : "Experience These And More",
              "servicesh31":"Experience These And More",
              "servicesh2" : "Dental Treatments",
              "service1" : "Dental Implants",
              "service2" : "Dental Crowns and Bridges",
              "service3" : "Dentures",
              "service4" : "Cosmetic Dentistry and Veneers",
              "service5" : "Orthodontic Treatment",
              "service6" : "General Dentistry",
              "service1p" : "Durable, artificial tooth roots to support natural-looking replacement teeth.",
              "service2p" : "High-quality prosthetics for damaged or missing teeth, ensuring durability.",
              "service3p" : "Custom-made replacements for comfortable chewing, speaking, and smiling.",
              "service4p" : "Transform your smile with whitening, veneers, and personalized makeovers.",
              "service5p" : "Achieve straighter teeth and correct bites with orthodontic treatments, including Invisalign",
              "service6p" : "Preventive care, treatments, and maintenance for optimal oral health and a beautiful smile.",
              "aboutUs" : "About Us",
              "whyandent" : "Why Andent?",
              "whyandentp" : "Your Trusted Dental Clinic in Albania. With 10+ years of expertise, our renowned dentist Dr. Anduela Çurmaku leads our exceptional team. From painless implants to advanced treatments, our precision, safety, and top-quality equipment ensure optimal oral health. Trust our skilled team for a confident smile.",
              "whyandentp2" : "",
              "learnmore" : "Learn More",
              "meetTeam" : "Meet Our Dental Team",
              "clerk": "Front desk clerk",
              "dentist": "Dentistry",
              "secretary": "Secretary",
              "tech": "Dental technician",
              "dentaltourismcoord" : "Dental Tourism Clinic Coordinator",
              "assistant" : "Dental Assistant",
              "staff1" : "Anduela Curmaku",
              "staff2" : "Blerta Curmaku",
              "staff3": "Ermal Cuadari",
              "staff4" : "Laurent Bixheku",
              "staff5" : "Leonard Maci",
              "staff6" : "Manuela Manjani",
              "staff7" : "Marvin Dedaj",
              "staff8" : "Rudina Bitri",
              "staff9" : "Safjela Baja",
              "makethemosttrip" : "Make The Most Out Of Your Trip",
              "freeaccom": "Free accommodation in a 4-star hotel in the heart of Tirana",
              "freeaccomp": "Allowing you to immerse yourself in the vibrant life and captivating attractions while receiving top-tier dental services.",
              "assistancewithtravel": "Assistance with your dental travel experience from start to finish",
              "assistancewithtravelp": "Services like picking you up and dropping you off from the airport, scheduling appointments, reminders, translations, and more.",
              "traveltips": "Travel tips and tours to make the most of your trip",
              "traveltipsp": "To ensure you're getting the most out of your trip, we will personalise your journey based on your likes and dislikes.",
              "test1name": "Gaetano",
              "test1desc": "Dentures are removable dental appliances that are crafted to resemble your natural teeth and gums. They are custom-made to fit your unique mouth shape and are designed to restore your smile and ability to chew and speak properly. Dentures are made from durable materials that are both comfortable and functional.",
              "test2name": "Roberta",
              "test2desc": "Dentures are removable dental appliances that are crafted to resemble your natural teeth and gums. They are custom-made to fit your unique mouth shape and are designed to restore your smile and ability to chew and speak properly. Dentures are made from durable materials that are both comfortable and functional.",
              "bookaptmnt": "Contact Us",
              "fname": "First Name",
              "lname": "Last Name",
              "email": "Email",
              "number": "Phone Number",
              "comments": "Write Comments",
              "file": "Upload Dental Scan or X-Ray; Png, Jpeg, Pdf",
              "formpolicy1": "By submitting this form you agree to our privacy policy as well as to receive marketing emails from Andent. You can unsubscribe from these communications at any time.",
              "formpolicy2": "",
              "upload": "Upload",
              "uploaded": "Uploaded!",
              "booknow": "Book Now",
              "address": "Clinic Address",
              "phnum": "Phone Number:",
              "saveupto70": "Save Up to 70%",
              "implantshero": "Discover Exceptional Dental Implants",
              "implantsherop": "Say goodbye to the challenges of missing or damaged teeth. We restore your smile with our affordable and long-lasting implants for improved oral health.",
              "implantsbenefits": "Benefits of Dental Implants",
              "impben1": "Permanent and Sturdy Foundation",
              "impben1p": "Dental implants provide a long-lasting solution for replacing missing teeth, offering a secure and stable base.",
              "impben2": "Improved Functionality",
              "impben2p": "With dental implants, you can regain full chewing ability and restore proper speech, enhancing your overall quality of life.",
              "impben3": "Prevents Dental Complications",
              "impben3p": "By replacing missing teeth, dental implants prevent surrounding teeth from shifting, maintaining proper bite alignment and avoiding potential jawbone deterioration.",
              "impben4": "Preserves Dental Structure",
              "impben4p": "Dental implants help preserve the integrity of your dental structure, preventing the loss of adjacent teeth and supporting optimal oral health.",
              "impben5": "Stimulates Jawbone Health",
              "impben5p": "Implants stimulate the jawbone, promoting healthy bone growth and preventing bone loss commonly associated with missing teeth.",
              "implantswork": "How Dental Implants Work",
              "impwork1": "Implant is inserted into the bone",
              "impwork2": "Healing Process of the bone (osseointegration)",
              "impwork3": "Dental Abutment is placed on the implant",
              "impwork4": "Ceramic Crown is placed which replaces the real tooth",
              "experiencelifechanging": "Experience the",
              "experiencelifechanging1": "life-changing",
              "experiencelifechanging3": "benefits of dental implants at our clinic.",
              "experiencelifechangingp": "Our experienced team ensures precise implant placement, exceptional aesthetics, and superior functionality.",
              "stepbystep": "Step by Step Procedure with Andent",
              "imp1": "1. Comprehensive Assessment",
              "imp1p": "We kickstart your journey with a detailed evaluation of your oral health, understanding your needs, and discussing the best implant solution for you. We leverage advanced imaging technology for precise treatment planning",
              "imp2": "2. Implant Surgery",
              "imp2p": "Our dental experts will then place the titanium implant into your jawbone. This is done with precision and care, prioritizing your comfort throughout the process.",
              "imp3": "3. Healing and Integration",
              "imp3p": "Post-surgery, we allow time for osseointegration, where the implant fuses with the jawbone. This healing process, which can take a few months, ensures a stable foundation for the new tooth.",
              "imp4": "4. Abutment Placement",
              "imp4p": "Once healed, an abutment, a small connector, is attached to the implant. This will hold your new tooth in place.",
              "imp5": "5. Beautiful Restoration",
              "imp5p": "The final step is the placement of the prosthetic tooth, beautifully crafted to blend seamlessly with your natural teeth. With your new smile in place, you're all set to chew, speak, and beam with confidence.",
              "imptypes": "Types Of Dental Implants",
              "impt1": "Zirconia Implants",
              "impt1p1": "Metal-free option with excellent aesthetics.",
              "impt1p2": "Biocompatible ceramic material.",
              "impt1p3": "Perfect for patients seeking a natural look and feel.",
              "impt2": "Titanium Implants",
              "impt2p1": "Long lasting solution for natural tooth replacement.",
              "impt2p2": "Reduced risk of rejection or allergic reactions.",
              "impt2p3": "Prevents bone loss from missing teeth.",
              "heartgirlh": "Contact us to learn more about our services",
              "heartgirlp": "Guided by our expert team, we focus on your comfort and satisfaction. Achieve a confident smile with Andent.",
              "bridgeshero": "Discover Dental Bridges & Crowns",
              "bridgesherop": "Say goodbye to the challenges of missing or damaged teeth. We restore your smile with our affordable and long lasting implants for improved oral health.",
              "bridgesimportance": "Benefits of Dental Bridges & Crowns",
              "bridgesimp1": "Restored Chewing Function",
              "bridgesimp1p": "Experience better biting and chewing capabilities with durable dental crowns and bridges and enjoy your favorite foods without discomfort",
              "bridgesimp2": "Improved Speech",
              "bridgesimp2p": "Dental crowns and bridges fill gaps which ensure confident and articulate communication",
              "bridgesimp3": "Enhanced Aesthetics and Confidence",
              "bridgesimp3p": "Achieve a natural-looking smile that boosts self-confidence and engage confidently in social interactions",
              "bridgesimp4": "Longevity and Reliability",
              "bridgesimp4p": "Our expertly crafted dental crowns and bridges provide durability and reliable performance for years",
              "bridgesimp5": "Preservation of Oral Health",
              "bridgesimp5p": "Preserve jawbone and facial structure, preventing bone loss and maintaining natural tooth alignment",
              "howbridgeswork": "How Dental Bridges and Crowns Work",
              "bridges": "Bridges",
              "bridgesp": "Bridges are fixed prostheses that replace missing teeth by using artificial teeth to bridge the gap, supported by neighboring teeth or implants.",
              "crowns": "Crowns",
              "crownsp": "Crowns are custom-made tooth-shaped restorations that protect and strengthen damaged teeth, while enhancing their appearance.",
              "howbridgesdifferent": "How bridges are different than implants",
              "howbridgesdifferentp": "Dental bridges rely on adjacent teeth for support, while dental implants are independently anchored into the jawbone, offering enhanced durability",
              "bridges1": "1. Consultation",
              "bridges1p": "Our experienced dental team will assess your oral health and discuss your goals and concerns.",
              "bridges2": "2. Treatment Planning",
              "bridges2p": "A personalized treatment plan will be developed based on your unique needs and preferences",
              "bridges3": "3. Tooth Preparation",
              "bridges3p": "For crowns, the damaged tooth is reshaped to create space for the crown. For bridges, adjacent teeth are prepared to support the bridge.",
              "bridges4": "4. Impressions to ensure optimal fit",
              "bridges4p": "An impression of your teeth is taken to ensure the crowns or bridges are custom-fit for optimal comfort and functionality.",
              "bridges5": "5. Placement of crowns or bridges",
              "bridges5p": "Once your custom crowns or bridges are ready, they will be securely bonded or fixed in place and you can enjoy your new healthy teeth.",
              "bridgestypes": "Types of Dental Crowns and Bridges",
              "bridgest1": "Zirconia Crowns",
              "bridgest1p": "Known for their strength and durability, zirconia crowns are highly resistant to chipping or cracking.",
              "bridgest2": "Porcelain Crowns",
              "bridgest2p": "Natural-looking and durable, porcelain crowns are a popular choice for front teeth.",
              "bridgest3": "Ceramic Crowns",
              "bridgest3p": "Ceramic crowns offer excellent aesthetics and are metal-free, making them a great option for patients with metal allergies.",
              "generaldentistryhero": "Discover Premium Dentistry Services",
              "generaldentistryherop": "Say goodbye to the challenges of missing or damaged teeth. We restore your smile with our affordable and long lasting implants for improved oral health.",
              "experienceourtreatments": "Experience Our Dental Treatments",
              "treatment1h": "Routine Dental Examinations and Check-ups",
              "treatment1p": "Stay ahead of potential dental problems with regular check-ups. Our skilled dentists perform thorough examinations, detecting any oral health issues early on and providing timely treatments.",
              "treatment2h": "Dental Cleanings",
              "treatment2p": "Experience a fresh and healthy smile with our professional dental cleanings. Our hygienists employ advanced techniques to remove plaque, tartar, and stains, ensuring your teeth are thoroughly cleaned.",
              "treatment3h": "Dental Fillings",
              "treatment3p": "Restore the strength and appearance of your damaged teeth with our amalgam or composite resin fillings. Our dentists ensure durable and aesthetically pleasing results, preserving your oral health.",
              "treatment4h": "Tooth Extractions",
              "treatment4p": "In cases where a tooth is beyond repair due to severe damage or infection, our skilled dentists perform tooth extractions with utmost care. We provide comprehensive aftercare instructions to facilitate a smooth recovery.",
              "treatment5h": "Dental Sealants",
              "treatment5p": "Protect your teeth from cavities with dental sealants. Our thin, protective coatings are applied to the chewing surfaces of your teeth, particularly beneficial for children and individuals prone to dental decay.",
              "treatment6h": "Fluoride Treatments",
              "treatment6p": "Strengthen your tooth enamel and prevent tooth decay with our fluoride treatments. Our application of this essential mineral provides an extra layer of protection, especially for those at higher risk of cavities.",
              "treatment7h": "Treatment for Gum Disease (Gingivitis)",
              "treatment7p": "Combat gum disease with our effective treatments. Our comprehensive approach eliminates bacteria and restores gum health, preventing further oral health complications.",
              "treatment8h": "Dental X-Rays",
              "treatment8p": "Accurate diagnoses and treatment planning are made possible with dental X-rays. Our modern imaging technology captures detailed images, aiding in the detection of hidden dental issues.",
              "treatment9h": "Dental Bonding",
              "treatment9p": "Enhance the appearance and functionality of your teeth with dental bonding. Our skilled dentists use tooth-colored resin material to repair chips, cracks, or discoloration",
            "treatment10h": "Oral Hygiene Instructions",
            "treatment10p": "Our dedicated dental team takes the time to educate patients on proper oral hygiene techniques. We provide personalized instructions to help you maintain optimal dental health at home.",
            "expdentaltourism": "Experience Dental Tourism at Andent",
            "expdentaltourismp": "If you're seeking top-quality dental care combined with an unforgettable travel experience, look no further than Andent. We are proud to offer exceptional dental tourism services in the beautiful city of Tirana, Albania",
            "tourismbenefits": "Benefits of Dental Tourism with Andent",
            "tourismben1": "Affordability",
            "tourismben1p": "We provide top-tier dental services at up 70% of the cost you'd expect to pay locally. This affordability is one of the key factors driving our reputation as a leading destination for dental tourism.",
            "tourismben2": "Quality Care",
            "tourismben2p": "Our staff brings over 15 years of experience in various fields of dentistry. Using advanced technology and staying up-to-date with the latest techniques, we ensure that every patient receives the best treatment possible.",
            "tourismben3": "Safety & Security",
            "tourismben3p": "We follow stringent security protocols and hygiene standards to ensure a safe environment for all our clients. Our clinic is equipped with state-of-the-art dental technology to facilitate accurate diagnosis and precise treatments, making your visit to worry-free.",
            "gettingtotirana": "Getting to Tirana",
            "easilyaccessible": "An Easily Accessible and Budget Friendly Destination",
            "safetypriority": "SAFETY IS OUR PRIORITY",
            "securityprotocol": "Security Protocol & Technology",
            "securityprotocolp": "At Andent Clinic, we prioritize your health and safety by implementing top dental practices and maintaining the highest hygiene standards. Our multi-professional dental team follows international guidelines, ensuring a safe environment.",
            "securityprotocolp1": "Experience our state-of-the-art dental facilities equipped with modern tools and technologies, including:",
            "tech1": "Intra and Extra Oral Scanner",
            "tech1p": "Our advanced scanning technology allows for thorough planning and execution.",
            "tech2": "DNA and Bacterial Testing",
            "tech2p": "Our DNA and bacterial tests are used to identify potential oral infections at the earliest stages.",
            "tech3": "Guarantee for Dental Services",
            "tech3p": "We offer guarantees for our dental services, reflecting our commitment to you.",
            "tech4": "Laser Technology",
            "tech4p": "We ensure quick recovery and minimal discomfort by using state of the art laser technology",
            "tech5": "Intraoral Camera",
            "tech5p": "Our use of intraoral cameras allows for real-time, close-up visuals of your oral cavity.",
            "tech6": "3D Dental CT Scan",
            "tech6p": "Our 3D dental CT scans provide a comprehensive, three-dimensional view of your teeth.",
            "whyusherop": "Experience Exceptional Dental Care",
            "whyusheroh": "Welcome to Andent",
            "whyusherop1": "Since our establishment in 2007, we've dedicated ourselves to transforming smiles and enhancing oral health for over 15,000 patients, both domestically and across Europe. Our unwavering commitment to safety, advanced dental technologies, and European quality standards set us apart as a pioneer in the Albanian dental sector With 15 years of experience, we proudly offer a comprehensive range of dental services to cater to all oral health needs.",
            "yearsofexperience": "Year of Experience",
            "coredentaltreatments": "Our Core Dental Treatments",
            "orthheroh": "Discover Premium Orthodontic Treatments",
            "orthherop": "We are devoted to delivering remarkable results for all of our orthodontic treatments. Take the first step towards your dream smile with our personalized care",
            "orthtypes": "Types of Orthodontic Treatments",
            "ortht1": "Traditional Braces and Retainers",
            "ortht1p": "Durable metal braces expertly realign teeth, offering a cost-effective and proven method to perfect your smile.",
            "ortht2": "Invisible",
            "ortht21": "Aligners",
            "ortht2p": "Invisible Aligners are custom-made, clear devices, providing a discreet and removable solution for teeth realignment",
            "orthben": "Benefits of Invisible Aligners",
            "orthinfo1": "Superior Design",
            "orthinfo1p": "Invisible aligners offer a",
            "orthinfo1pspan": "revolutionary, aesthetic, and convenient",
            "orthinfo1p2": "orthodontic treatment. With their clear design, comfortable fit, and advanced 3D technology, these aligners enable discreet, predictable, and personalized teeth alignment, redefining your orthodontic journey.",
            "orthinfo2": "Convenience",
            "orthinfo2p": "Invisible aligners at seamlessly integrate into your lifestyle. They offer the",
            "orthinfo2pspan": "convenience of removability for daily activities,",
            "orthinfo2p2": "require fewer orthodontist visits compared to traditional braces, and promote better oral hygiene by reducing the risks of tooth decay and gum disease during treatment.",
            "howalignerswork": "How Invisible Aligners Work",
            "aligners1": "3D Impression",
            "aligners1p": "We begin with a 3D scan of your mouth to capture an exact model of your teeth and gums, forming the basis for your treatment plan.",
            "aligners2": "Treatment Plan Design",
            "aligners2p": "We map out the precise, step-by-step movement of your teeth from their current position to their final desired alignment.",
            "aligners3": "Aligner Fabrication",
            "aligners3p": "A series of aligners are crafted using biocompatible, BPA-free, clear plastic material which are manufactured to ensure an exact fit.",
            "aligners4": "Quality Check",
            "aligners4p": "Before you receive your aligners, each set undergoes a rigorous quality check to ensure a perfect fit and optimal performance.",
            "orthstep1": "Consultation",
            "orthstep1p": "Our orthodontic experts will assess your teeth and discuss your smile goals.",
            "orthstep2": "Custom Treatment Plan",
            "orthstep2p": "Using advanced technology, we create a personalized treatment plan tailored to your unique needs.",
            "orthstep3": "Fabrication Process",
            "orthstep3p": "Your custom Invisible aligners or your metal braces will be be fabricated using digital impressions of your teeth for a precise and comfortable fit.",
            "orthstep4": "Aligner Wear or Placing Braces",
            "orthstep4p": "You will wear your aligners for 20-22 hours a day or our dentists will place your new braces on your teeth and ensure a comfortable fit",
            "orthstep5": "Smile Transformation",
            "orthstep5p": "Over time, you will notice your teeth gradually moving into their proper alignment, bringing you closer to your desired smile.",
            "veneersheroh": "Discover Premium Dental Veneers",
            "veneersherop": "We are devoted to delivering remarkable results for all of our orthodontic treatments. Take the first step towards your dream smile with our personalized care",
            "veneersbenefit": "Benefits of Dental Veneers",
            "veneersbenefitp": "Veneers are an effective solution for a range of cosmetic dental concerns like discolored, chipped, or misaligned teeth",
            "veneersbenefit1": "Aesthetics",
            "veneersbenefit1p": "Our expertly crafted veneers enhance the appearance of teeth that are discolored, stained, chipped, cracked, or have gaps between them.",
            "veneersbenefit2": "Customization",
            "veneersbenefit2p": "Each veneer is custom-made to match the color, shape, size, and texture of your natural teeth ensuring a seamless integration with your smile",
            "veneersbenefit3": "Durability",
            "veneersbenefit3p": "We use high-quality materials, such as porcelain or composite resin, known for their strength and longevity, ",
            "veneersbenefit4": "Minimally Invasive",
            "veneersbenefit4p": "Our minimally invasive approach preserves the majority of your natural tooth structure by bonding the veneers to the front surface of your teeth",
            "veneersstep1": "Consultation",
            "veneersstep1p": "The process starts with a thorough examination of your oral health. We'll discuss your goals and the best type of veneers",
            "veneersstep2": "Preparation",
            "veneersstep2p": "Preparation may involve removing enamel from the tooth's surface to accommodate the veneer. We will then take an impression or digital scan of your teeth.",
            "veneersstep3": "Creation",
            "veneersstep3p": "Using the impressions, we will craft the veneers using durable materials that fit in with the rest of your teeth",
            "veneersstep4": "Placement",
            "veneersstep4p": "We then clean your teeth and bond the veneers to the tooth's surface using a special adhesive.",
            "veneersinfoh": "Cosmetic Dentistry and Smile Makeovers",
            "veneersinfop": "In addition to dental veneers, Andent offers a range of cosmetic dentistry procedures to help you achieve the smile you've always desired",
            "veneersinfo1": "Teeth Whitening",
            "veneersinfo1p": "Our professional teeth whitening treatments effectively remove stains and discoloration, revealing a brighter and more vibrant smile",
            "veneersinfo2": "Composite Bonding",
            "veneersinfo2p": "Through composite bonding, we can repair chipped, cracked, or decayed teeth using tooth-colored resin material",
            "veneersinfo3": "Smile Makeovers",
            "veneersinfo3p": "Our smile makeovers address multiple aspects of your smile by combining various cosmetic procedures tailored to your unique needs",
            "veneersgirlh": "Achieve the smile you've always wanted with our dental veneers",
            "veneersgirlp": "Contact us today to schedule a consultation and let our team create a customized treatment plan to deliver beautiful, natural-looking results",
            "denturesheroh": "Discover Premium Dentures",
            "denturesherop": "We are dedicated to providing you with carefully crafted dentures to fit your mouth and replicate the appearance of natural teeth. Take the first step towards your dream smile with our personalized care",
            "dentureswork": "How Dentures Work",
            "denturesworkh3": "Natural Looking Design",
            "denturesworkp": "Dentures are removable dental appliances that are crafted to resemble your natural teeth and gums. They are custom-made to fit your unique mouth shape and are designed to restore your smile and ability to chew and speak properly. Dentures are made from durable materials that are both comfortable and functional.",
            "denturesworkp1": "Custom Made Design",
            "denturesworkp2": "Easy to Maintain",
            "denturestype": "Types of Dentures",
            "denturestypep": "Dentures provide a reliable remedy for dental issues like missing, damaged, or decaying teeth, restoring both functionality and aesthetics",
            "denturestype1": "Complete Dentures",
            "denturestype1p": "Complete dentures replace all of your natural teeth and are custom-made to fit your mouth perfectly, providing a full and natural-looking smile.",
            "denturestype2": "Partial Dentures",
            "denturestype2p": "If you have some natural teeth remaining, partial dentures are designed to fill in the gaps, restoring both aesthetics and functionality.",
            "denturestype3": "Implant-Supported Dentures",
            "denturestype3p": "Implant-supported dentures are attached to dental implants for enhanced stability and comfort providing improved chewing ability.",
            "denturestep1": "Consultation",
            "denturestep1p": "Our team will evaluate your oral health and discuss your denture options based on your specific needs and preferences.",
            "denturestep2": "Impressions and Measurements",
            "denturestep2p": "Precise impressions and measurements of your mouth are taken to ensure a proper fit and optimal functionality of your dentures.",
            "denturestep3": "Denture Fabrication",
            "denturestep3p": "Using advanced dental technology and high-quality materials, your dentures will be meticulously crafted to provide a natural-looking appearance and comfortable fit.",
            "denturestep4": "Fitting and Adjustments",
            "denturestep4p": "Once your dentures are ready, we will ensure they fit properly and make any necessary adjustments to guarantee optimal comfort and functionality.",
            "testhero": "Our Client",
            "testherored": "Testimonials",
            "testp1": "We are dedicated to providing you with the highest standard service.",
            "testp2": "",
            "testp3": "Take the first step towards the smile of your dreams with our personalized care",
            "test3name": "Antonia Naselli",
            "test3desc": "Dentures are removable dental appliances that are crafted to resemble your natural teeth and gums. They are custom-made to fit your unique mouth shape and are designed to restore your smile and ability to chew and speak properly. Dentures are made from durable materials that are both comfortable and functional.",
            "test4name": "Emilio",
            "test4desc": "Dentures are removable dental appliances that are crafted to resemble your natural teeth and gums. They are custom-made to fit your unique mouth shape and are designed to restore your smile and ability to chew and speak properly. Dentures are made from durable materials that are both comfortable and functional.",
            "test5name": "Giuseppe",
            "test5desc": "Dentures are removable dental appliances that are crafted to resemble your natural teeth and gums. They are custom-made to fit your unique mouth shape and are designed to restore your smile and ability to chew and speak properly. Dentures are made from durable materials that are both comfortable and functional.",
            "test6name": "Rossella",
            "test6desc": "Dentures are removable dental appliances that are crafted to resemble your natural teeth and gums. They are custom-made to fit your unique mouth shape and are designed to restore your smile and ability to chew and speak properly. Dentures are made from durable materials that are both comfortable and functional.",
            "test7name": "Antonio",
            "test7desc": "Dentures are removable dental appliances that are crafted to resemble your natural teeth and gums. They are custom-made to fit your unique mouth shape and are designed to restore your smile and ability to chew and speak properly. Dentures are made from durable materials that are both comfortable and functional.",
            "test8name": "Mikela",
            "test8desc": "Dentures are removable dental appliances that are crafted to resemble your natural teeth and gums. They are custom-made to fit your unique mouth shape and are designed to restore your smile and ability to chew and speak properly. Dentures are made from durable materials that are both comfortable and functional.",
            "test9name": "Mateo",
            "test9desc": "Dentures are removable dental appliances that are crafted to resemble your natural teeth and gums. They are custom-made to fit your unique mouth shape and are designed to restore your smile and ability to chew and speak properly. Dentures are made from durable materials that are both comfortable and functional.",
            "test10name": "George",
            "test10desc": "Dentures are removable dental appliances that are crafted to resemble your natural teeth and gums. They are custom-made to fit your unique mouth shape and are designed to restore your smile and ability to chew and speak properly. Dentures are made from durable materials that are both comfortable and functional.",
            "beforeandafter": "Before & After Pictures of Clients",
            "belowlogo": "",
            "monday": "Monday",
            "saturday": "Saturday",
            "otherlinks": "Other Links",
            "dentalimplantsh":"dental-implants",
            "dentalbridgesh":"dental-crowns-bridges",
            "whyush":"why-andent",
            "dentaltourismh":"dental-tourism",
            "testimonialsh":"testimonials",
            "blogh":"blog",
            "generaldentistryh":"general-dentistry",
            "orthodonticsh":"orthodontics",
            "denturesh":"dentures",
            "dentalveneersh":"dental-veneers",
            "contactush":"contact-us",
            "homelink":"",
            "hometitle":"Best Dental Clinic in Tirana, Albania - Andent",
            "implantstitle":"Advanced Dental Implant Procedures -  Andent Tirana, Albania",
            "bridgestitle":"Restore Your Smile with Dental Crowns and Bridges - Andent",
            "generaltitle":"Comprehensive General Dentistry Services - Andent Tirana",
            "tourismtitle":"Best Dental Tourism Services in Tirana, Albania - Andent Dental Clinic",
            "whyustitle":"Andent Dental Clinic Tirana, Albania - Excellence in Dental Care",
            "orthtitle":"Effective Orthodontic Treatments - Andent Tirana, Albania",
            "veneerstitle":"Transform Your Smile with Dental Veneers - Andent Dental Tirana",
            "denturestitle":"High-Quality Full and Partial Dentures - Andent Dental Clinic Tirana, Albania",
            "testtitle":"Testimonials from Our Patients - Experiences with Andent Tirana, Albania",
            "homedesc":"Discover top-notch dental care in Tirana, Albania, at Andent Dental Clinic. Our expert team offers a range of services to enhance your oral health and smile. Book an appointment today!",
            "implantsdesc":"Regain your confident smile with cutting-edge dental implant solutions at Andent Dental Clinic in Tirana, Albania. Experience seamless tooth replacement with our skilled team. Get a personalized quote now!",
            "bridgesdesc":"Andent Dental Clinic in Tirana, Albania, specializes in dental crowns and bridges, providing durable and aesthetically pleasing solutions to improve your dental health. Get a quote now!",
            "generaldesc":"Choose Andent Dental Clinic for exceptional general dentistry in Tirana, Albania. Our comprehensive services cater to all your oral health needs. Call now!",
            "tourismdesc":"Experience dental tourism at its finest in Tirana, Albania, with Andent Dental Clinic. Enjoy quality dental care while exploring the beautiful city of Tirana and more. Get a quote immediately!",
            "whyusdesc":"Andent Dental Clinic in Tirana, Albania, offers excellence in dental care, combining advanced dental treatments, experienced professionals, and a patient-centric approach.",
            "orthdesc":"Straighten your teeth and achieve a perfect smile with our effective orthodontic treatments at Andent Dental Clinic in Tirana, Albania.",
            "veneersdesc":"Enhance your smile with stunning dental veneers at Andent Dental Clinic in Tirana, Albania. Achieve a flawless and natural-looking appearance.",
            "denturesdesc":"Restore your missing teeth with high-quality full or partial dentures at Andent Dental Clinic in Tirana, Albania. Regain function and confidence in your smile.",
            "testdesc":"First-hand testimonials from our patients about their experiences with Andent in Tirana, Albania. Read success stories about dental procedures and the personalized attention we offer.",
            "heroimg1":"andent dental clinic",
            "heroimg2":"andent Tirana dental clinic",
            "heroimg3":"andent dentists",
            "heroimg4":"dentures in Albania",
            "heroimg5":"andent dental care",
            "heroimg6":"dental services Albania",
            "heroimg7":"dentists in Tirana",
            "heroimg8":"dentists in Albania",
            "whyus1":"Albania dental care",
            "whyus2":"dental clinic Albania",
            "whyus3":"dental clinic in Albania",
            "whyus4":"andent Albania",
            "whyus5":"andent dental clinic albania",
            "tourism1":"dental tourism accomodation",
            "tourism2":"dental tourism Albania",
            "tourism3":"dental tourism guide",
            "howimplantwork1":"dental Implant ",
            "howimplantwork2":"dental Implants ",
            "howimplantwork3":"how dental implant works ",
            "howimplantwork4":"ceramic crown",
            "implantstypes1":"types of dental Implants ",
            "implantstypes2":"dental implant surgery",
            "heartgirl":"andent dental services",
            "bridgeswork1":"dental crowns",
            "bridgeswork2":"dental bridges",
            "crownstype1":"zirconia crowns",
            "crownstype2":"porcelain crowns",
            "crownstype3":"ceramic crowns",
            "tourismbenefit1":"dental tourism ",
            "tourismbenefit2":"benefits of dental tourism ",
            "tourismbenefit3":"dental travel",
            "wecltoandent":"andent dental clinic albania",
            "easyaccessible":"dental travel Albania",
            "securityimg1":"security protocol",
            "securityimg2":"advanced technology ",
            "securityimg3":"DNA",
            "securityimg4":"laser technology",
            "securityimg5":"guarantee for dental services",
            "securityimg6":"3D dental CT scans",
            "orthtype1":"traditional braces",
            "orthtype2":"invisible aligners",
            "alignersbenefit1":"orthodontics",
            "stepbysteporth1":"dental consultation",
            "stepbysteporth2":"custom treatment plan",
            "stepbysteporth3":"custom Invisible aligners",
            "stepbysteporth4":"aligner wear",
            "stepbysteporth5":"andent smile transformation",
            "denturesworkimg":"dentures Albania",
            "denturestypeimg1":"complete dentures",
            "denturestypeimg2":"partial dentures ",
            "denturestypeimg3":"implant-supported dentures",
            "denturesstep1":"denture options",
            "denturesstep2":"measurements of your mouth",
            "denturesstep3":"denture fabrication",
            "denturesstep4":"guarantee optimal comfort",
            "veneersstep1img":"dental veeners Albania",
            "veneersstep2img":"veneers Albania",
            "veneersstep3img":"crafted veneers in Tirana",
            "veneersstep4img":"teeth whitening",
            "cosmdent1":"cosmetic dentistry in Tirana",
            "cosmdent2":"composite bonding",
            "cosmdent3":"smile makeovers",
            "veneersbottom":"cosmetic dentistry procedures",
            "beforeafter1":"andent testimonial",
            "beforeafter2":"andent happy patients",
            "beforeafter3":"andent testimonials",
            "beforeafter4":"experienced dentists Albania",
            "beforeafter5":"carefully crafted dentures",
            "beforeafter6":"teeth whitening Tirana",
            "beforeafter7":"dream smile in Albania",
            "beforeafter8":"personalized dental care",
            "beforeafter9":"before & after pictures of dental procedures",
            "beforeafter10":"andent clients",
            "beforeafter11":"Tirana dental clinic",
            "beforeafter12":"Albania dental care",
            "beforeafter13":"dentist in Tirana",
            "beforeafter14":"dental services Tirana",
            "beforeafter15":"dental specialists Tirana",
            "beforeafter16":"affordable dental services Albania",
            "beforeafter17":"dental check-ups Tirana",
            "beforeafter18":"dental procedures Albania",
            "beforeafter19":"smile makeover Tirana",
            "beforeafter20":"restorative dentistry Albania",
            "thankyou":"Thank You! We will be in contact as soon as possible.",
            "consent":"By clicking “Accept All Cookies”, you agree to the storing of cookies on your device to enhance site navigation, analyze site usage, and assist in our marketing efforts. For more detailed information about the cookies we use, see our",
            "whatsAppNum":"+355696598059",
            "whatsAppMsg":"Hello there! 🤝\nHow can we help?",
            "emailLink":"hello@andent.al",
            "simNum":"+355 (0) 69 659 8059",
            "cookiePolicy1":"We and selected third parties use cookies or similar technologies for technical purposes and, with your consent, also for experience, measurement and marketing purposes (with personalized ads). Refusal of consent may make the related functions unavailable.",
            "cookiePolicy2":"In relation to advertising, we and selected third parties may use precise geolocation data and identification through device scanning to store and/or access information on a device and process personal data such as your use, for the following advertising purposes: personalized ads and content, ad and content evaluation, audience observations and product development.",
            "cookiePolicy3":"You can freely give, refuse or withdraw your consent at any time by accessing the preferences panel.",
            "cookiePolicy":"Cookie Policy",
            "cookiePath":"cookie-policy",
            "privacyPol":"Privacy Policy",
            "privacyPolLink":"privacy-policy-en",
            "privacyp1":"This privacy notice outlines how Dental Clinic Andent (Andent) collects, uses, and safeguards the personal information of users who visit our website, utilize our services, or request appointments or estimates. We are committed to ensuring the protection of your privacy in compliance with current regulations.",
            "privacyp2":"Dental Clinic Andent, located at Tirana, Rruga Bardhok Biba, Pall. Golden Trema, Shk. B, Kati 4, with VAT M22314039G and contact email hello@andent.al, is responsible for processing your personal data. We use and protect all information that users provide when using the www.andent.al website and our other online and offline services, especially concerning personal data processing while offering our services.",
            "privacyp3":"Users have the right to access, rectify, delete, or limit the use of their personal information. They can also withdraw consent to data processing at any time. To exercise these rights, please contact us through the provided contact details.",
            "privacyp4":"Personal data refers to any information relating to an identifiable person. Specifically, personal data includes any information that identifies a user (e.g., name, email address, postal address, etc.). Sensitive personal data (e.g., dental health conditions) require special protection. Processing personal data involves any operation or set of operations, whether automated or not, concerning personal data. This includes collecting, recording, organizing, storing, modifying, deleting, viewing, using, disclosing through transmission, and any other manner of data handling.",
            "privacyp5":"How We Collect Personal Data",
            "privacyp6":"Andent collects your personal data in the following instances:",
            "privacyp61":"If you directly contact us through any of our websites to request information or an estimate for our services or through our contact form.",
            "privacyp62":"If you purchase a service directly from us.",
            "privacyp63":"If you respond to our direct marketing campaigns, e.g., by filling out a contact form on our webpage.",
            "privacyp64":"If our partners provide us with your data in a permissible manner.",
            "privacyp65":"If you are under 16 years old, please do not provide any data without the consent of a parent or guardian.",
            "privacyp7":"This data includes, but is not limited to, name, email address, phone number, details about your dental needs, and files related to radiographic exams describing your dental requirements and health conditions.",
            "privacyp8":"We use cookies and similar technologies to gather information about how our website is used. This data helps us enhance your online experience and tailor content according to your preferences.",
            "privacyp9":"How We Use Your Data",
            "privacyp10":"The use of personal data in compliance with personal data protection rules must be justified by legal grounds. The justified bases for data processing include legitimate interest, contractual obligation, and legal duty. Andent aims to promote and provide information about its services, ensuring the highest standards in service sales and offerings. We ensure a balance between Andent's legitimate interest in processing data and users' fundamental rights. We collect and process contact data, service details, warranties, and your medical history as a patient.",
            "privacyp11":"Personal data may be shared with third parties, provided there is a justified basis for such sharing, usually based on consent. The personal information gathered is used to process estimates, formulate and communicate work plans, schedule appointments, and inform you about our offers and services. Andent collaborates with third-party partners and discloses that personal data may be shared with and processed by these third parties, some of whom may be based abroad (Italy and Switzerland), to meet user requests.",
            "privacyp12":"Andent may use direct marketing based on your personal data to inform you about news, promotions in our clinic, special discounts, new service introductions, and our presentations at fairs, conferences, and other events. Direct marketing consent can be withdrawn at any time. You can lodge a complaint about data processing by sending an email to Andent. Dental Clinic Andent operates under Albanian laws.",
            "privacyp13":"How We Protect Your Data",
            "privacy14":"We employ various security measures, including encryption and authentication, to safeguard and maintain the security, integrity, and accessibility of your data. We take appropriate security measures to protect your personal information from unauthorized access, alterations, or disclosures. However, no data transmission or storage system can be guaranteed to be entirely secure.",
            "privacy15":"For example, we use the following measures:",
            "privacy16":"Strictly limited personal access to your data based on the 'need-to-know' principle.",
            "privacy17":"Secure transmission of collected data.",
            "privacy18":"Installation of firewalls in IT systems to prevent unauthorized access.",
            "privacy19":"Continuous monitoring of IT access to detect any misuse of personal data.",
            "privacy20":"All your data is stored on our secure servers and those of our partners and accessed and used in line with our security standards. We are always committed to ensuring the privacy of your data. Personal data is processed securely, preventing unauthorized or unlawful processing, data loss, or destruction.",
            "privacy21":"By filling out the contact form, you grant us permission to process your personal data as specified in the registration or contact form and any submitted diagnostic exams for the stated purpose. Beyond this, Andent will not undertake any processing other than those declared and will not transfer data to third parties except in fulfilling agreements.",
            "privacy22":"We commit not to misuse personal data provided through the registration form or any other form or obtained via cookies, nor share them with third parties without your permission, except when explicitly required by law or to meet obligations. All user data is kept confidential and accessible only to employees and partners who need it for work",
            "privacy23":"",
            "privacy24":"",
            "privacy25":"",
            "privacy26":"",
            "privacy27":"",
            "privacy28":"",
            "policyNotice":"By submitting this form you agree to our ",
            "policyNotice1":" as well as to receive marketing emails from Andent. You can unsubscribe from these communications at any time.",
            "policyLink":"privacy policy",
            "policyNotice2":"",
            "policyNotice3":"",
            "magicFN":"First Name",
            "magicFN1":"Enter First Name",
            "magicLN" : "Last Name",
            "magicLN1" : "Enter Last Name",
            "magicE" : "Email",
            "magicE1" : "Enter email",
            "magicN" : "Number",
            "magicN1" : "Enter Number",
            "magicM" : "Message",
            "magicM1" : "Enter Message",
            "magicUpload" : "Upload Panoramex",
            "magicSubmit" : "Submit",
            "sizeWarn" : "File size exceeds maximum limit: 10 MB!"
          }
        },
        it : {
            translation: {
              "test": "testare la traduzione i18n cosa",
              "home": "Homepage",
              "implants": "Impianti dentali",
              "dentalbridges": "Corone dentali",
              "aboutus": "Chi siamo",
              "tourism": "Turismo dentale",
              "testimonial": "Testimonianze",
              "blog": "Blog",
              "otherserv": "Altri servizi",
              "orthodontics": "Ortodonzia",
              "general": "Odontoiatria Generale",
              "dentures": "Dentiere",
              "veneers": "Faccette dentali",
              "contactus": "Contattaci",
              "ourservices": "Servizi",
              "welcome": "BENVENUTI IN ANDENT",
              "homehero": "La migliore clinica dentale di Tirana per ",
              "homehero2": "e",
              "homeheroh2": "qualità",
              "homeheroh22": "comfort",
              "homeherop": "Siamo completamente focalizzati sul conseguimento di risultati straordinari attraverso trattamenti ortodontici impeccabili. Inizia il tuo percorso oggi stesso e trova il sorriso dei tuoi sogni grazie alle nostre cure altamente personalizzate.",
              "yourphone": "Il tuo numero di telefono...",
              "yourname": "Il tuo nome...",
              "callback": "Ottieni la richiamata",
              "reviews": "Esperienze dei Pazienti",
              "servicesh3": "RITROVA IL SORRISO PERFETTO",
              "servicesh31":"PROVA QUESTI E ALTRI SERVIZI",
              "servicesh2": "Trattamenti dentali",
              "service1": "Impianti dentali",
              "service2": "Corone e ponti dentali",
              "service3": "Dentiere",
              "service4": "Odontoiatria estetica e faccette",
              "service5": "Trattamento ortodontico",
              "service6": "Odontoiatria Generale",
              "service1p": "Radici artificiali resistenti che sostengono denti sostitutivi dall'aspetto naturale per un sorriso impeccabile.",
              "service2p": "Protesi di alta qualità e durature per denti danneggiati o mancanti.",
              "service3p": "Sostituzioni su misura per masticare, parlare e sorridere comodamente.",
              "service4p": "Trasforma il tuo sorriso con sbiancamento, faccette e makeover personalizzati.",
              "service5p": "Ottieni denti dritti e correggi i morsi con i trattamenti ortodontici, incluso Invisalign",
              "service6p": "Cure preventive, trattamenti e mantenimento per una salute orale ottimale e un bel sorriso.",
              "aboutUs": "Chi siamo",
              "whyandent": "Perché Andent?",
              "whyandentp": "Esperienza decennale, più di 15.000 pazienti europei, direzione a cura della dott.ssa Anduela Çurmaku, talento dell’Odontoiatria confermato da premi e riconoscimenti: ecco perché siamo il punto di riferimento per le cure dentali più avanzate.",
              "whyandentp2" : "Dall'applicazione indolore di impianti al recupero completo dei casi più complessi, Andent è garanzia di eccellenza, qualità e sicurezza. Affidati al nostro team per ritrovare il tuo sorriso perfetto!",
              "learnmore": "Scopri di più",
              "meetTeam": "Incontra il Nostro Team",
              "clerk": "Addetto alla reception",
              "dentist": "Odontoiatria",
              "secretary": "segretario",
              "tech": "Odontotecnico",
              "dentaltourismcoord": "Coordinatore di Clinica per il Turismo Dentale",
              "assistant": "Assistente dentista",
              "staff1": "Anduela Curmaku",
              "staff2": "Blerta Curmaku",
              "staff3": "Ermal Cuadari",
              "staff4": "Laurent Bixheku",
              "staff5": "Leonardo Macì",
              "staff6": "Manuela Manjani",
              "staff7": "Marvin Dedaj",
              "staff8": "Rudina Bitri",
              "staff9": "Safjela Baja",
              "makethemosttrip": "Ottieni il massimo dal tuo viaggio",
              "freeaccom": "Sistemazione gratuita in un hotel 4 stelle situato nel cuore di Tirana",
              "freeaccomp": "Visita le attrazioni principali della Capitale e immergiti nella sua vita vibrante mentre ricevi servizi dentistici di alto livello.",
              "assistancewithtravel": "Assistenza assicurata dall’inizio alla fine del tuo viaggio dentale",
              "assistancewithtravelp": "Offriamo servizi di transfer da e per l'aeroporto, programmiamo gli appuntamenti, facciamo traduzioni e tanto altro.",
              "traveltips": "Consigli di viaggio e tour per sfruttare al meglio il tuo viaggio",
              "traveltipsp": "Per assicurarti di ottenere il massimo dal tuo viaggio, personalizzeremo il tuo viaggio in base ai tuoi gusti e preferenze.",
              "test1name": "Gaetano",
              "test1desc": "Le protesi sono apparecchi dentali rimovibili realizzati per assomigliare ai denti e alle gengive naturali. Sono realizzati su misura per adattarsi alla forma unica della tua bocca e sono progettati per ripristinare il tuo sorriso e la capacità di masticare e parlare correttamente. Le protesi sono realizzate con materiali durevoli, confortevoli e funzionali.",
              "test2name": "Roberta",
              "test2desc": "Le protesi sono apparecchi dentali rimovibili realizzati per assomigliare ai denti e alle gengive naturali. Sono realizzati su misura per adattarsi alla forma unica della tua bocca e sono progettati per ripristinare il tuo sorriso e la capacità di masticare e parlare correttamente. Le protesi sono realizzate con materiali durevoli, confortevoli e funzionali.",
              "bookaptmnt": "Richiedi la tua Consulenza",
              "fname": "Name",
              "lname": "Cognome",
              "email": "E-mail",
              "number": "Numero di telefono",
              "comments": "Messaggio",
              "file": "Panoramex; Png, Jpeg, Pdf",
              "formpolicy1": "Acconsento al trattamento dei miei dati personali forniti nel presente modulo per l'elaborazione del preventvo e per le finalità connesse ai servizi offerti dalla Clinica Dentale Andent. Comprendo che i miei dati saranno trattati conformemente alla normativa vigente e che ho il diritto di revocare questo consenso in qualsiasi momento. Acconsento a che i miei dati personali vengano trasmessi e trattati da partner della Clinica Dentale Andent siti all'estero (Italia e Svizzera) per dare attuazione ed esecuzione alla richiesta di preventivo.",
              "formpolicy2": "Garantiamo che i tuoi dati, in specie quelli sulle tue condizioni sanitarie, saranno trattati in modo sicuro e riservato. Per ulteriori informazioni sulla nostra politica sulla privacy, puoi consultare la nostra politica sulla privacy.",
              "upload": "Caricamento",
              "uploaded": "Caricato!",
              "booknow": "RICHIEDI ORA",
              "address": "Indirizzo della clinica",
              "phnum": "Numero di telefono:",
              "saveupto70": "Risparmia fino al 70%",
              "implantshero": "Scopri impianti dentali eccezionali",
              "implantsherop": "Dì addio al problema dei denti mancanti o danneggiati! Ripristiniamo il tuo sorriso utilizzando solo impianti delle migliori marche svizzere ed italiane.",
              "implantsbenefits": "Vantaggi degli impianti dentali",
              "impben1": "Fondamenta permanenti e resistenti",
              "impben1p": "Gli impianti dentali offrono una soluzione duratura per la sostituzione dei denti mancanti, garantendoti una base sicura e stabile.",
              "impben2": "Funzionalità migliorata",
              "impben2p": "Grazie agli impianti dentali, puoi riacquistare la piena capacità masticatoria e ripristinare una corretta pronuncia, questo migliorerà  la qualità della tua vita.",
              "impben3": "Prevenzione delle complicazioni dentali",
              "impben3p": "Gli impianti dentali, sostituendo i denti mancanti, impediscono lo spostamento dei denti circostanti, preservando un corretto allineamento del morso e prevenendo possibili problemi alla mandibola.",
              "impben4": "Preserva la struttura dentale",
              "impben4p": "Gli impianti dentali contribuiscono a mantenere l'integrità della struttura dentale, prevenendo la perdita dei denti circostanti e promuovendo una salute orale ottimale.",
              "impben5": "Salute della mandibola migliorata",
              "impben5p": "Gli impianti dentali stimolano l'osso mascellare, favorendo una crescita ossea sana e prevenendo la perdita ossea spesso associata alla mancanza di denti.",
              "implantswork": "Come funzionano gli impianti dentali",
              "impwork1": "L'impianto è inserito nell'osso",
              "impwork2": "Processo di guarigione dell'osso (osteointegrazione)",
              "impwork3": "Il moncone dentale viene posizionato sull'impianto",
              "impwork4": "Viene posizionata la corona in ceramica che sostituisce il dente vero",
              "experiencelifechanging": "Migliora la tua salute orale con gli impianti dentali presso la nostra clinica",
              "experiencelifechanging1": "",
              "experiencelifechanging3": "",
              "experiencelifechangingp": "Il nostro team di esperti assicura un  posizionamento preciso dell'impianto, un'estetica ottimale e una funzionalità senza pari.",
              "stepbystep": "Procedura Dettagliata con Andent",
              "imp1": "1. Valutazione preliminare",
              "imp1p": "Iniziamo il tuo percorso con una valutazione approfondita della tua salute orale, comprendendo le tue necessità e definendo la soluzione implantare ottimale. Utilizziamo tecnologie di imaging avanzate per una pianificazione accurata del trattamento.",
              "imp2": "2. Chirurgia implantare",
              "imp2p": "I nostri dentisti esperti procederanno all'inserimento dell'impianto in titanio nella mascella con attenzione e precisione, assicurandoti il massimo comfort durante ogni fase del processo.",
              "imp3": "3. Guarigione e integrazione",
              "imp3p": "Dopo l'intervento chirurgico, inizia il periodo di osteointegrazione, in cui l'impianto si fonde con l'osso mascellare. Questo processo di guarigione, che può durare alcuni mesi, stabilisce una base solida per il nuovo dente.",
              "imp4": "4. Posizionamento del moncone",
              "imp4p": "Dopo la guarigione, verrà collegato un moncone, un piccolo connettore, all'impianto. Questo garantirà la stabilità del tuo nuovo dente.",
              "imp5": "5. Bellissimo restauro",
              "imp5p": "L'ultima fase consiste nel posizionamento della protesi dentale che si armonizzerà  perfettamente con i denti naturali. Grazie al tuo nuovo sorriso, sei pronto a masticare, parlare e sorridere con fiducia.",
              "imptypes": "Tipi Di Impianti Dentali",
              "impt1": "Impianti di zirconio",
              "impt1p1": "Opzione senza metallo per  un'estetica eccellente.",
              "impt1p2": "Materiale ceramico biocompatibile.",
              "impt1p3": "Perfetto per i pazienti che cercano un aspetto naturale.",
              "impt2": "Impianti in titanio",
              "impt2p1": "Soluzione di lunga durata per la sostituzione del dente naturale",
              "impt2p2": "Rischio ridotto di rigetto o reazioni allergiche",
              "impt2p3": "Previene la perdita ossea da denti mancanti",
              "heartgirlh": "Contattaci per sapere di più sui nostri servizi",
              "heartgirlp": "Lasciati guidare dal nostro team di esperti, mettiamo al primo posto il tuo benessere e la tua soddisfazione. Ottieni il sorriso che hai sempre desiderato con Andent.",
              "bridgeshero": "Scopri Ponti e Corone Dentali",
              "bridgesherop": "Dì addio al problema dei denti mancanti o danneggiati. Ripristiniamo il tuo sorriso e la tua salute orale utilizzando solo impianti convenienti e di lunga durata.",
              "bridgesimportance": "Importanza di Corone e Ponti Dentali",
              "bridgesimp1": "Ripristina la funzione masticatoria",
              "bridgesimp1p": "Sperimenta un miglioramento nella capacità di mordere e masticare grazie a corone e ponti dentali durevoli. Goditi i tuoi cibi preferiti senza disagio.",
              "bridgesimp2": "Pronuncia migliorata",
              "bridgesimp2p": "Corone e ponti dentali colmano gli spazi, assicurando una comunicazione e un’articolazione migliore delle parole.",
              "bridgesimp3": "Estetica e fiducia migliorate",
              "bridgesimp3p": "Acquisisci un sorriso naturale, aumenta la fiducia in te stesso, e partecipa alle interazioni sociali con più  sicurezza.",
              "bridgesimp4": "Longevità e affidabilità",
              "bridgesimp4p": "Le nostre corone e ponti dentali, realizzati con maestria, offrono durata e affidabilità nelle prestazioni per molti anni a venire.",
              "bridgesimp5": "Conservazione della salute orale",
              "bridgesimp5p": "Preserva l'osso mascellare e la struttura facciale, prevenendo la perdita ossea e mantenendo l'allineamento naturale dei denti",
              "howbridgeswork": "Come funzionano i ponti e le corone dentali",
              "bridges": "Ponti",
              "bridgesp": "I ponti sono protesi fisse, che sostituiscono i denti mancanti con denti artificiali per colmare il divario, supportate da denti o impianti vicini.",
              "crowns": "Corone",
              "crownsp": "Le corone sono restauri su misura a forma di dente che proteggono e rinforzano i denti danneggiati, migliorandone al tempo stesso l'aspetto.",
              "howbridgesdifferent": "Differenze tra ponti e impianti",
              "howbridgesdifferentp": "I ponti dentali trovano supporto nei denti adiacenti, mentre gli impianti dentali sono ancorati in modo indipendente nell'osso mascellare, garantendo così una maggiore durata.",
              "bridges1": "1. Valutazione preliminare",
              "bridges1p": "Il nostro team odontoiatrico esperto valuterà la tua salute orale e discuterà i tuoi obiettivi e le tue preoccupazioni.",
              "bridges2": "2. Pianificazione del trattamento",
              "bridges2p": "Verrà elaborato un piano di trattamento personalizzato in base alle tue esigenze e alle tue preferenze.",
              "bridges3": "3. Preparazione del dente",
              "bridges3p": "el caso delle corone, il dente danneggiato viene modellato per creare lo spazio necessario per la corona stessa. Per quanto riguarda i ponti, invece, i denti adiacenti vengono preparati per fornire supporto al ponte.",
              "bridges4": "4. Impronta dentale per garantire una vestibilità ottimale",
              "bridges4p": "Viene realizzata un'impronta dei tuoi denti per assicurare che le corone o i ponti siano realizzati su misura, garantendo così un comfort e una funzionalità ottimali.",
              "bridges5": "5. Posizionamento di corone o ponti",
              "bridges5p": "Una volta che le tue corone o ponti personalizzati saranno pronti, verranno saldamente fissati o incollati in posizione, permettendoti di godere dei tuoi nuovi denti sani.",
              "bridgestypes": "Tipi di corone e ponti dentali",
              "bridgest1": "Corone in Zirconio",
              "bridgest1p": "Le corone in zirconia sono robuste e durature. Hanno  elevata resistenza alle scheggiature e alle screpolature.",
              "bridgest2": "Corone in Porcellana",
              "bridgest2p": "Le corone in porcellana, apprezzate per il loro aspetto naturale e la resistenza, sono spesso scelte  per i denti anteriori.",
              "bridgest3": "Corone di Ceramica",
              "bridgest3p": "Le corone in ceramica garantiscono un'eccellente estetica e sono prive di metallo, rendendole una scelta ideale per i pazienti con sensibilità ai metalli.",
              "generaldentistryhero": "Scopri i servizi di odontoiatria premium",
              "generaldentistryherop": "Dì addio al problema dei denti mancanti o danneggiati. Ripristiniamo il tuo sorriso e la tua salute orale utilizzando solo impianti convenienti e di lunga durata.",
              "experienceourtreatments": "Prova i nostri trattamenti dentali",
              "treatment1h": "Controlli e visite odontoiatriche di routine",
              "treatment1p": "Mantieniti informato sui possibili problemi dentali effettuando controlli regolari. I nostri dentisti qualificati eseguono esami approfonditi per rilevare tempestivamente problemi di salute orale, garantendo trattamenti rapidi ed efficaci.",
              "treatment2h": "Pulizie dentali",
              "treatment2p": "Regalati un sorriso fresco e sano con le nostre pulizie dentali professionali. I nostri igienisti impiegano tecniche avanzate per rimuovere placca, tartaro e macchie, assicurando che i tuoi denti siano puliti a fondo.",
              "treatment3h": "Otturazioni dentali",
              "treatment3p": "Ripristina la forza e l'aspetto dei tuoi denti danneggiati con le nostre otturazioni in amalgama o resina composita. I nostri dentisti garantiscono risultati durevoli ed esteticamente gradevoli, preservando la tua salute orale.",
              "treatment4h": "Estrazioni di denti",
              "treatment4p": "Nelle situazioni in cui un dente è irrimediabilmente danneggiato, i nostri esperti dentisti eseguono estrazioni con cura e attenzione. Offriamo dettagliate indicazioni post-trattamento per agevolare una pronta guarigione.",
              "treatment5h": "Sigillanti dentali",
              "treatment5p": "Proteggi i tuoi denti dalla carie con i sigillanti dentali. I nostri sottili rivestimenti protettivi vengono applicati alle superfici masticatorie dei denti. Questo trattamento è particolarmente utile per i bambini e le persone soggette a carie.",
              "treatment6h": "Trattamenti al fluoro",
              "treatment6p": "Rafforza lo smalto dei denti e previeni la carie con i nostri trattamenti al fluoro.L'applicazione di questo minerale  essenziale fornisce un livello di protezione extra, soprattutto per coloro che sono a maggior rischio di carie.",
              "treatment7h": "Trattamento per malattie gengivali (gengivite)",
              "treatment7p": "Combatti le malattie gengivali con i nostri efficaci trattamenti. Il nostro approccio completo elimina i batteri e ripristina la salute delle gengive, evitando ulteriori complicazioni per la tua salute orale.",
              "treatment8h": "Radiografie dentali",
              "treatment8p": "Diagnosi accurate e pianificazione del trattamento sono possibili con i raggi X dentali. La nostra moderna tecnologia di imaging ci permette di acquisire immagini dettagliate, e di rilevare così anche i problemi dentali nascosti.",
              "treatment9h": "Legame dentale",
              "treatment9p": "Migliora l'aspetto e la funzionalità dei tuoi denti con il legame dentale. I nostri abili dentisti utilizzano materiale in resina color dente per riparare scheggiature, crepe o scolorimento, ripristinando il tuo sorriso sicuro.",
              "treatment10h": "Istruzioni per l'igiene orale",
              "treatment10p": "Il nostro team odontoiatrico dedicato dedica tempo per istruire i pazienti sulle corrette tecniche di igiene orale. Forniamo istruzioni personalizzate per aiutarti a mantenere una salute dentale ottimale anche a casa.",
              "expdentaltourism": "Vivi il turismo dentale con Andent",
              "expdentaltourismp": "Siamo orgogliosi di offrire ogni giorno ai pazienti che ci raggiungono dall’estero un’esperienza gradevole e sorprendente! Assicurati le migliori cure dentali e scopri una terra piena di bellezza, storia e colori!",
              "tourismbenefits": "Vantaggi del turismo dentale con Andent",
              "tourismben1": "Convenienza",
              "tourismben1p": "Forniamo servizi odontoiatrici di alto livello ad un prezzo fino al 70% inferiore rispetto a quanto pagheresti dove risiedi. Questa convenienza è uno dei fattori chiave che ci ha reso leader del settore e destinazione principale per il turismo dentale.",
              "tourismben2": "Cura di qualità",
              "tourismben2p": "Il nostro staff vanta oltre 15 anni di esperienza in vari campi dell'odontoiatria. Utilizziamo tecnologie avanzate, rimaniamo sempre aggiornati sulle ultime tecniche e ci assicuriamo che ogni paziente riceva il miglior trattamento possibile.",
              "tourismben3": "Sicuri & Protetti",
              "tourismben3p": "Seguiamo rigorosi protocolli di sicurezza e standard igienici per garantire un ambiente sicuro a tutti i nostri clienti. La nostra clinica è dotata di tecnologia dentale all'avanguardia per facilitare diagnosi accurate e trattamenti precisi, questo rende la tua visita sicura e senza preoccupazioni.",
              "gettingtotirana": "Arrivare a Tirana",
              "easilyaccessible": "Una destinazione facilmente accessibile e conveniente",
              "safetypriority": "LA SICUREZZA È LA NOSTRA PRIORITÀ",
              "securityprotocol": "Protocollo e Tecnologia di Sicurezza",
              "securityprotocolp": "In Andent Clinic la tua salute e la tua sicurezza sono una priorità! Le nostre postazioni di lavoro rispettano severi standard di igiene, conformi alla linee guida internazionali.",
              "securityprotocolp1": "Scopri la dotazione di strumenti ed apparecchiature tecnologiche per garantirti precisione, velocità e sicurezza:",
              "tech1": "Scanner Intra ed Extra Orale",
              "tech1p": "La nostra tecnologia di scansione avanzata consente una pianificazione e un'esecuzione complete.",
              "tech2": "DNA e test batterici",
              "tech2p": "Utilizziamo test del DNA e batterici per rilevare tempestivamente possibili infezioni orali già nelle fasi iniziali",
              "tech3": "Garanzia per i servizi odontoiatrici",
              "tech3p": "Forniamo garanzie per i nostri servizi odontoiatrici, che riflettono il nostro impegno nei tuoi confronti.",
              "tech4": "Tecnologia laser",
              "tech4p": "Garantiamo un veloce recupero e il minimo disagio utilizzando la tecnologia laser all'avanguardia",
              "tech5": "Telecamera intraorale",
              "tech5p": "Grazie alle nostre telecamere intraorali, otteniamo immagini ravvicinate in tempo reale della cavità orale",
              "tech6": "Scansione TC dentale 3D",
              "tech6p": "Le nostre scansioni TC dentali 3D forniscono una visione completa e tridimensionale dei tuoi denti.",
              "whyusherop": "Sperimenta cure dentistiche eccezionali",
              "whyusheroh": "Benvenuti ad Andent",
              "whyusherop1": "Dalla nostra fondazione nel 2007, ci siamo dedicati a trasformare il sorriso e migliorare la salute orale di oltre 15.000 pazienti. Operiamo sia a livello nazionale che in tutta Europa. Il nostro costante impegno per la sicurezza, l’uso di tecnologie dentali avanzate e l’adozione di standard di qualità europei ci distinguono come pionieri nel settore dentale albanese. Con 15 anni di esperienza, offriamo con orgoglio una gamma completa di servizi odontoiatrici per soddisfare tutte le esigenze di salute orale.",
              "yearsofexperience": "Anni di esperienza",
              "coredentaltreatments": "I Nostri Principali Trattamenti Dentali",
              "orthheroh": "Scopri trattamenti ortodontici premium",
              "orthherop": "Il nostro impegno è rivolto verso il solo ottenere risultati eccezionali grazie a trattamenti ortodontici all’avanguardia. Inizia il percorso verso il sorriso dei tuoi sogni con le nostre cure personalizzate.",
              "orthtypes": "Tipi di Trattamenti Ortodontici",
              "ortht1": "Bretelle tradizionali e fermi",
              "ortht1p": "I nostri robusti apparecchi in metallo riallineano dolcemente i denti. Scopri un  metodo economico e collaudato per perfezionare il tuo sorriso.",
              "ortht2": "Allineatori",
              "ortht21": "invisibili",
              "ortht2p": "Gli allineatori invisibili sono dispositivi trasparenti realizzati su misura.  Forniscono una soluzione discreta e facilmente rimovibile per il riallineamento dei denti",
              "orthben": "Vantaggi degli Invisalign",
              "orthinfo1": "Design Superiore",
              "orthinfo1p": "Gli allineatori invisibili rivoluzionano l'ortodonzia: estetici e convenienti, con design trasparente, comfort e tecnologia 3D. Garantiscono allineamento dentale discreto e prevedibile, ridefinendo il percorso ortodontico in modo personalizzato.",
              "orthinfo1pspan": "",
              "orthinfo1p2": "",
              "orthinfo2": "Convenienza",
              "orthinfo2p": "Gli allineatori invisibili si fondono armoniosamente con il tuo stile di vita. Rimovibili per le attività quotidiane, richiedono meno visite rispetto agli apparecchi tradizionali e migliorano l'igiene orale, riducendo il rischio di carie e problemi gengivali durante il trattamento.",
              "orthinfo2pspan": "",
              "orthinfo2p2": "",
              "howalignerswork": "Come Funzionano gli Invisalign",
              "aligners1": "Impressione 3D",
              "aligners1p": "Iniziamo con una scansione 3D della tua bocca, creando un modello preciso di denti e gengive come fondamento del tuo piano di trattamento.",
              "aligners2": "Progettazione del piano di trattamento",
              "aligners2p": "Tracciamo il progressivo e accurato movimento dei tuoi denti dalla posizione attuale all'allineamento desiderato.",
              "aligners3": "Fabbricazione di allineatori",
              "aligners3p": "Creiamo una serie di allineatori utilizzando plastica trasparente biocompatibile, priva di BPA, progettata per garantire un'aderenza ottimale.",
              "aligners4": "Controllo di qualità",
              "aligners4p": "Prima di essere consegnati, ogni set di allineatori viene sottoposto a un rigoroso controllo di qualità per assicurare una perfetta aderenza e prestazioni ottimali.",
              "orthstep1": "Valutazione",
              "orthstep1p": "I nostri esperti valuteranno la tua dentatura e discuteranno i tuoi obiettivi di sorriso.",
              "orthstep2": "Piano di Trattamento Personalizzato",
              "orthstep2p": "Attraverso tecnologie avanzate, elaboriamo un piano personalizzato adatto alle tue esigenze specifiche.",
              "orthstep3": "Processo di fabbricazione",
              "orthstep3p": " I tuoi allineatori invisibili o apparecchi metallici verranno fabbricati utilizzando impronte digitali dei tuoi denti per un'aderenza precisa e comfort ottimale.",
              "orthstep4": "Indossare gli Allineatori o Posizionare gli Apparecchi",
              "orthstep4p": "Indosserai gli allineatori per 20-22 ore al giorno o i nostri dentisti applicheranno gli apparecchi con attenzione al comfort.",
              "orthstep5": "Ritrova il tuo sorriso ",
              "orthstep5p": "Nel tempo, noterai i tuoi denti spostarsi gradualmente verso l'allineamento corretto, avvicinandoti al tuo sorriso desiderato.",
              "veneersheroh": "Scopri Premium Faccette Dentali",
              "veneersherop": "La nostra dedizione si traduce in risultati eccezionali per ogni trattamento ortodontico. Mostra a tutti il sorriso dei tuoi sogni con le nostre cure su misura.",
              "veneersbenefit": "Vantaggi delle Faccette Dentali",
              "veneersbenefitp": "Le faccette sono una soluzione efficace per una serie di problemi estetici dentali come denti scoloriti, scheggiati o disallineati",
              "veneersbenefit1": "Estetica",
              "veneersbenefit1p": "Le nostre faccette sapientemente realizzate perfezionano l'aspetto dei denti quando scoloriti, macchiati, scheggiati, incrinati o che presentano spazi tra loro.",
              "veneersbenefit2": "Personalizzazione",
              "veneersbenefit2p": "Ogni faccetta è realizzata su misura. Si adatta al colore, alla forma, alle dimensioni e alla consistenza dei tuoi denti naturali, garantendoti una perfetta integrazione con il tuo sorriso",
              "veneersbenefit3": "Durata",
              "veneersbenefit3p": "Utilizziamo materiali di alta qualità, come la porcellana o la resina composita, riconosciuti per la loro resistenza e longevità",
              "veneersbenefit4": "Minimamente invasivo",
              "veneersbenefit4p": "Il nostro approccio minimamente invasivo preserva la maggior parte della struttura del dente naturale legando le faccette alla superficie anteriore dei denti",
              "veneersstep1": "Consultazione",
              "veneersstep1p": "Il processo inizia con un approfondito esame della tua salute orale. Discuteremo i tuoi obiettivi e il tipo ottimale di faccette.",
              "veneersstep2": "Preparazione",
              "veneersstep2p": "Questa fase potrebbe richiedere la rimozione di smalto dentale per l'applicazione delle faccette. Successivamente, effettueremo un'impresione o scansione digitale dei tuoi denti.",
              "veneersstep3": "Creazione",
              "veneersstep3p": "Basandoci sulle impronte, creeremo le faccette con materiali resistenti che si armonizzano con gli altri denti.",
              "veneersstep4": "Posizionamento",
              "veneersstep4p": "Dopo aver pulito i denti, applicheremo le faccette sulla superficie utilizzando un adesivo speciale.",
              "veneersinfoh": "Odontoiatria Estetica e Rifacimento del Sorriso",
              "veneersinfop": "Oltre alle faccette dentali, Andent offre una gamma di procedure di odontoiatria estetica per aiutarti a ottenere il sorriso che hai sempre desiderato",
              "veneersinfo1": "Sbiancamento dei denti",
              "veneersinfo1p": "I nostri trattamenti professionali per lo sbiancamento dei denti rimuovono efficacemente macchie e segni di scolorimento, rivelando un sorriso più luminoso e vibrante",
              "veneersinfo2": "Legame composito",
              "veneersinfo2p": "Attraverso il legame composito, ripariamo denti scheggiati, incrinati o cariati utilizzando materiale in resina del colore del dente",
              "veneersinfo3": "Rifacimento del sorriso",
              "veneersinfo3p": "I nostri rifacimenti del sorriso affrontano molteplici aspetti del tuo sorriso combinando varie procedure cosmetiche su misura per le tue esigenze uniche",
              "veneersgirlh": "Ottieni il sorriso che hai sempre desiderato con il nostro faccette dentali",
              "veneersgirlp": "Contattaci oggi per programmare una consulenza e lascia che il nostro team crei un piano di trattamento personalizzato per ottenere risultati belli e dall'aspetto naturale",
              "denturesheroh": "Scopri Premium Protesi Dentali",
              "denturesherop": "installiamo protesi accuratamente selezionate che si adattano perfettamente  alla tua bocca pensate per replicare l'aspetto dei denti naturali. Fai il primo passo verso il sorriso dei tuoi sogni con le nostre cure personalizzate.",
              "dentureswork": "Come Funzionano le Protesi Dentarie",
              "denturesworkh3": "Design dall'aspetto naturale",
              "denturesworkp": "Le protesi sono dispositivi rimovibili che replicano l'aspetto naturale di denti e gengive. Su misura per la tua bocca, riportano sorriso, masticazione e parlato corretti. Realizzate con materiali durevoli, confortevoli e funzionali.",
              "denturesworkp1": "Design su Misura",
              "denturesworkp2": "Facile da Montare ",
              "denturestype": "Tipi di Dentiere",
              "denturestypep": "Le dentiere offrono una soluzione affidabile per problemi dentali come denti mancanti, danneggiati o cariati, ripristinando sia la funzionalità che l'estetica.",
              "denturestype1": "Protesi Complete",
              "denturestype1p": "Le protesi totali rimpiazzano tutti i tuoi denti naturali, adattandosi perfettamente alla bocca per un sorriso completo e naturale.",
              "denturestype2": "Protesi Parziali",
              "denturestype2p": "Se hai denti naturali rimanenti, le protesi parziali sono create per riempire gli spazi vuoti, ripristinando sia l'aspetto che la funzionalità.",
              "denturestype3": "Protesi Supportate da Impianti",
              "denturestype3p": "Le protesi supportate dagli impianti sono ancorate a impianti dentali garantendoti stabilità, comfort e migliore masticazione.",
              "denturestep1": "Consultazione",
              "denturestep1p": "Il nostro team valuterà la tua salute orale e valuterò le opzioni protesiche basandosi sulle tue specifiche necessità e preferenze.",
              "denturestep2": "Impressioni e Misurazioni",
              "denturestep2p": "Vengono prese impronte e misurazioni accurate della tua bocca per garantire una vestibilità corretta e una funzionalità ottimale delle protesi.",
              "denturestep3": "Fabbricazione delle Protesi Dentarie",
              "denturestep3p": "Utilizzando tecnologie dentali avanzate e materiali di alta qualità, le protesi sono create con attenzione per un aspetto naturale e un comfort ottimale.",
              "denturestep4": "Montaggio e Regolazioni",
              "denturestep4p": "Una volta pronte, verificheremo che le tue protesi siano ben adattate e apporteremo eventuali correzioni per assicurare comfort e funzionalità ottimali.",
              "testhero": "La parola al Paziente",
              "testherored": "Testimonianze",
              "testp1": "Tutti dicono di essere bravi.  Noi preferiamo sentircelo dire dai nostri pazienti!",
              "testp2": "",
              "testp3": "Guardali e ascoltali: troverai la soddisfazione di chi ha risolto ogni problema e sta per tornare a casa con un sorriso magnifico!",
              "test3name": "Antonia Naselli",
              "test3desc": "Le protesi sono apparecchi dentali rimovibili realizzati per assomigliare ai denti e alle gengive naturali. Sono realizzati su misura per adattarsi alla forma unica della tua bocca e sono progettati per ripristinare il tuo sorriso e la capacità di masticare e parlare correttamente. Le protesi sono realizzate con materiali durevoli, confortevoli e funzionali.",
              "test4name": "Emiliano",
              "test4desc": "Le protesi sono apparecchi dentali rimovibili realizzati per assomigliare ai denti e alle gengive naturali. Sono realizzati su misura per adattarsi alla forma unica della tua bocca e sono progettati per ripristinare il tuo sorriso e la capacità di masticare e parlare correttamente. Le protesi sono realizzate con materiali durevoli, confortevoli e funzionali.",
              "test5name": "Giuseppe",
              "test5desc": "Le protesi sono apparecchi dentali rimovibili realizzati per assomigliare ai denti e alle gengive naturali. Sono realizzati su misura per adattarsi alla forma unica della tua bocca e sono progettati per ripristinare il tuo sorriso e la capacità di masticare e parlare correttamente. Le protesi sono realizzate con materiali durevoli, confortevoli e funzionali.",
              "test6name": "Rossella",
              "test6desc": "Le protesi sono apparecchi dentali rimovibili realizzati per assomigliare ai denti e alle gengive naturali. Sono realizzati su misura per adattarsi alla forma unica della tua bocca e sono progettati per ripristinare il tuo sorriso e la capacità di masticare e parlare correttamente. Le protesi sono realizzate con materiali durevoli, confortevoli e funzionali.",
              "test7name": "Antonio",
              "test7desc": "Le protesi sono apparecchi dentali rimovibili realizzati per assomigliare ai denti e alle gengive naturali. Sono realizzati su misura per adattarsi alla forma unica della tua bocca e sono progettati per ripristinare il tuo sorriso e la capacità di masticare e parlare correttamente. Le protesi sono realizzate con materiali durevoli, confortevoli e funzionali.",
              "test8name": "Mikela",
              "test8desc": "Le protesi sono apparecchi dentali rimovibili realizzati per assomigliare ai denti e alle gengive naturali. Sono realizzati su misura per adattarsi alla forma unica della tua bocca e sono progettati per ripristinare il tuo sorriso e la capacità di masticare e parlare correttamente. Le protesi sono realizzate con materiali durevoli, confortevoli e funzionali.",
              "test9name": "Matteo",
              "test9desc": "Le protesi sono apparecchi dentali rimovibili realizzati per assomigliare ai denti e alle gengive naturali. Sono realizzati su misura per adattarsi alla forma unica della tua bocca e sono progettati per ripristinare il tuo sorriso e la capacità di masticare e parlare correttamente. Le protesi sono realizzate con materiali durevoli, confortevoli e funzionali.",
              "test10name": "Giorgio",
              "test10desc": "Le protesi sono apparecchi dentali rimovibili realizzati per assomigliare ai denti e alle gengive naturali. Sono realizzati su misura per adattarsi alla forma unica della tua bocca e sono progettati per ripristinare il tuo sorriso e la capacità di masticare e parlare correttamente. Le protesi sono realizzate con materiali durevoli, confortevoli e funzionali.",
              "beforeandafter": "Prima e dopo le immagini dei clienti",
              "belowlogo": "",
              "monday": "Lunedi",
              "saturday": "Sabato",
              "otherlinks": "Altri collegamenti",
              "dentalimplantsh":"it/impianti-dentali",
              "dentalbridgesh":"it/ponti-corone",
              "whyush":"it/cure-dentistiche",
              "dentaltourismh":"it/turismo-dentale-albania",
              "testimonialsh":"it/testimonianze",
              "blogh":"blog",
              "generaldentistryh":"it/servizi-odontoiatria",
              "orthodonticsh":"it/trattamenti-ortodontici",
              "denturesh":"it/protesi-dentali",
              "dentalveneersh":"it/faccette-dentali",
              "contactush":'it/contattaci',
              "homelink":"it",
              "hometitle":"La migliore clinica dentale a Tirana, in Albania - Andent",
              "implantstitle":"Procedura Avanzata per Impianti Dentali - Andent Tirana, Albania",
              "bridgestitle":"Ripristina il tuo sorriso con ponti e corone dentali - Andent",
              "generaltitle":"Servizi di Odontoiatria Generale - Andent Tirana",
              "tourismtitle":"I migliori servizi di turismo dentale a Tirana, Albania - Andent",
              "whyustitle":"Andent Dental Clinic Tirana, Albania - Eccellenza nelle cure dentistiche",
              "orthtitle":"Trattamenti ortodontici efficaci - Andent Tirana, Albania",
              "veneerstitle":"Trasforma il tuo sorriso con le faccette dentali - Andent Tirana",
              "denturestitle":"Protesi totali e parziali di alta qualità - Andent Tirana, Albania",
              "testtitle":"Testimonianze dei Nostri Pazienti - Esperienze con Andent Tirana, Albania",
              "homedesc":"Clinica dentale a Tirana per trattamenti dentali e turismo dentale. Esperienza professionale e cura completa per il tuo sorriso. Prenota la tua visita oggi stesso e Scopri di più!",
              "implantsdesc":"Scopri gli impianti dentali presso la clinica Andent a Tirana, leggi le esperienze dei clienti. Vantaggi e risultati straordinari per il tuo sorriso. Richiedi subito un preventivo personalizzato!",
              "bridgesdesc":"Scopri i nostri servizi dentali in Albania presso Clinica Andent. Esperti in ponti e corone per sorrisi sani e splendenti. Prenota la tua visita oggi.",
              "generaldesc":"Clinica dentale Andent in Albania: Servizi di odontoiatria, visite, otturazioni e pulizie. Anche turismo dentale. Scopri di più sul nostro sito.",
              "tourismdesc":"Scopri il turismo dentale al suo meglio a Tirana, in Albania, con Andent Dental Clinic.Trattamenti dentali di alta qualità e sistemazioni di livello superiore mentre esplori la bellissima città di Tirana. Ottieni subito un preventivo!",
              "whyusdesc":"Scopri cure dentistiche convenienti e di qualità in Albania a Tirana. Godi dell’esperienza decennale del team per un viaggio dentale indimenticabile.",
              "orthdesc":"Trattamenti ortodontici di alta qualità a Tirana con risparmio garantito. Raddrizza i denti e ottieni un sorriso perfetto. Scopri il turismo dentale da Andent.",
              "veneersdesc":"Scopri le faccette dentali da Andent, clinica dentale economica e di qualità a Tirana, Albania. Rinnova il tuo sorriso con fiducia.",
              "denturesdesc":"Ritrova il sorriso con protesi dentali personalizzate presso la nostra clinica economica e all'avanguardia a Tirana. Dentiere su misura per te.",
              "testdesc":"Scopri le testimonianze dirette dei nostri pazienti riguardo alle loro esperienze con Andent a Tirana, Albania. Leggi le storie di successo sulle procedure dentali e l'attenzione personalizzata che offriamo.",
              'heroimg1': 'Andent Dental Clinic',
              'heroimg2': 'Clinica dentistica Andent Tirana',
              'heroimg3': 'Dentisti Andent',
              'heroimg4': 'Protesi dentarie in Albania',
              'heroimg5': 'Cura dentale Andent',
              'heroimg6': 'Servizi dentali in Albania',
              'heroimg7': 'Dentisti a Tirana',
              'heroimg8': 'Dentisti in Albania',
              'whyus1': 'Cura dentale in Albania',
              'whyus2': 'Clinica dentale Albania',
              'whyus3': 'Clinica dentale in Albania',
              'whyus4': 'Andent Albania',
              'whyus5': 'Clinica dentistica Andent Albania',
              'tourism1': 'Alloggio per il turismo dentale',
              'tourism2': 'Turismo dentale in Albania',
              'tourism3': 'Guida al turismo dentale',
              'howimplantwork1': 'Impianto dentale',
              'howimplantwork2': 'Impianti dentali',
              'howimplantwork3': "Come funziona l'impianto dentale",
              'howimplantwork4': 'Corona in ceramica',
              'implantstypes1': 'Tipi di impianti dentali',
              'implantstypes2': 'Chirurgia degli impianti dentali',
              'heartgirl': 'Servizi dentali Andent',
              'bridgeswork1': 'Corone dentali',
              'bridgeswork2': 'Ponti dentali',
              'crownstype1': 'Corone in zirconia',
              'crownstype2': 'Corone in porcellana',
              'crownstype3': 'Corone in ceramica',
              'tourismbenefit1': 'Clinica dentistica Andent Albania',
              'tourismbenefit2': 'Turismo dentale',
              'tourismbenefit3': 'Benefici del turismo dentale',
              'wecltoandent': 'Viaggio dentale',
              'easyaccessible': 'Viaggio dentale in Albania',
              'securityimg1': 'Protocollo di sicurezza',
              'securityimg2': 'Tecnologia avanzata',
              'securityimg3': 'DNA',
              'securityimg4': 'Tecnologia laser',
              'securityimg5': 'Garanzia per i servizi dentali',
              'securityimg6': 'Scansioni dentali CT 3D',
              'orthtype1': 'Parentesi quadre tradizionali',
              'orthtype2': 'Invisalign',
              'alignersbenefit1': 'Ortodonzia',
              'stepbysteporth1': 'Consulenza dentale',
              'stepbysteporth2': 'Piano di trattamento personalizzato',
              'stepbysteporth3': 'Invisalign personalizzate',
              'stepbysteporth4': 'Utilizzo degli allineatori',
              'stepbysteporth5': 'Trasformazione del sorriso Andent',
              'denturesworkimg': 'Protesi dentarie in Albania',
              'denturestypeimg1': 'Protesi complete',
              'denturestypeimg2': 'Protesi parziali',
              'denturestypeimg3': 'Protesi supportate dagli impianti',
              'denturesstep1': 'Opzioni di protesi',
              'denturesstep2': 'Misure della tua bocca',
              'denturesstep3': 'Fabbricazione delle protesi',
              'denturesstep4': 'Garanzia di comfort ottimale',
              'veneersstep1img': 'Rivestimenti dentali in Albania',
              'veneersstep2img': 'Rivestimenti dentali Albania',
              'veneersstep3img': 'Rivestimenti realizzati a Tirana',
              'veneersstep4img': 'Sbiancamento dei denti',
              'cosmdent1': 'Odontoiatria estetica a Tirana',
              'cosmdent2': 'Bonding composito',
              'cosmdent3': 'Trasformazioni del sorriso',
              'veneersbottom': 'Procedure di odontoiatria estetica',
              'beforeafter1': 'Testimonianza Andent',
              'beforeafter2': 'Pazienti felici Andent',
              'beforeafter3': 'Testimonianze Andent',
              'beforeafter4': 'Dentisti esperti in Albania',
              'beforeafter5': 'Protesi accuratamente realizzate',
              'beforeafter6': 'Sbiancamento dei denti a Tirana',
              'beforeafter7': 'Sorriso da sogno in Albania',
              'beforeafter8': 'Cura dentale personalizzata',
              'beforeafter9': 'immagini prima e dopo delle procedure dentistiche',
              'beforeafter10': 'Clienti Andent',
              'beforeafter11': 'Clinica dentistica Tirana',
              'beforeafter12': 'Cura dentale Albania',
              'beforeafter13': 'Dentista a Tirana',
              'beforeafter14': 'Servizi dentali Tirana',
              'beforeafter15': 'Specialisti dentali Tirana',
              'beforeafter16': 'Servizi dentali accessibili in Albania',
              'beforeafter17': 'Controlli dentali Tirana',
              'beforeafter18': 'Procedure dentali Albania',
              'beforeafter19': 'Trasformazione del sorriso Tirana',
              'beforeafter20': 'Odontoiatria restaurativa in Albania',
              "thankyou":"Grazie! Ti contatteremo il prima possibile",
              "consent":"Facendo clic su 'Accetta tutti i cookie', accetti la memorizzazione dei cookie sul tuo dispositivo per migliorare la navigazione del sito, analizzare l'utilizzo del sito e assistere nelle nostre attività di marketing. Per informazioni più dettagliate sui cookie che utilizziamo, consultare la nostra",
              "whatsAppNum":"+355696598059",
              "whatsAppMsg":"Benvenuto! Scrivi qui la tua domanda: ti risponderemo al più presto!",
              "emailLink":"hello@andent.al",
              "simNum":"+355 (0) 69 659 8059",
              "cookiePolicy1":"Noi e terze parti selezionate utilizziamo cookie o tecnologie simili per finalità tecniche e, con il tuo consenso, anche per le finalità di esperienza, misurazione e marketing (con annunci personalizzati). il rifiuto del consenso può rendere non disponibili le relative funzioni.",
              "cookiePolicy2":"Per quanto riguarda la pubblicità, noi e terze parti selezionate, potremmo utilizzare dati di geolocalizzazione precisi e l'identificazione attraverso la scansione del dispositivo, al fine di archiviare e/o accedere a informazioni su un dispositivo e trattare dati personali come i tuoi dati di utilizzo, per le seguenti finalità pubblicitarie: annunci e contenuti personalizzati, valutazione degli annunci e del contenuto, osservazioni del pubblico e sviluppo di prodotti.",
              "cookiePolicy3":"Puoi liberamente prestare, rifiutare o revocare il tuo consenso, in qualsiasi momento, accedendo al pannello delle preferenze.",
              "cookiePolicy":"Gestione dei Cookie",
              "cookiePath":"it/cookie-policy",
              "privacyPol":"Informativa sulla privacy",
              "privacyPolLink":"it/privacy-policy-it",
              "privacyp1":"La presente informativa sulla privacy descrive come la Clinica Dentale Andent (Andent) raccoglie, utilizza e protegge le informazioni personali degli utenti che visitano il nostro sito web, utilizzano i nostri servizi o richiedono incontri o preventivi. Ci impegniamo a garantire la massima tutela della tua privacy e il rispetto delle normative vigenti.",
              "privacyp2":"La Clinica Dentale Andent con sede a Tirana in Rruga Bardhok Biba, Pall. Golden Trema, Shk. B, Kati 4, p.iva M22314039G, e-mail di contatto hello@andent.al, è il titolare del trattamento dei vostri dati personali, usa e protegge tutte le informazioni che gli Utenti consegnano quando usano il sito web www.andent.al e altri nostri servizi online e offline, specialmente riguardo il trattamento dei dati personali nell’offrire i propri servizi.",
              "privacyp3":"L'utente ha il diritto di accedere, correggere, cancellare o limitare l’uso delle tue informazioni personali. Può inoltre revocare il consenso al trattamento dei dati in qualsiasi momento. Per farlo, si prega di contattarci tramite i dati di contatto forniti.",
              "privacyp4":"Il dato personale è ogni informazione che si riferisce a una certa persona fisica o che permette di identificare una persona fisica. Precisamente, i dati personali sono considerati tutti i dati con i quali si determina l'identità dellutente (per esempio nome e cognome, indirizzo mail, indirizzo postale e simili). I dati personali di particolare protezione sono quei dati personali particolarmente sensibili e meritevoli di una accurata protezione (per esempio i dati sanitari riferiti alle condizioni dentali). Il trattamento dei dati personali è ogni azione o un gruppo di azioni eseguite sui dati personali, sia con i mezzi automatici o meno, come raccolta, registrazione, organizzazione, archiviazione, modifica o cambiamento, cancellazione, visione, uso, divulgazione via trasmissione, pubblicazione o rendere accessibile in altra maniera, combinazione e implementazione delle operazioni logiche, digitali, matematiche e altre con i dati.",
              "privacyp5":"Come raccogliamo i dati personali",
              "privacyp6":"Andent raccoglie i Suoi dati personali nei casi seguenti:",
              "privacyp61":"se ci contatta direttamente tramite qualsiasi sito web della Clinica per richiedere le informazioni o un’offerta per i nostri servizi, tramite il nostro modulo per il contatto",
              "privacyp62":"se compra direttamente presso di noi un servizio",
              "privacyp63":"se risponde alle nostre campagne dirette di marketing, per esempio compilando il modulo di contatto per inserimento dei dati sulla nostra pagina web",
              "privacyp64":"se i nostri partner ci consegnano i vostri dati nella maniera permessa",
              "privacyp65":"se ha meno di 16 anni, vi preghiamo di non fornirci nessun dato senza permesso del parente o tutore",
              "privacyp7":"Queste informazioni includono, ma non sono limitate a, nome, indirizzo email, numero di telefono, dettagli relativi alle tue esigenze dentali, file relativi ad esami radiografici che descrivono le esigenze dentali e le condizioni sanitarie.",
              "privacyp8":"Utilizziamo cookie e tecnologie simili per raccogliere informazioni sull’uso del nostro sito web. Questi dati ci aiutano a migliorare la tua esperienza online e a personalizzare i contenuti in base alle tue preferenze.",
              "privacyp9":"Come si possono usare i vostri dati",
              "privacyp10":"L’uso dei dati personali in conformità con le regole sulla protezione dei dati personali deve essere giustificato dalle basi legali e noi con questo regolamento fondiamo le basi per ogni uso dei dati personali. Le basi giustificate per il trattamento dei dati sono un interesse legittimo, l’obbligo contrattuale e un dovere legale. Il trattamento di Andent è finalizzato alla promozione e per offrire le informazioni sui propri servizi, con lo scopo di mantenere gli standard più alti della vendita dei servizi e della sua offerta. I diritti fondamentali degli Utenti sono equilibrati rispetto all’interesse legittimo di Andent di trattare i dati con lo scopo indicato. Andent raccoglierà e tratterà i dati di contatto, i dati sui servizi eseguiti, sulle garanzie, anche le informazioni sulla vostra storia sanitaria come paziente.",
              "privacyp11":"I dati personali si possono trasmettere alle terze parti con la condizione che esista una base giustificata per la trasmissione in questo caso dato dal consenso. Le informazioni personali raccolte vengono utilizzate per elaborare preventivi, elaborare e comunicare piani di lavoro, programmare appuntamenti e fornirti informazioni sulle nostre offerte e servizi. Andent si avvale di partner terzi e informa che i dati personali vengono trasmessi e trattati da terzi, i quali possono avere anche sede all’estero (Italia e Svizzera) allo scopo di eseguire e adempiere le richieste degli Utenti.",
              "privacyp12":"Andent può usare, direttamente o tramite partner esterni e con sede all’estero (Italia e Svizzera) il marketing diretto in base ai Suoi dati personali per informarLa sulle novità e sulle promozioni nella nostra Clinica, sulle agevolazioni speciali per l’acquisto, sulle presentazioni dei servizi nuovi, e anche sulle nostre presentazioni alle fiere, conferenze e altri avvenimenti. Il permesso per il marketing diretto può essere ritirato in qualsiasi momento. Ogni persona in qualsiasi momento può anche fare un reclamo sul trattamento dei dati inviando una mail a Andent. La Clinica Andent è soggetta alle leggi dell’Albania.",
              "privacyp13":"Come proteggiamo la sicurezza dei vostri dati",
              "privacy14":"Usiamo vari metodi di sicurezza, incluso il criptaggio e l’autenticazione per proteggere e mantenere la sicurezza, l’integrità e l’accessibilità dei vostri dati. Utilizziamo misure di sicurezza adeguate, per proteggere le tue informazioni personali da accessi non autorizzati, modifiche o divulgazioni. Tuttavia, nessun sistema di trasmissione o di archiviazione dati può essere garantito come completamente sicuro.",
              "privacy15":"Usiamo ad esempio le misure seguenti:",
              "privacy16":"l’accesso personale strettamente limitato ai vostri dati secondo il principio “dell’accesso neccessario”",
              "privacy17":"la trasmissione sicura dei dati raccolti",
              "privacy18":"installazione del firewall nei sistemi IT con lo scopo di vietare l’accesso non autorizzato",
              "privacy19":"localizzazione permanente dell’accesso IT ai sistemi per la scoperta di abusi dei dati personali",
              "privacy20":"Tutti i vostri dati vengono archiviati sui nostri server sicuri e sui server sicuri dei nostri partner e vengono resi accessibili ed usati in concordanza con le nostre regole ed i nostri standard di sicurezza. La protezione della privacy dei vostri dati è permanente e Andent sta prendendo tutte le misure necessarie per la loro protezione. I dati personali vengono trattati in una maniera sicura, inclusa la protezione dal trattamento non autorizzato o illegale e o la perdita di dati.",
              "privacy21":"Con la compilazione del modulo di contatto ci lasciate il permesso di trattare i vostri dati personali indicati nel modulo di registrazione o di contatto e compreso gli esami diagnostici inviati per lo scopo specifico. Al di fuori di esso Andent non effettuerà nessun altro trattamento a parte quelli dichiarati e non cederà i dati a terzi se non in esecuzione degli accordi.",
              "privacy22":"Ci obblighiamo di non abusare i dati personali forniti con il modulo di registrazione o di qualche altro modulo o ottenuti tramite cookies, né lasciarli alle terze persone senza il vostro permesso tranne i casi quando la legge lo prescrive dichiaratamente e nei casi quando è necessario per eseguire gli obblighi. I dati personali sono considerati tutti i dati con i quali si determina l’identità del Beneficiario e che servono per rispondere alle richieste del Beneficiario, per la statistica e per eventuale spedizione delle offerte speciali.",
              "privacy23":"Tutti i dati sugli utenti vengono custoditi severamente e sono accessibili solo ai dipendenti e ai partner a cui questi dati sono necessari per lo svolgimento del lavoro. Tutti i dipendenti di Andent ed i partner aziendali sono responsabili di rispettare il principio della protezione della privacy. Ci obblighiamo di fornire la protezione dei vostri dati personali in maniera di raccogliere i dati che sono necessari per adempiere allo scopo del permesso dato o l’interesse legittimo e gli obblighi legali o contrattuali. I dati che vengono automaticamente registrati una volta avuto accesso alla pagina web (l’indirizzo IP, il nome del dominio, la specie del browser, il numero delle viste, il tempo spento sulle pagine e simili) vengono usati esclusivamente per la valutazione della presenza sulla pagina web e per migliorare il suo contenuto e la sua funzionalità, cioé con lo scopo statistico.",
              "privacy24":"Secondo la legislazione valida nazionale e sovranazionale, con lo scopo della protezione della privacy dei dati personali, ci obblighiamo specialmente di trattare i vostri dati secondo la legge e buona fede, raccogliere i dati esclusivamente con lo scopo precisamente specifico e legale, non inoltrare i dati a qualsiasi terza persona senza il vostro permesso precedente, non inoltrare i dati personali ai paesi fuori zona EU se il paese non assicura il livello adatto della protezione dei dati; assicurare l’archiviazione adeguata, sicura in modo che la stessa non supera lo scopo per il quale i dati sono stati raccolti e per il quale vengono trattati; assicurare l’accuratezza dei dati personali; assicurare il trattamento dei dati personali solo per il tempo e per lo scopo per i quali e’ necessario; assicurare tutte le misure tecniche e di organizzazione necessarie e adeguate per prevenire la distruzione, il danno o la perdita dei dati personali dell’Utente.",
              "privacy25":"Andent si riserva di contattare l’Utente per la verifica dell’autenticità della richiesta.",
              "privacy26":"La scadenza della custodia dei dati dell’Utente da parte di Andent dura 10 anni o fino alla ricevuta della richiesta di cancellazione da parte della persona alla quale si riferiscono i dati personali, dopo di che i dati personali vengono cancellati. Andent custodisce i dati personali più lungo della scadenza menzionata solo se é obbligata da una legge nazionale o dalla legislazione sovranazionale. I dati personali che non sono più necessari vengono o irreversibilmente resi anonimi o vengono distrutti in una maniera sicura.",
              "privacy27":"Contatti",
              "privacy28":"Per qualsiasi domanda o richiesta riguardante la tua privacy e i tuoi dati personali, puoi contattarci all’indirizzo hello@andent.al. Utilizzando il nostro sito web o fornendoci le tue informazioni personali, accetti le pratiche descritte in questa informativa. Ci riserviamo il diritto di apportare modifiche a questa informativa in qualsiasi momento, quindi, ti consigliamo di consultare regolarmente questa pagina per verificare eventuali aggiornamenti.",
              "policyNotice":"Acconsento al trattamento dei miei dati personali forniti nel presente modulo per l'elaborazione del preventvo e per le finalità connesse ",
              "policyNotice2":"Acconsento al trattamento dei miei dati personali forniti nel presente modulo per l'elaborazione del preventvo e per le finalità connesse ai servizi offerti dalla Clinica Dentale Andent. Comprendo che i miei dati saranno trattati conformemente alla normativa vigente e che ho il diritto di revocare questo consenso in qualsiasi momento. Acconsento a che i miei dati personali vengano trasmessi e trattati da partner della Clinica Dentale Andent siti all'estero (Italia e Svizzera) per dare attuazione ed esecuzione alla richiesta di preventivo.",
              "policyNotice3":"Garantiamo che i tuoi dati, in specie quelli sulle tue condizioni sanitarie, saranno trattati in modo sicuro e riservato. Per ulteriori informazioni sulla nostra politica sulla privacy, puoi consultare la nostra ",
              "policyLink":"politica sulla privacy.",
              "magicFN": "Nome",
              "magicFN1": "Inserisci il Nome",
              "magicLN": "Cognome",
              "magicLN1": "Inserisci il Cognome",
              "magicE": "Email",
              "magicE1": "Inserisci l'Email",
              "magicN": "Numero",
              "magicN1": "Inserisci il Numero",
              "magicM": "Messaggio",
              "magicM1": "Inserisci il Messaggio",
              "magicUpload": "Carica Panoramica",
              "magicSubmit": "Invia",
              "sizeWarn" : "La dimensione del file supera il limite massimo: 10 MB!"
            }
        },
        sq : {
          translation : {
            'test': 'testimi i përkthimit i18n',
            'home': 'Kreu',
            'implants': 'Implante Dentare',
            'dentalbridges': 'Urat Dentare',
            'aboutus': 'Pse Andent',
            'tourism': 'Turizmi Dentar',
            'testimonial': 'Dëshmitë',
            'blog': 'Blog',
            'otherserv': 'Shërbime të tjera',
            'orthodontics': 'Ortodoncia',
            'general': 'Stomatologji e Përgjithshme',
            'dentures': 'Protezat e Dhëmbëve',
            'veneers': 'Fasetat Dentare',
            'contactus': 'Na kontaktoni',
            'ourservices': 'Shërbimet tona',
            'welcome': 'Mirësevini në Andent',
            'homehero': 'Klinikë Dentare në Tiranë, e njohur për Cilësi dhe Komoditet',
            'homehero2': '',
            'homeheroh2': '',
            'homeheroh22': '',
            'homeherop': 'Ne jemi të përkushtuar për të arritur rezultate të jashtëzakonshme për të gjitha trajtimet ortodontike. Hidhni hapin e parë drejt buzëqeshjes tuaj të ëndrrave me kujdesin tonë të personalizuar.',
            'yourphone': 'Numri juaj i telefonit...',
            'yourname': 'Emri juaj...',
            'callback': 'Merr përsëri thirrjen',
            'reviews': 'Shihni çfarë kanë për të thënë të tjerët',
            'servicesh3': 'Përjetoni këto dhe më shumë',
            "servicesh31":"Përjetoni këto dhe më shumë",
            'servicesh2': 'Shërbimet Dentare',
            'service1': 'Implante Dentare',
            'service2': 'Kurora dhe Ura Dentare',
            'service3': 'Protezat e Dhëmbëve',
            'service4': 'Dentistri Estetike dhe Faseta',
            'service5': 'Trajtimi Ortodontik',
            'service6': 'Stomatologji e Përgjithshme',
            'service1p': 'Rrënjë artificiale të qëndrueshme për të mbështetur dhëmbët zëvendësues me pamje natyrale.',
            'service2p': 'Proteza me cilësi të lartë për dhëmbët e dëmtuar ose që mungojnë, duke ju siguruar qëndrueshmëri dhe jetëgjatësi.',
            'service3p': 'Proteza të bëra me porosi për përtypje dhe të folur të rehatshme, si dhe buzëqeshje pa probleme.',
            'service4p': 'Transformoni buzëqeshjen tuaj me zbardhim, faseta dhe trajtime të personalizuara.',
            'service5p': 'Dhëmbë më të drejtë dhe kafshime korrekte me trajtime ortodontike, duke përfshirë Invisalign.',
            'service6p': 'Kujdes parandalues, trajtime dhe mirëmbajtje për një shëndet optimal oral dhe një buzëqeshje të bukur.',
            'aboutUs': 'Rreth Nesh',
            'whyandent': 'Përse Andent?',
            'whyandentp': 'Klinika juaj Dentare e Besuar në Shqipëri. Me 10+ vite eksperiencë, stomatologia jonë e njohur Dr. Anduela Çurmaku drejton ekipin tonë të jashtëzakonshëm. Nga implantet pa dhimbje, te trajtimet e avancuara, saktësia, siguria dhe pajisjet tona me cilësi të lartë garantojnë shëndet optimal oral. Besojini ekipit tonë të profesionistëve për një buzëqeshje të sigurt.',
            "whyandentp2" : "",
            'learnmore': 'Mëso më shumë',
            'meetTeam': 'Njihuni me ekipin tonë dentar',
            'clerk': 'Sekretare',
            'dentist': 'Stomatologji',
            'secretary': 'Sekretare',
            'tech': 'Teknik dentar',
            'dentaltourismcoord': 'Koordinator i Klinikës Dentare të Turizmit',
            'assistant': 'Asistent dentar',
            'staff1': 'Anduela Curmaku',
            'staff2': 'Blerta Curmaku',
            'staff3': 'Ermal Cuadari',
            'staff4': 'Laurent Bixheku',
            'staff5': 'Leonard Maci',
            'staff6': 'Manuela Manjani',
            'staff7': 'Marvin Dedaj',
            'staff8': 'Rudina Bitri',
            'staff9': 'Safjela Baja',
            'makethemosttrip': 'Përfitoni sa më shumë nga udhëtimi juaj',
            'freeaccom': 'Akomodim falas në një hotel me 4 yje në zemër të Tiranës',
            'freeaccomp': 'Zhytuni në jetën e gjallë dhe atraksionet magjepsëse në Shqipëri ndërkohë që jeni duke marrë shërbime dentare të nivelit më të lartë.',
            'assistancewithtravel': 'Ndihmë me përvojën tuaj të udhëtimit dentar nga fillimi në fund',
            'assistancewithtravelp': 'Shërbime si marrja dhe dërgimi në aeroport, caktimi i takimeve dhe rikujtesat për oraret, shërbime përkthimi dhe më shumë.',
            'traveltips': 'Këshilla për udhëtime dhe ture për të përfituar sa më shumë nga udhëtimi juaj',
            'traveltipsp': "Për t'u siguruar që po përfitoni sa më shumë nga udhëtimi juaj, ne do ta personalizojmë qëndrimin bazuar në pëlqimet dhe mospëlqimet tuaja.",
            'test1name': 'Gaetano',
            'test1desc': 'Dentures are removable dental appliances that are crafted to resemble your natural teeth and gums. They are custom-made to fit your unique mouth shape and are designed to restore your smile and ability to chew and speak properly. Dentures are made from durable materials that are both comfortable and functional.',
            'test2name': 'Roberta',
            'test2desc': 'Dentures are removable dental appliances that are crafted to resemble your natural teeth and gums. They are custom-made to fit your unique mouth shape and are designed to restore your smile and ability to chew and speak properly. Dentures are made from durable materials that are both comfortable and functional.',
            'bookaptmnt': 'Rezervoni një takim',
            'fname': 'Emri',
            'lname': 'Mbiemri',
            'email': 'Email',
            'number': 'Numri i telefonit',
            'comments': 'Shkruani komente',
            'file': 'Panoramex',
            'formpolicy1': 'Duke plotësuar këtë web-form ju keni pranuar termat dhe rregullat e ruajtjes së privacisë. Gjithashtu keni pranuar te merrni njoftime nga departamenti i marketingut mbi të rejat e ofruara nga Andent. Mund të ç’abonoheni në çdo kohë.', 
            'formpolicy2': '', 
            'upload': 'Ngarkoni',
            'uploaded': 'Ngarkuar!',
            'booknow': 'Rezervoni tani',
            'address': 'Adresa',
            'phnum': 'Numri i telefonit:',
            'saveupto70': '',
            'implantshero': 'Zbuloni Implante Dentare Perfekte',
            'implantsherop': 'Thuaji lamtumirë vështirësive nga dhëmbët e humbur ose të dëmtuar. Ne rikthejmë buzëqeshjen tuaj me implantet tona ekonomike dhe cilësore për përmirësimin e shëndetit tuaj oral.',
            'implantsbenefits': 'Avantazhet e Implanteve Dentare',
            'impben1': 'Bazamenti i përhershëm dhe i qëndrueshëm',
            'impben1p': 'Implantet dentare ofrojnë një zgjidhje afatgjatë për zëvendësimin e dhëmbëve që mungojnë, duke ofruar një bazë të sigurt dhe të qëndrueshme.',
            'impben2': 'Funksionaliteti i përmirësuar',
            'impben2p': 'Me implantet dentare, ju mund të rifitoni aftësinë e plotë të përtypjes dhe të  folurin e duhur, duke përmirësuar cilësinë e përgjithshme të jetës tuaj.',
            'impben3': 'Parandalimi i komplikimeve dentare',
            'impben3p': 'Duke zëvendësuar dhëmbët që mungojnë, implantet dentare parandalojnë zhvendosjen e dhëmbëve përreth, duke ruajtur shtrirjen e duhur të kafshimit dhe duke shmangur përkeqësimin e mundshëm të kockave të nofullës.',
            'impben4': 'Ruajtja e strukturës së dhëmbëve',
            'impben4p': 'Implantet dentare ndihmojnë në ruajtjen e integritetit të strukturës suaj dentare, duke parandaluar humbjen e dhëmbëve ngjitur dhe duke mbështetur shëndetin optimal oral.',
            'impben5': 'Stimulimi i shëndetit të mirë të kockave të nofullës',
            'impben5p': 'Implantet stimulojnë kockën e nofullës, duke promovuar rritjen e shëndetshme të kockave dhe duke parandaluar humbjen e kockave që zakonisht shoqërohet me mungesën e dhëmbëve.',
            'implantswork': 'Si funksionojnë implantet dentare',
            'impwork1': 'Implanti futet në kockë',
            'impwork2': 'Procesi i shërimit të kockave (oseointegrimi)',
            'impwork3': 'Dental Abutment vendoset në implant',
            'impwork4': 'Vendoset kurorë qeramike e cila zëvendëson dhëmbin e vërtetë',
            'experiencelifechanging': 'Përmirësoni shëndetin oral me implantet dentare në klinikën tonë.',
            'experiencelifechanging1': '',
            'experiencelifechanging3': '',
            'experiencelifechangingp': 'Ekipi ynë me përvojë siguron vendosje të saktë, funksionale dhe me estetikë të implantit',
            'stepbystep': 'Procedura hap pas hapi me Andent',
            'imp1': 'Vlerësim Gjithëpërfshirës',
            'imp1p': 'Ne e nisim udhëtimin tuaj me një vlerësim të hollësishëm të shëndetit tuaj oral, duke kuptuar nevojat tuaja dhe duke diskutuar zgjidhjen më të mirë të implantit për ju. Ne përdorim teknologji të avancuar imazherike për planifikim të saktë të trajtimit',
            'imp2': 'Kirurgjia për vendosjen e implantit dentar',
            'imp2p': 'Ekspertët tanë dentarë do ta vendosin implantin e titanit në nofullën tuaj. Kjo bëhet me saktësi dhe kujdes, duke i dhënë përparësi komoditetit tuaj gjatë gjithë procesit.',
            'imp3': 'Shërimi dhe Integrimi',
            'imp3p': 'Pas operacionit, ne lejojmë kohë për osseointegrimin, ku implanti bashkohet me kockën e nofullës. Ky proces shërimi, i cili mund të zgjasë disa muaj, siguron një bazë të qëndrueshme për dhëmbin e ri.',
            'imp4': 'Vendosja e abutmentit',
            'imp4p': 'Pasi ndodh shërimi, abutmenti, një lidhës i vogël, i shtohet implantit. Kjo do të mbajë dhëmbin tuaj të ri në vend.',
            'imp5': 'Një restaurim perfekt',
            'imp5p': "Hapi i fundit është vendosja e dhëmbit protetik, i punuar bukur për t'u përzier pa probleme me dhëmbët tuaj natyralë. Me buzëqeshjen tuaj të re, ju jeni gati të përtypni, të flisni dhe të rrezatoni vetëbesim.",
            'imptypes': 'Llojet e Implanteve Dentare',
            'impt1': 'Implante Zirkoni',
            'impt1p1': 'Opsioni pa metal, me estetikë të shkëlqyer.',
            'impt1p2': 'Material qeramik biokompatibël.',
            'impt1p3': 'E përkryer për pacientët që kërkojnë një pamje dhe ndjesi natyrale.',
            'impt2': 'Implante Titani',
            'impt2p1': 'Zgjidhje afatgjatë për zëvendësimin natyral të dhëmbëve',
            'impt2p2': 'Rreziku i reduktuar i refuzimit ose reaksioneve alergjike',
            'impt2p3': 'Parandalon humbjen e kockës nga mungesa e dhëmbëve',
            'heartgirlh': 'Na kontaktoni për të mësuar më shumë rreth shërbimeve tona',
            'heartgirlp': 'Të udhëhequr nga ekipi ynë i ekspertëve, ne fokusohemi në komoditetin dhe kënaqësinë tuaj. Për një buzëqeshje të shkëlqyeshme me Andent.',
            'bridgeshero': 'Zbuloni Urat & Kurorat Dentare',
            'bridgesherop': 'Thuaji lamtumirë vështirësive nga dhëmbët e humbur ose të dëmtuar. Ne rikthejmë buzëqeshjen tuaj me implantet tona ekonomike dhe cilësore për përmirësimin e shëndetit tuaj oral.',
            'bridgesimportance': 'Rëndësia e Urave & Kurorave Dentare',
            'bridgesimp1': 'Rikthehet funksioni i përtypjes',
            'bridgesimp1p': 'Rifitoni aftësi më të mira të kafshimit dhe përtypjes me kurora dhe ura të qëndrueshme dentare dhe shijoni ushqimet tuaja të preferuara pa shqetësime',
            'bridgesimp2': 'Përmirësimi i të folurit',
            'bridgesimp2p': 'Kurora dhe urat dentare mbushin boshllëqet duke siguruar komunikim të qartë dhe të artikuluar',
            'bridgesimp3': 'Estetikë dhe vetëbesim i përmirësuar',
            'bridgesimp3p': 'Arrini një buzëqeshje me pamje natyrale që rrit vetëbesimin dhe përfshihuni me lehtësi në ndërveprimet sociale',
            'bridgesimp4': 'Jetëgjatësia dhe siguria',
            'bridgesimp4p': 'Kurorat dhe urat tona dentare të krijuara me mjeshtëri ofrojnë qëndrueshmëri dhe performancë të sigurtë për vite me rradhë',
            'bridgesimp5': 'Ruajtja e shëndetit oral',
            'bridgesimp5p': 'Ruani strukturën e nofullës dhe fytyrës, duke parandaluar humbjen e kockës dhe duke ruajtur shtrirjen natyrale të dhëmbëve',
            'howbridgeswork': 'Si funksionojnë urat dhe kurorat dentare',
            'bridges': 'Urat',
            'bridgesp': 'Urat janë proteza fikse që zëvendësojnë dhëmbët që mungojnë duke përdorur dhëmbë artificialë për të plotësuar boshllëkun. Mbështeten te dhëmbët fqinjë ose implantet.',
            'crowns': 'Kurora',
            'crownsp': 'Kurorat janë restaurime në formë dhëmbi të bëra me porosi që mbrojnë dhe forcojnë dhëmbët e dëmtuar, ndërkohë që përmirësojnë pamjen e tyre.',
            'howbridgesdifferent': 'Si ndryshojnë urat nga implantet',
            'howbridgesdifferentp': 'Urat dentare varen nga dhëmbët ngjitur për mbështetje, ndërsa implantet dentare janë të ankoruara në mënyrë të pavarur në kockën e nofullës, duke ofruar qëndrueshmëri të shtuar',
            'bridges1': 'Konsultimi',
            'bridges1p': 'Ekipi ynë dentar me përvojë do të vlerësojë shëndetin tuaj oral dhe do të diskutojë qëllimet dhe shqetësimet tuaja.',
            'bridges2': 'Planifikimi i trajtimit',
            'bridges2p': 'Një plan trajtimi i personalizuar do të zhvillohet bazuar në nevojat dhe preferencat tuaja unike.',
            'bridges3': 'Përgatitja e dhëmbëve',
            'bridges3p': 'Për kurorat, dhëmbit të dëmtuar i jepet formë për të krijuar hapësirë për kurorën. Për urat, dhëmbët ngjitur përgatiten për të mbështetur urën.',
            'bridges4': 'Përshtypjet për të siguruar përshtatje optimale',
            'bridges4p': "Merret një përshtypje e dhëmbëve tuaj për t'u siguruar që kurorat ose urat janë të përshtatshme për komoditet dhe funksionalitet optimal.",
            'bridges5': 'Vendosja e kurorave ose urave',
            'bridges5p': 'Pasi kurorat ose urat tuaja të personalizuara të jenë gati, ato do të lidhen mirë ose fiksohen në vend dhe ju mund të shijoni dhëmbët tuaj të rinj.',
            'bridgestypes': 'Llojet e Urave & Kurorave Dentare',
            'bridgest1': 'Zirkon',
            'bridgest1p': 'Të njohura për forcën dhe qëndrueshmërinë e tyre, kurorat e zirkonit janë shumë rezistente ndaj copëtimit ose plasaritjes.',
            'bridgest2': 'Porcelan',
            'bridgest2p': 'Kurorat prej porcelani me pamje natyrale dhe të qëndrueshme janë një zgjedhje popullore për dhëmbët e përparmë.',
            'bridgest3': 'Qeramik',
            'bridgest3p': 'Kurorat qeramike ofrojnë estetikë të shkëlqyer dhe janë pa metal, duke i bërë ato një opsion të shkëlqyeshëm për pacientët me alergji ndaj metaleve.',
            'generaldentistryhero': 'Zbuloni Shërbime Dentare Premium',
            'generaldentistryherop': 'Thuaji lamtumirë vështirësive nga dhëmbët e humbur ose të dëmtuar. Ne ju rikthejmë buzëqeshjen me urat dhe kurorat dentare cilësore dhe ekonomike, si edhe përmirësojmë shëndetin tuaj oral.',
            'experienceourtreatments': 'Përjetoni trajtimet tona dentare',
            'treatment1h': 'Ekzaminime dhe kontrolle dentare rutinë',
            'treatment1p': 'Parandaloni probleme të mundshme me kontrolle të rregullta. Stomatologët tanë janë të aftë të kryejnë ekzaminime të plota, duke zbuluar në kohë çdo problem të shëndetit oral dhe ofrojnë trajtime për to.',
            'treatment2h': 'Pastrim dentar',
            'treatment2p': 'Përjetoni një buzëqeshje të freskët dhe të shëndetshme me pastrimet profesionale të dhëmbëve. Dentistët tanë përdorin teknika të avancuara për të hequr pllakën, gurët dhe njollat, duke u siguruar që dhëmbët tuaj të pastrohen plotësisht.',
            'treatment3h': 'Mbushje dhëmbësh',
            'treatment3p': 'Rikthejini forcën dhe pamjen dhëmbëve tuaj të dëmtuar permes mbushjeve me amalgamë ose mbushje të përbërë. Stomatologët tanë sigurojnë rezultate të qëndrueshme dhe estetikisht të këndshme, duke ruajtur shëndetin tuaj oral.',
            'treatment4h': 'Heqje dhëmbësh',
            'treatment4p': 'Në rastet kur një dhëmb nuk mund të riparohet për shkak të dëmtimit të rëndë ose infeksionit, dentistët tanë të aftë kryejnë shkuljen e dhëmbëve me kujdes maksimal. Ne ofrojmë udhëzime gjithëpërfshirëse për kujdesin pasues për të lehtësuar një rikuperim të qetë.',
            'treatment5h': 'Sealantët dentarë',
            'treatment5p': 'Mbroni dhëmbët tuaj nga kavitetet me izolues dentarë. Veshjet tona të holla mbrojtëse aplikohen në sipërfaqet përtypëse të dhëmbëve tuaj, veçanërisht të dobishme për fëmijët dhe individët e prirur ndaj prishjes së dhëmbëve.',
            'treatment6h': 'Trajtimet me fluor',
            'treatment6p': 'Forconi smaltin tuaj të dhëmbëve dhe parandaloni prishjen e dhëmbëve me trajtimet tona me fluor. Aplikimi ynë i këtij minerali thelbësor ofron një shtresë shtesë mbrojtjeje, veçanërisht për ata që janë në rrezik më të lartë të kaviteteve.',
            'treatment7h': 'Trajtimi për sëmundjen e mishrave të dhëmbëve (gingiviti)',
            'treatment7p': 'Luftoni sëmundjen e mishrave të dhëmbëve me trajtimet tona efektive. Qasja jonë gjithëpërfshirëse eliminon bakteret dhe rikthen shëndetin e mishrave të dhëmbëve, duke parandaluar komplikimet e mëtejshme të shëndetit oral.',
            'treatment8h': 'Grafi dentare',
            'treatment8p': 'Diagnozat e sakta dhe planifikimi i trajtimit bëhen të mundura me grafi dentare. Teknologjia jonë moderne e imazherisë kap imazhe të detajuara, duke ndihmuar në zbulimin e problemeve të fshehura dentare.',
            'treatment9h': 'Lidhje dentare',
            'treatment9p': 'Përmirësoni pamjen dhe funksionalitetin e dhëmbëve tuaj me lidhjen dentare. Dentistët tanë të aftë përdorin material me ngjyrë dhëmbi për të riparuar ciflosje, çarje ose çngjyrosje, duke rikthyer buzëqeshjen tuaj.',
            'treatment10h': 'Udhëzime për higjienën e gojës',
            'treatment10p': "Ekipi ynë i përkushtuar merr kohë për të edukuar pacientët mbi teknikat e duhura të higjienës orale. Ne ofrojmë udhëzime të personalizuara për t'ju ndihmuar të ruani shëndetin optimal të dhëmbëve në shtëpi.",
            'expdentaltourism': 'Përjetoni më të Mirën e Turizmit Dentar me Andent',
            'expdentaltourismp': 'Nëse jeni duke kërkuar kujdes dentar me cilësi të lartë, të kombinuar me një përvojë të paharrueshme udhëtimi, mos kërkoni më larg se Andent. Ne jemi krenarë që ofrojmë edhe shërbime turistike në kryeqytetin e bukur të Shqipërisë, Tiranë.',
            'tourismbenefits': 'Përfitimet e Turizmit Dentar me Andent',
            'tourismben1': 'Kosto e ulët',
            'tourismben1p': 'Ne ofrojmë shërbime dentare të nivelit të lartë me 70% të kostos që paguhet në vendet e tjera Evropiane. Kjo kosto është një nga faktorët kryesorë që nxit reputacionin tonë si destinacion kryesor për turizmin dentar.',
            'tourismben2': 'Kujdesi cilësor',
            'tourismben2p': 'Stafi ynë sjell mbi 10 vjet përvojë në fusha të ndryshme të stomatologjisë. Duke përdorur teknologji të avancuar dhe duke qëndruar të përditësuar me teknikat më të fundit, ne sigurojmë që çdo pacient të marrë trajtimin më të mirë të mundshëm.',
            'tourismben3': 'Siguaria & Garancia',
            'tourismben3p': 'Ne ndjekim protokollet e rrepta të sigurisë dhe standardet e higjienës për të siguruar një mjedis të sigurt për të gjithë klientët tanë. Klinika jonë është e pajisur me teknologjinë më të fundit dentare për të lehtësuar diagnostikimin dhe trajtimet e sakta, duke eliminuar shqetësimet gjatë vizitës.',
            'gettingtotirana': 'Arritja në Tiranë',
            'easilyaccessible': 'Një destinacion lehtësisht i arritshëm dhe miqësor me buxhetin',
            'safetypriority': 'SIGURIA ESHTE PRIORITETI JONË',
            'securityprotocol': 'Protokolli i Sigurisë & Teknologjia',
            'securityprotocolp': 'Në klinikën Andent, ne i japim përparësi shëndetit dhe sigurisë tuaj duke zbatuar praktikat më të mira dentare dhe duke ruajtur standardet më të larta të higjienës. Ekipi ynë stomatologjik profesionist ndjek udhëzimet ndërkombëtare, duke siguruar një mjedis të sigurt.',
            'securityprotocolp1': 'Zbuloni pajisjet tona më të avancuara dentare të pajisura me mjete dhe teknologji moderne, duke përfshirë:',
            'tech1': 'Skaner Intra dhe Ekstra Oral',
            'tech1p': 'Teknologjia jonë e avancuar e skanimit lejon planifikim dhe ekzekutim të plotë.',
            'tech2': 'Testimi i ADN-së dhe Baktereve',
            'tech2p': 'Testet tona të ADN-së dhe baktereve përdoren për të identifikuar infeksionet e mundshme orale në fazat më të hershme.',
            'tech3': 'Garanci për Shërbimet Dentare',
            'tech3p': 'Ne ofrojmë garanci për shërbimet tona dentare, duke reflektuar angazhimin tonë ndaj jush.',
            'tech4': 'Teknologji Lazer',
            'tech4p': 'Ne sigurojmë shërim të shpejtë dhe shqetësim minimal duke përdorur teknologjinë më të fundit të lazerit',
            'tech5': 'Kamera intraorale',
            'tech5p': 'Përdorimi ynë i kamerave intraorale mundëson pamje vizuale në kohë reale, nga afër të gojës.',
            'tech6': 'Skanim 3D CT dentar',
            'tech6p': 'Skanimet tona CT dentare 3D ofrojnë një pamje gjithëpërfshirëse tre-dimensionale të dhëmbëve tuaj.',
            'whyusherop': 'Përjetoni një kujdes të jashtëzakonshëm dentar',
            'whyusheroh': 'Mirësevini në Andent',
            'whyusherop1': 'Prej krijimit të klinikës në vitin 2007, ne i jemi përkushtuar transformimit të buzëqeshjeve dhe përmirësimit të shëndetit oral për mbi 15,000 pacientë, si brenda vendit, si edhe në Evropë. Angazhimi ynë i palëkundur për sigurinë, teknologjitë e avancuara dentare dhe standardet evropiane të cilësisë, na dallojnë si pionierë në sektorin stomatologjik shqiptar. Me 15 vjet përvojë, ne ofrojmë me krenari një gamë të plotë shërbimesh dentare për të përmbushur të gjitha nevojat e shëndetit oral.',
            'yearsofexperience': 'Vjet Eksperiencë',
            'coredentaltreatments': 'Trajtimet tona kryesore dentare',
            'orthheroh': 'Zbuloni Trajtimet Ortodontike Premium',
            'orthherop': 'Ne jemi të përkushtuar për të dhënë rezultate të jashtëzakonshme për të gjitha trajtimet tona ortodontike. Hidhni hapin e parë drejt buzëqeshjes tuaj të ëndrrave me kujdesin tonë të personalizuar',
            'orthtypes': 'Llojet e trajtimeve ortodontike',
            'ortht1': 'Aparate Dentare Tradicionale',
            'ortht1p': 'Braketat metalike rezistente riorganizojnë dhëmbët me profesionalizëm, duke ofruar një metodë me kosto efektive dhe të provuar për të pasur një\xa0 buzëqeshje të përsosur.',
            'ortht2': 'Invisalign',
            'ortht21': '',
            'ortht2p': 'Invisalign (braketat transparente) janë pajisje\xa0 të tejdukshme te prodhuara me porosi, që ofrojnë një zgjidhje të zgjuar për riorganizimin e dhëmbëve.',
            'orthben': 'Përfitimet e rreshtuesve të padukshëm',
            'orthinfo1': 'Dizajn Superior',
            'orthinfo1p': 'Invisalign, drejtues të padukshëm dhëmbësh,',
            'orthinfo1pspan': 'ofrojnë një trajtim ortodontik revolucionar,',
            'orthinfo1p2': 'estetik dhe të përshtatshëm. Me dizajnin e tyre të qartë, përshtatjen e rehatshme dhe teknologjinë e përparuar 3D, këta drejtues dhëmbësh mundësojnë shtrirje diskrete, të parashikueshme dhe të personalizuar të dhëmbëve, duke ripërcaktuar udhëtimin tuaj ortodontik.',
            'orthinfo2': 'Komoditet',
            'orthinfo2p': 'Drejtuesit e padukshëm integrohen',
            'orthinfo2pspan': 'pa probleme në stilin tuaj të jetesës.',
            'orthinfo2p2': 'Ato mund të hiqen lehtësisht për aktivitetet e përditshme, kërkojnë më pak vizita ortodontike në krahasim me mbajtëset tradicionale dhe promovojnë higjienë më të mirë orale duke reduktuar rreziqet e prishjes së dhëmbëve dhe sëmundjeve të mishrave të dhëmbëve gjatë trajtimit.',
            'howalignerswork': 'Si funksionon Invisalign',
            'aligners1': 'Skanim 3D',
            'aligners1p': 'Fillojmë me skanimin 3D të gojës për të nxjerrë modelin e saktë të dhëmbëve dhe mishrave të dhëmbëve, duke formuar bazën për planin e trajtimit tuaj.',
            'aligners2': 'Plani i trajtimit',
            'aligners2p': 'Planifikojmë lëvizjen e saktë, hap pas hapi, të dhëmbëve nga pozicioni i tyre aktual në shtrirjen përfundimtare të dëshiruar.',
            'aligners3': 'Fabrikimi i Invisalign',
            'aligners3p': 'Krijohet një seri me Invisalign, duke përdorur material plastik të pastër biokompatibël, pa BPA. Seria prodhohet për të siguruar një përshtatje të saktë.',
            'aligners4': 'Kontrolli i Cilësisë',
            'aligners4p': 'Përpara se ta merrni Invisalign, çdo grup i nënshtrohet një kontrolli rigoroz të cilësisë për të siguruar përshtatje të përsosur dhe performancë optimale.',
            'orthstep1': 'Konsultimi',
            'orthstep1p': 'Ekspertët tanë ortodontikë do të vlerësojnë dhëmbët tuaj dhe do të diskutojnë qëllimet tuaja.',
            'orthstep2': 'Plani Trajtimi i Personalizuar',
            'orthstep2p': 'Duke përdorur teknologji të avancuar, ne krijojmë një plan trajtimi të personalizuar të përshtatur për nevojat tuaja unike.',
            'orthstep3': 'Procesi i Fabrikimit',
            'orthstep3p': 'Invisalign-et tuaj të personalizuar ose drejtuesit metalik do të fabrikohen duke përdorur skanimin e dhëmbëve tuaj për një përshtatje të saktë dhe të rehatshme.',
            'orthstep4': 'Vendosja e Invisalign ose kllapave',
            'orthstep4p': 'Ju do të vendosni drejtuesit e padukshem (Invisalign) për 20-22 orë në ditë ose dentisti juaj do të vendosë mbajtëset tuaja metalike të reja në dhëmbët tuaj dhe do të sigurojë një përshtatje të rehatshme.',
            'orthstep5': 'Transformimi i Buzëqeshjes',
            'orthstep5p': 'Me kalimin e kohës, do të vini re që dhëmbët tuaj gradualisht lëvizin në shtrirjen e tyre të duhur, duke ju afruar me buzëqeshjen tuaj të dëshiruar.',
            'veneersheroh': 'Zbuloni për Fasetat Dentare Premium',
            'veneersherop': 'Ne jemi të përkushtuar për të dhënë rezultate të jashtëzakonshme për të gjitha trajtimet tona ortodontike. Hidhni hapin e parë drejt buzëqeshjes tuaj të ëndrrave me kujdesin tonë të personalizuar.',
            'veneersbenefit': 'Përfitimet e fasetave dentare',
            'veneersbenefitp': 'Fasetat janë një zgjidhje efektive për një sërë shqetësimesh dentare kozmetike si dhëmbët e çngjyruar, të prerë ose të shtrembëruar',
            'veneersbenefit1': 'Estetike',
            'veneersbenefit1p': 'Fasetat tona të punuara me mjeshtëri përmirësojnë pamjen e dhëmbëve që janë të zbardhur, të njollosur, të copëtuar, të plasaritur ose që kanë boshllëqe midis tyre.',
            'veneersbenefit2': 'Përshtatje',
            'veneersbenefit2p': 'Çdo fasetë është bërë me porosi që të përputhet me ngjyrën, formën, madhësinë dhe strukturën e dhëmbëve natyralë duke siguruar një integrim të qetë me buzëqeshjen tuaj.',
            'veneersbenefit3': 'Qëndrueshmëria',
            'veneersbenefit3p': 'Ne përdorim materiale me cilësi të lartë, si porcelani ose lidhje kompozite, të njohura për forcën dhe jetëgjatësinë e tyre',
            'veneersbenefit4': 'Minimalisht Invazive',
            'veneersbenefit4p': 'Qasja jonë minimalisht invasive, ruan pjesën më të madhe të strukturës natyrale të dhëmbit duke i lidhur fasetat në sipërfaqen e përparme të dhëmbëve tuaj.',
            'veneersstep1': 'Konsultimi',
            'veneersstep1p': 'Procesi fillon me ekzaminimin e plotë të shëndetit tuaj oral. Më pas diskutojmë mbi llojin e fasetave që i përshtatet më së miri qëllimit dhe mundësive tuaja.',
            'veneersstep2': 'Përgatitja',
            'veneersstep2p': 'Përgatitja mund të përfshijë heqjen e smaltit nga sipërfaqja e dhëmbit për të akomoduar fasetën. Më pas do të bëjmë një grafi ose skanim digjital të dhëmbëve.',
            'veneersstep3': 'Krijimi',
            'veneersstep3p': 'Fasetat krijohen me materiale të qëndrueshme duke u konsultuar me grafinë e bërë më parë, me qëllim përshtatjen e tyre me pjesën tjetër të dhëmbëve.',
            'veneersstep4': 'Vendosja',
            'veneersstep4p': 'Pas pastrimit të dhëmbëve, lidhen fasetat duke përdorur ngjitësin e veçantë dentar.',
            'veneersinfoh': 'Stomatologji Estetike & Smile Makeovers',
            'veneersinfop': "Përveç fasetave dentare, Andent ofron një sërë procedurash stomatologjike kozmetike për t'ju ndihmuar të keni buzëqeshjen që keni dëshiruar gjithmonë",
            'veneersinfo1': 'Zbardhim Dhëmbësh',
            'veneersinfo1p': 'Trajtimet tona profesionale për zbardhjen e dhëmbëve heqin në mënyrë efektive njollat dhe çngjyrosjen e tyre, duke siguruar një buzëqeshje më të ndritshme dhe të shëndetshme.',
            'veneersinfo2': 'Lidhje Kompozit',
            'veneersinfo2p': 'Nëpërmjet lidhjes Kompozit, mund të riparojmë dhëmbët e copëtuar, të plasaritur ose të prishur duke përdorur material rrëshirë me ngjyrë dhëmbi.',
            'veneersinfo3': 'Smile Makeovers',
            'veneersinfo3p': 'Smile Makeovers trajtojnë aspekte të shumta të buzëqeshjes tuaj duke kombinuar procedura të ndryshme kozmetike të përshtatura për nevojat tuaja unike.',
            'veneersgirlh': 'Arrini buzëqeshjen që keni dashur gjithmonë me fasetat dentare',
            'veneersgirlp': 'Na kontaktoni sot për të caktuar një konsultë dhe ekipi ynë të krijojë një plan trajtimi të personalizuar për të dhënë rezultate të bukura, me pamje natyrale',
            'denturesheroh': 'Zbuloni Protezat Premium',
            'denturesherop': "Ne jemi të përkushtuar t'ju ofrojmë proteza të punuara me kujdes, në mënyrë që të përshtaten me gojën tuaj dhe të përsërisin pamjen e dhëmbëve natyralë. Hidhni hapin e parë drejt buzëqeshjes tuaj të ëndrrave me kujdesin tonë të personalizuar.",
            'dentureswork': 'Si funksionojnë protezat',
            'denturesworkh3': 'Dizajn me pamje natyrale',
            'denturesworkp': "Protezat janë pajisje dentare të lëvizëshme të krijuara për t'iu përafruar dhëmbëve dhe mishrave të dhëmbëve tuaj natyralë. Ato bëhen me porosi për t'iu përshtatur formës tuaj unike të gojës dhe janë krijuar për të rikthyer buzëqeshjen dhe aftësinë tuaj për të përtypur dhe folur siç duhet. Protezat janë bërë nga materiale të qëndrueshme që janë të rehatshme dhe funksionale.",
            'denturesworkp1': 'Dizajn i bërë me porosi',
            'denturesworkp2': "Lehtë për t'u mirëmbajtur",
            'denturestype': 'Llojet e Protezave',
            'denturestypep': 'Protezat ofrojnë zgjidhje të besueshme për problemet dentare si, dhëmbët e humbur, të dëmtuar ose të prishur, duke rikthyer funksionalitetin dhe estetikën.',
            'denturestype1': 'Proteza të plota',
            'denturestype1p': 'Protezat e plota bëhen me porosi për të zëvendësuar të gjithë dhëmbët e munguar natyralë duke u përshtatur në mënyrë perfekte me gojën dhe duke krijuar një buzëqeshje të plotë dhe me pamje natyrale.',
            'denturestype2': 'Proteza të pjesshme',
            'denturestype2p': 'Nëse ju mungojnë një ose më shumë dhëmbë natyralë, ofrojmë protezat e pjesshme\xa0 të krijuara për të mbushur boshllëqet, duke rikthyer estetikën dhe funksionalitetin',
            'denturestype3': 'Proteza të mbështetura me implant',
            'denturestype3p': 'Protezat e mbështetura nga implantet ngjiten në implantet dentare për qëndrueshmëri dhe rehati të shtuar duke siguruar aftësi të përmirësuar përtypëse.',
            'denturestep1': 'Konsultimi',
            'denturestep1p': 'Ekipi ynë vlerëson shëndetin oral dhe diskuton opsionet tuaja të protezës bazuar në nevojat dhe preferencat specifike.',
            'denturestep2': 'Grafia dhe Matjet',
            'denturestep2p': 'Grafitë dhe matjet e sakta të gojës merren për të siguruar përshtatjen e duhur dhe funksionalitet optimal të protezave.',
            'denturestep3': 'Fabrikimi i Protezave',
            'denturestep3p': 'Duke përdorur teknologji të avancuar dentare dhe materiale me cilësi të lartë, protezat tuaja do të përpunohen me përpikëri për të ofruar një pamje natyrale dhe përshtatje të rehatshme.',
            'denturestep4': 'Vendosja dhe përshtatja',
            'denturestep4p': 'Pasi proteza të jetë gati, do të sigurohemi se ato përshtaten siç duhet, si edhe bëjmë çdo rregullim të nevojshëm për të garantuar rehati dhe funksionalitet optimal.',
            'testhero': 'Klienti ynë',
            'testherored': 'Dëshmitë',
            'testp1': "Ne jemi të përkushtuar t'ju ofrojmë standardet më të larta te sherbimeve denteare.",
            'testp2': '',
            'testp3': 'Hidhni hapin e parë drejt buzëqeshjes tuaj të ëndrrave me kujdesin tonë të personalizuar',
            'test3name': 'Antonia Naselli',
            'test3desc': 'Dentures are removable dental appliances that are crafted to resemble your natural teeth and gums. They are custom-made to fit your unique mouth shape and are designed to restore your smile and ability to chew and speak properly. Dentures are made from durable materials that are both comfortable and functional.',
            'test4name': 'Emilio',
            'test4desc': 'Dentures are removable dental appliances that are crafted to resemble your natural teeth and gums. They are custom-made to fit your unique mouth shape and are designed to restore your smile and ability to chew and speak properly. Dentures are made from durable materials that are both comfortable and functional.',
            'test5name': 'Giuseppe',
            'test5desc': 'Dentures are removable dental appliances that are crafted to resemble your natural teeth and gums. They are custom-made to fit your unique mouth shape and are designed to restore your smile and ability to chew and speak properly. Dentures are made from durable materials that are both comfortable and functional.',
            'test6name': 'Rossella',
            'test6desc': 'Dentures are removable dental appliances that are crafted to resemble your natural teeth and gums. They are custom-made to fit your unique mouth shape and are designed to restore your smile and ability to chew and speak properly. Dentures are made from durable materials that are both comfortable and functional.',
            'test7name': 'Antonio',
            'test7desc': 'Dentures are removable dental appliances that are crafted to resemble your natural teeth and gums. They are custom-made to fit your unique mouth shape and are designed to restore your smile and ability to chew and speak properly. Dentures are made from durable materials that are both comfortable and functional.',
            'test8name': 'Mikela',
            'test8desc': 'Dentures are removable dental appliances that are crafted to resemble your natural teeth and gums. They are custom-made to fit your unique mouth shape and are designed to restore your smile and ability to chew and speak properly. Dentures are made from durable materials that are both comfortable and functional.',
            'test9name': 'Mateo',
            'test9desc': 'Dentures are removable dental appliances that are crafted to resemble your natural teeth and gums. They are custom-made to fit your unique mouth shape and are designed to restore your smile and ability to chew and speak properly. Dentures are made from durable materials that are both comfortable and functional.',
            'test10name': 'Gjergjit',
            'test10desc': 'Dentures are removable dental appliances that are crafted to resemble your natural teeth and gums. They are custom-made to fit your unique mouth shape and are designed to restore your smile and ability to chew and speak properly. Dentures are made from durable materials that are both comfortable and functional.',
            'beforeandafter': 'Përpara',
            'belowlogo': '',
            'monday': 'e hënë',
            'saturday': 'e shtunë',
            'otherlinks': 'Lidhje të tjera',
            'dentalimplantsh': 'sq/implante-dentare',
            'dentalbridgesh': 'sq/ura-dentare',
            'whyush': 'sq/perse-andent',
            'dentaltourismh': 'sq/turizem-dentar',
            'testimonialsh': 'sq/dëshmitë',
            'blogh': 'blog',
            'generaldentistryh': 'sq/stomatologji',
            'orthodonticsh': 'sq/ortodoncia',
            'denturesh': 'sq/proteza-dentare',
            'dentalveneersh': 'sq/faseta-dentare',
            'contactush': 'sq/kontakt',
            "homelink":"sq",
            'hometitle': 'Klinika më e mirë dentare në Tiranë, Shqipëri - Andent',
            'implantstitle': 'Implante Dentare - Andent Tirana, Shqipëri',
            'bridgestitle': 'Kurora dhe Ura Dentare - Andent Tirana, Shqipëri',
            'generaltitle': 'Stomatologji e Përgjithshme - Andent Tiranë',
            'tourismtitle': 'Klinika #1 për Turizëm Dentar në Tiranë, Shqipëri - Klinika Dentare Andent',
            'whyustitle': 'Klinika Dentare Andent - Ekselencë në Kujdesin Dentar',
            'orthtitle': 'Ortodoncia - Trajtime Ortodontike Efektive - Andent ',
            'veneerstitle': 'Faseta Dentare - Porcelani, Zirkoni, Emax - Andent Tirana',
            'denturestitle': 'Proteza dentare me cilësi të lartë - Andent Tiranë, Shqipëri',
            'testtitle': 'Dëshmitë e pacientëve të Andent Tiranë, Shqipëri',
            'homedesc': 'Zbuloni kujdesin më të mirë dentar në Tiranë, Shqipëri, në Klinikën Dentare Andent. Ekipi ynë i ekspertëve ofron një sërë shërbimesh për të përmirësuar shëndetin tuaj oral dhe buzëqeshjen. Rezervoni një takim sot!',
            'implantsdesc': 'Rifitoni buzëqeshjen tuaj të sigurt me zgjidhjet më të fundit të implanteve dentare në Klinikën Dentare Andent në Tiranë, Shqipëri. Përjetoni zëvendësimin pa probleme të dhëmbëve me ekipin tonë profesional. Merrni një ofertë të personalizuar tani!',
            'bridgesdesc': 'Klinika Dentare Andent në Tiranë, Shqipëri, e specializuar në kurora dhe ura dentare, duke ofruar zgjidhje të qëndrueshme dhe estetike për të përmirësuar shëndetin tuaj dentar. Telefono tani!',
            'generaldesc': 'Zgjidhni Klinikën Dentare Andent për stomatologji të përgjithshme në Tiranë, Shqipëri. Shërbimet tona gjithëpërfshirëse plotësojnë të gjitha nevojat tuaja të shëndetit oral. Telefono tani!',
            'tourismdesc': 'Përjetoni turizmin dentar në maksimum në Tiranë, Shqipëri, me Klinikën Dentare Andent. Shijoni kujdes cilësor dentar duke eksploruar qytetin e bukur të Tiranës dhe më shumë. Rezervoni një takim sot!',
            'whyusdesc': 'Klinika Dentare ndent në Tiranë, Shqipëri, ofron përsosmëri në kujdesin dentar, duke kombinuar trajtime të avancuara dentare, profesionistë me përvojë dhe një qasje me në qendër pacientin. Telefono tani!',
            'orthdesc': 'Drejtoni dhëmbët dhe arrini një buzëqeshje perfekte me trajtimet tona efektive ortodontike në Klinikën Dentare Andent në Tiranë, Shqipëri. Merrni një ofertë të personalizuar tani!',
            'veneersdesc': 'Përmirësoni buzëqeshjen tuaj me fasetat dentare më të mira në Klinikën Dentare Andent në Tiranë, Shqipëri. Arritni një pamje pa të meta dhe me pamje natyrale. Merrni një ofertë të personalizuar tani!',
            'denturesdesc': 'Rivendosni dhëmbët që ju mungojnë me proteza të plota ose të pjesshme cilësore në Klinikën Dentare Andent në Tiranë, Shqipëri. Rifitoni funksionin dhe besimin në buzëqeshjen tuaj. Telefono tani!',
            'testdesc': 'Dëshmi me foto dhe video nga pacientët tanë për përvojat e tyre me Andent në Tiranë, Shqipëri. Lexoni histori suksesi për procedurat dentare dhe vëmendjen e personalizuar që ne ofrojmë.',
            'heroimg1': 'Klinika Dentare Andent',
            'heroimg2': 'Klinika Dentare Andent në Tiranë',
            'heroimg3': 'Dentistët e Andent',
            'heroimg4': 'Protezat e dhëmbëve në Shqipëri',
            'heroimg5': 'Kujdesi Dentar në Andent',
            'heroimg6': 'Shërbimet Dentare në Shqipëri',
            'heroimg7': 'Dentistë në Tiranë',
            'heroimg8': 'Dentistë në Shqipëri',
            'whyus1': 'Kujdesi Dentar në Shqipëri',
            'whyus2': 'Klinikë Dentare Shqipëri',
            'whyus3': 'Klinika Dentare në Shqipëri',
            'whyus4': 'Andent Shqipëri',
            'whyus5': 'Andent Klinikë Dentare Shqipëri',
            'tourism1': 'Akomodimi për Turizmin Dentar',
            'tourism2': 'Turizmi Dentar në Shqipëri',
            'tourism3': 'Guida për Turizmin Dentar',
            'howimplantwork1': 'Implant Dentar',
            'howimplantwork2': 'Implante Dentare',
            'howimplantwork3': 'Si funksionon Implanti Dentar',
            'howimplantwork4': 'Kurora e Qeramikës',
            'implantstypes1': 'Llojet e Implantit Dental',
            'implantstypes2': 'Kirurgjia e Implantit Dental',
            'heartgirl': 'Shërbimet Dentare Andent',
            'bridgeswork1': 'Kurorat Dentare',
            'bridgeswork2': 'Urat Dentare',
            'crownstype1': 'Kurorat e Zirconit',
            'crownstype2': 'Kurorat e Porcelanit',
            'crownstype3': 'Kurorat  e Qeramikës',
            'tourismbenefit1': 'Klinika Dentare Andent Shqipëri',
            'tourismbenefit2': 'Turizmi Dentar',
            'tourismbenefit3': 'Avantazhet e Turizmit Dental',
            'wecltoandent': 'Udhëtimi për turizëm dentar',
            'easyaccessible': 'turizëm dentar në Shqipëri',
            'securityimg1': 'Protokolli i Sigurisë',
            'securityimg2': 'Teknologjia e Përparuar',
            'securityimg3': 'ADN',
            'securityimg4': 'Teknologjia e Laserit',
            'securityimg5': 'Garancia për Shërbimet Dentare',
            'securityimg6': 'Skanimet Dentare CT 3D',
            'orthtype1': 'aparatet tradicionale të dhëmbëve',
            'orthtype2': 'Invisalign',
            'alignersbenefit1': 'Ortodoncia',
            'stepbysteporth1': 'Konsulta dentare',
            'stepbysteporth2': 'Plan trajtimi i përshtatur sipas nevojave',
            'stepbysteporth3': 'Invisalign  të Përshtatur',
            'stepbysteporth4': 'pëedorimi i Invisalign ',
            'stepbysteporth5': 'Transformimi i buzëqeshjes me Andent',
            'denturesworkimg': 'Proteza në Shqipëri',
            'denturestypeimg1': 'Proteza e Plotë',
            'denturestypeimg2': 'Proteza e Pjesëshme',
            'denturestypeimg3': 'Proteza e Mbështetur nga Implantet',
            'denturesstep1': 'Opsionet e Protezave',
            'denturesstep2': 'Matjet e dhembëve',
            'denturesstep3': 'Fabrikimi i Protezave',
            'denturesstep4': 'Garancia për Komoditetin Optimal',
            'veneersstep1img': 'Faseta dentare në Shqipëri',
            'veneersstep2img': 'Faseta dentare Shqipëri',
            'veneersstep3img': 'Fasetat e Krijueshme në Tiranë',
            'veneersstep4img': 'zbardhimi i dhëmbëve',
            'cosmdent1': 'Kujdesi Estetik Dentar në Tiranë',
            'cosmdent2': 'Lidhja e Kompozitit',
            'cosmdent3': 'Riformimi i buzëqeshjes',
            'veneersbottom': 'Procedurat e Kujdesit Estetik Dentar',
            'beforeafter1': 'Dëshmitë e pacienteve Andent',
            'beforeafter2': 'Pacientë të Kënaqur Andent',
            'beforeafter3': 'Dëshmitë e pacienteve Andent Tirana',
            'beforeafter4': 'Dentistët me përvojë në Shqipëri',
            'beforeafter5': 'Proteza e Përpunuar Kujdesshëm',
            'beforeafter6': 'zbardhimi i dhëmbëve në Tiranë',
            'beforeafter7': 'buzëqeshja e ëndërave',
            'beforeafter8': 'Kujdesi Dentar i Përshtatur Personalisht',
            'beforeafter9': 'Fotografi të procedurave ',
            'beforeafter10': 'Klientët Andent',
            'beforeafter11': 'Klinika Dentare Tiranë',
            'beforeafter12': 'Kujdesi Dentar në Shqipëri',
            'beforeafter13': 'Dentistë në Tiranë',
            'beforeafter14': 'Shërbimet Dentare Tiranë',
            'beforeafter15': 'Specialistët Dentarë Tiranë',
            'beforeafter16': 'Shërbimet Dentare të Përballueshme në Shqipëri',
            'beforeafter17': 'Kontrolle Dentare Rutinë Tiranë',
            'beforeafter18': 'Procedurat Dentare në Shqipëri',
            'beforeafter19': 'Riformimi i buzëqeshjes Tiranë',
            'beforeafter20': 'Stomatologji në Shqipëri',
            "thankyou":"Faleminderit! Do të jemi në kontakt sa më shpejt të jetë e mundur",
            "consent":"By clicking “Accept All Cookies”, you agree to the storing of cookies on your device to enhance site navigation, analyze site usage, and assist in our marketing efforts. For more detailed information about the cookies we use, see our",
            "whatsAppNum":"+355693755065",
            "whatsAppMsg":"Hello there! 🤝\nHow can we help?",
            "emailLink":"info@andent.al",
            "simNum":"+355(0) 69 375 5065",
            "cookiePolicy1":"We and selected third parties use cookies or similar technologies for technical purposes and, with your consent, also for experience, measurement and marketing purposes (with personalized ads). Refusal of consent may make the related functions unavailable.",
            "cookiePolicy2":"In relation to advertising, we and selected third parties may use precise geolocation data and identification through device scanning to store and/or access information on a device and process personal data such as your use, for the following advertising purposes: personalized ads and content, ad and content evaluation, audience observations and product development.",
            "cookiePolicy3":"You can freely give, refuse or withdraw your consent at any time by accessing the preferences panel.",
            "cookiePolicy":"Cookie Policy",
            "cookiePath":"cookie-policy",
            "privacyPol":"Privacy Policy",
            "privacyPolLink":"privacy-policy-sq",
            "privacyp1":"This privacy notice outlines how Dental Clinic Andent (Andent) collects, uses, and safeguards the personal information of users who visit our website, utilize our services, or request appointments or estimates. We are committed to ensuring the protection of your privacy in compliance with current regulations.",
            "privacyp2":"Dental Clinic Andent, located at Tirana, Rruga Bardhok Biba, Pall. Golden Trema, Shk. B, Kati 4, with VAT M22314039G and contact email info@andent.al, is responsible for processing your personal data. We use and protect all information that users provide when using the www.andent.al website and our other online and offline services, especially concerning personal data processing while offering our services.",
            "privacyp3":"Users have the right to access, rectify, delete, or limit the use of their personal information. They can also withdraw consent to data processing at any time. To exercise these rights, please contact us through the provided contact details.",
            "privacyp4":"Personal data refers to any information relating to an identifiable person. Specifically, personal data includes any information that identifies a user (e.g., name, email address, postal address, etc.). Sensitive personal data (e.g., dental health conditions) require special protection. Processing personal data involves any operation or set of operations, whether automated or not, concerning personal data. This includes collecting, recording, organizing, storing, modifying, deleting, viewing, using, disclosing through transmission, and any other manner of data handling.",
            "privacyp5":"How We Collect Personal Data",
            "privacyp6":"Andent collects your personal data in the following instances:",
            "privacyp61":"If you directly contact us through any of our websites to request information or an estimate for our services or through our contact form.",
            "privacyp62":"If you purchase a service directly from us.",
            "privacyp63":"If you respond to our direct marketing campaigns, e.g., by filling out a contact form on our webpage.",
            "privacyp64":"If our partners provide us with your data in a permissible manner.",
            "privacyp65":"If you are under 16 years old, please do not provide any data without the consent of a parent or guardian.",
            "privacyp7":"This data includes, but is not limited to, name, email address, phone number, details about your dental needs, and files related to radiographic exams describing your dental requirements and health conditions.",
            "privacyp8":"We use cookies and similar technologies to gather information about how our website is used. This data helps us enhance your online experience and tailor content according to your preferences.",
            "privacyp9":"How We Use Your Data",
            "privacyp10":"The use of personal data in compliance with personal data protection rules must be justified by legal grounds. The justified bases for data processing include legitimate interest, contractual obligation, and legal duty. Andent aims to promote and provide information about its services, ensuring the highest standards in service sales and offerings. We ensure a balance between Andent's legitimate interest in processing data and users' fundamental rights. We collect and process contact data, service details, warranties, and your medical history as a patient.",
            "privacyp11":"Personal data may be shared with third parties, provided there is a justified basis for such sharing, usually based on consent. The personal information gathered is used to process estimates, formulate and communicate work plans, schedule appointments, and inform you about our offers and services. Andent collaborates with third-party partners and discloses that personal data may be shared with and processed by these third parties, some of whom may be based abroad (Italy and Switzerland), to meet user requests.",
            "privacyp12":"Andent may use direct marketing based on your personal data to inform you about news, promotions in our clinic, special discounts, new service introductions, and our presentations at fairs, conferences, and other events. Direct marketing consent can be withdrawn at any time. You can lodge a complaint about data processing by sending an email to Andent. Dental Clinic Andent operates under Albanian laws.",
            "privacyp13":"How We Protect Your Data",
            "privacy14":"We employ various security measures, including encryption and authentication, to safeguard and maintain the security, integrity, and accessibility of your data. We take appropriate security measures to protect your personal information from unauthorized access, alterations, or disclosures. However, no data transmission or storage system can be guaranteed to be entirely secure.",
            "privacy15":"For example, we use the following measures:",
            "privacy16":"Strictly limited personal access to your data based on the 'need-to-know' principle.",
            "privacy17":"Secure transmission of collected data.",
            "privacy18":"Installation of firewalls in IT systems to prevent unauthorized access.",
            "privacy19":"Continuous monitoring of IT access to detect any misuse of personal data.",
            "privacy20":"All your data is stored on our secure servers and those of our partners and accessed and used in line with our security standards. We are always committed to ensuring the privacy of your data. Personal data is processed securely, preventing unauthorized or unlawful processing, data loss, or destruction.",
            "privacy21":"By filling out the contact form, you grant us permission to process your personal data as specified in the registration or contact form and any submitted diagnostic exams for the stated purpose. Beyond this, Andent will not undertake any processing other than those declared and will not transfer data to third parties except in fulfilling agreements.",
            "privacy22":"We commit not to misuse personal data provided through the registration form or any other form or obtained via cookies, nor share them with third parties without your permission, except when explicitly required by law or to meet obligations. All user data is kept confidential and accessible only to employees and partners who need it for work",
            "privacy23":"",
            "privacy24":"",
            "privacy25":"",
            "privacy26":"",
            "privacy27":"",
            "privacy28":"",
            "policyNotice":"Duke plotësuar këtë web-form ju keni pranuar termat dhe rregullat e ",
            "policyNotice1":" Gjithashtu keni pranuar te merrni njoftime nga departamenti i marketingut mbi të rejat e ofruara nga Andent. Mund të ç’abonoheni në çdo kohë.",
            "policyNotice2":"",
            "policyNotice3":"",
            "policyLink":'ruajtjes së privacisë.',
            "magicFN": "Emri",
            "magicFN1": "Vendos Emrin",
            "magicLN": "Mbiemri",
            "magicLN1": "Vendos Mbiemrin",
            "magicE": "Email",
            "magicE1": "Vendos Email-in",
            "magicN": "Numri",
            "magicN1": "Vendos Numrin",
            "magicM": "Mesazhi",
            "magicM1": "Vendos Mesazhin",
            "magicUpload": "Ngarko Panoramën",
            "magicSubmit": "Dërgo",
            "sizeWarn" : "Dimensionet e skedarit janë shumë të kufizuara: 10 MB!"
          }
        }
    }
  });


export default i18n;