import React from 'react'
import iconone from '../../assets/andent-data/address.png'
import icontwo from '../../assets/andent-data/contactus.png'
import Iframe from 'react-iframe'
import { useState } from 'react'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { ConsentContext } from '../../routes'
import { useContext } from 'react'
import { Link } from 'react-router-dom'
import ReCAPTCHA from "react-google-recaptcha";
import ReactGA from "react-ga4";
import { PhoneInput } from "react-simple-phone-input";
import "react-simple-phone-input/dist/style.css";

function Make() {

  const [Fname,setFname]=useState("")
  const [Lname,setLname]=useState("")
  const [email,setEmail]=useState("")
  const [num,setNum]=useState("")
  const [comment,setComment]=useState("")
  //const [imgfile,setFile]=useState(null)
  const {t,i18n}=useTranslation();
  const [transform, setTransform] = useState("translateY(-5px)");
  const [responseMessage, setResponseMessage] = useState('');
  const [base64,setBase64] = useState(null);
  const [book,setBook] = useState(false);
  const [sent,setSent] = useState(false);
  const value = useContext(ConsentContext);
  const [seeMore,setSeeMore] = useState(true)
  const [valid,setValid] = useState(false)
  const [bookNowBtn,setBookNowBtn] = useState(false)
  
  useEffect(() => {
    //"translate(-5px,-5px)"
      const lng= i18n.language;

      if (lng==='it') {
        setTransform("translate(-5px,-5px)");
      }
      
      else if (lng==='sq') {
        setTransform("translate(-13px,-5px)");
      }

       else {
        setTransform("translateY(-5px)");
      }
  },[i18n,setTransform]);

  const chFN = (e) => {
    setFname(e.target.value)
  }

  const chLN = (e) => {
    setLname(e.target.value)
  }
  
  const chEmail = (e) => {
    setEmail(e.target.value)
  }

  const chCmnt = (e) => {
    setComment(e.target.value)
  }

  const chPano = (e) => {
    const allowedExtensions = ['pdf', 'jpg', 'jpeg', 'png','PNG','JPG','JPEG','PDF'];
    const fileExtension = e.target.files[0].name.split('.').pop().toLowerCase();

    if (e.target.files[0].size < 10000000 && allowedExtensions.includes(fileExtension)){
      const reader = new FileReader();
      reader.addEventListener('load',()=>{
        console.log("filename:",e.target.files[0].name);
        setBase64([{
          filename: e.target.files[0].name,
          content: reader.result.substr(reader.result.indexOf(',') + 1),
          encoding: 'base64'
        }])
      });
      reader.readAsDataURL(e.target.files[0]);
    }
    else if (e.target.files[0].size > 10000000){
      alert(t("sizeWarn"))
    }
    else if (!allowedExtensions.includes(fileExtension)){
      alert("Supported file formats : png,jpg & pdf")
    }
  }

  
  const api = async () =>{
    try { 
      
      const requestBody = {
        firstName: Fname,
        lastName: Lname,
        email,
        number: num,
        notes: comment,
        base64Data: base64,
        lng: i18n.language,
        magic: undefined,
        // test:true
      };

      const response = await fetch('https://office-backend.andent.al/clients/form', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),
      });

      const data = await response.json();
      console.log(data);
      setResponseMessage(data);
      setSent(true);

      setTimeout(() => {
        window.location.reload();
      }, 4000);

    } catch (error) {
      console.error('Error sending data:', error);
      console.log(responseMessage)
    }
  }

  const bookNow = async (e) =>{
    if(value===1){
      /* window.analytics.identify(email, {
         firstName: Fname,
         lastName: Lname,
         phoneNum : num,
         comments: comment,
         email : email,
         panoramex : base64
       });*/
       ReactGA.event({
         category: "Form",
         action: `Form Filled`,
         pagetitle: window.location.pathname
       });
       window.fbq('trackCustom', 'Form Filled');
     }

    setBook(true);
    setValid(!valid)
    setBookNowBtn(bookNowBtn)

    e.preventDefault();

    api();

  }

  const inputProps = {
    required: true,
    minlength: 5
  };
  return (
    <>
            <section id="contact" className="andent-padding">
            <div className="container">
              <div className="row justify-content-around align-items-center">
              <div className="col-lg-4 col-8 order-2 d-flex justify-content-center wow fadeInUp animated upper-padding" data-animation="fadeInDown animated" data-delay=".2s" style={{paddingLeft:"50px",position:"relative",bottom:"20px"}}>
                  <div className='contact-info'>
                  <div className='row justify-content-center'>
                      <div className='col-lg-4 col-8 d-flex justify-content-md-center'>
                      <div className="f-cta-icon">
                        <div>
                        <img src={iconone} alt='none' className='mob-bottom-icon'/>
                        </div>
                      </div>
                      </div>
                      <div className='col-lg-8 col-12 mob-bottom-text clinic-address'>
                        <div className='apt-text text-start'>
                            <h5 className='theme-dark text-center text-sm-center text-lg-start text-md-center' style={{color:"#4E4E50"}}>{t("address")}</h5>
                            <p className='size-16 theme-dark text-center text-lg-start text-md-center address-text' style={{lineHeight:"20px",fontWeight:"400"}}>
                            Rruga Bardhok Biba, Pall. Golden<br/> Trema,
                            Shk. B, Kati 4, Tiranë
                            </p>
                        </div>
                      </div>
                    </div>
                    <br/>
                    <div className='row justify-content-center'>
                      <div className='col-lg-4 col-8 d-flex justify-content-md-center'>
                      <div className="f-cta-icon">
                        <div>
                        <img src={icontwo} alt='none' className='mob-bottom-icon'/>
                        </div>
                      </div>
                      </div>
                      <div className='col-lg-8 col-12 mob-bottom-text contact-us'>
                        <div style={{position:"relative",bottom:"20px"}} className='apt-text'>
                          <h5 className='theme-dark text-sm-center text-lg-start text-md-center' style={{color:"#4E4E50"}}>{t("contactus")}</h5>
                          <p className='size-16 theme-dark text-sm-center text-lg-start text-md-center address-text' style={{lineHeight:"20px",fontWeight:"400"}}>
                          {t("phnum")}<br/> {t("simNum")}<br/><br/>
                          {t("email")}:<br/>{t("emailLink")}
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className='row upper-padding' style={{position:"relative"}}>
                    <div className="col-lg-12 text-center">
                    <Iframe id="contactsMap" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2996.004518759625!2d19.814192096789547!3d41.3305151!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x135031303fe86d0b%3A0x7d2830bb4995e303!2sDentisti%20in%20Albania%20-%20Andent%20Clinic!5e0!3m2!1sen!2s!4v1688988361448!5m2!1sen!2s"
                           width={322}
                           height={303} 
                           style={{ border : "0" }}
                          loading="lazy"
                          className='contacts-map map-pos'
                          >
                    </Iframe>
                    </div>
                    </div>
                    

                  </div>
                </div>

                <div className='row justify-content-center text-center'>
                <div className='col-lg-12 col-12'>
                <div className="section-title center-align">
                      <h2 className='size-60 theme-dark'>{t("bookaptmnt")}</h2>
                    </div>
                    </div>
                </div>
                
                <div className="col-lg-6 order-1 contact-us-pad">
                  <div className="contact">
                  <form  method="post" className="contact-form mt-30" onSubmit={bookNow}>
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="contact-field contact-us-pad">
                            <input type="text" disabled={book} id="firstn" name="firstn" placeholder={t("fname")} style={{borderRadius:"15px"}} className='form-small' required onChange={chFN}/>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="contact-field contact-us-pad">
                            <input type="text" disabled={book} id="lastn" name="lastn" placeholder={t("lname")} style={{borderRadius:"15px"}} className='form-small' required onChange={chLN}/>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="contact-field contact-us-pad">
                            <input type="email" disabled={book} id="Email" name="Email" placeholder={t("email")} style={{borderRadius:"15px"}} className='form-small' required onChange={chEmail}/>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="contact-field contact-us-pad">
                            <PhoneInput country="IT" id="phone-number" disabled={book} name="Phone Number" placeholder={t("number")} inputProps={inputProps} onChange={(data) => setNum(data.value)}/>
                            <PhoneInput country="IT" disabled={book} id="phone-number" name="Phone Number" inputProps={inputProps} onChange={(data) => setNum(data.value)}/>
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="contact-field contact-us-pad">
                            <textarea name="message" id="message" cols={30} rows={10} placeholder={t("comments")} defaultValue={""} className='input-box form-big' style={{borderRadius:"15px"}} onChange={chCmnt}/>
                          </div>
                          <div className='row justify-content-around'>
                          <div className="col-lg-7 col-12 col-sm-12 col-md-12 d-flex justify-content-start justify-content-md-center  justify-content-lg-start">
                          <div className="form-input-pad">
                           <div className='form-big2' style={{borderStyle:"solid"}}>
                              <h4 className="input-text-pad text-start input-text-sides" style={{fontSize:"12px",lineHeight:"18px",fontWeight:"700"}}>
                              {t("file")}<i style={{fontWeight:"400"}}>{base64?<p>Uploaded!</p>:<p></p>}</i>
                              </h4>
                           </div>
                          </div>
                          </div>
                          <div className="col-lg-5 col-12 col-sm-12 col-md-12 d-flex justify-content-lg-end justify-content-center text-center align-items-center form-input-pad-mob upload-top-pad">
                            <input type="file" id="file-input" disabled={book} name="file-input" onChange={chPano}/>
                            <label id="file-input-label" htmlFor="file-input" className='text-center'>{t("upload")}</label>
                          </div>
                          </div>
                          {i18n.language==="it"? (<>
                            <div className='row justify-content-center align-items-center text-center text-lg-start' style={{paddingTop:"20px"}}>
                          <div className="col-1 d-flex justify-content-end">
                            <input type="checkbox" required />
                          </div>
                          <div className="col-lg-11 text-center text-md-start">
                          <div className='notice mob-top-pad'>
                            {seeMore? (<><p>
                            {t("policyNotice")}
                            <Link to="#" onClick={()=>{setSeeMore(false)}}>
                            Vedi altro
                            </Link>
                            </p></>) : (<>
                              <p>
                                {t("policyNotice2")}
                              </p>
                            </>)}                
                          </div>
                          </div>
                          </div>
                          <div className='row justify-content-center align-items-center text-center text-lg-start' style={{paddingTop:"20px"}}>
                          <div className="col-1 d-flex justify-content-end">
                            <input type="checkbox" required />
                          </div>
                          <div className="col-lg-11 text-center text-md-start">
                          <div className='notice mob-top-pad'>
                            <p style={{paddingTop:"20px"}}>
                              {t("policyNotice3")}<span>
                                <Link to={`/${t('privacyPolLink')}`}>
                                  {t("policyLink")}
                                </Link>
                              </span>
                            </p>          
                          </div>
                          </div>
                          </div>
                          </>) : (<>
                            <div className='row justify-content-center align-items-center text-center text-lg-start' style={{paddingTop:"20px",paddingLeft:"12px"}}>
                          <div className="col-lg-12 text-center text-md-start" >
                          <div className='notice mob-top-pad'>
                            <p>
                            {t("policyNotice")}<span>
                            <Link to={`/${t('privacyPolLink')}`}>{t("policyLink")}</Link>
                            </span>{t("policyNotice1")}
                            </p>
                          </div>
                          </div>
                          </div>
                          </>)}
                          <div className='captcha d-flex justify-content-center col-lg-12 col-12'>
                            <ReCAPTCHA
                              sitekey="6LdXID8oAAAAAHscjkYCPO8HkAuh6QBkm8dUwpVx"
                              onChange={()=>{
                                setValid(true)
                              }}
                            />
                          </div>
                          <div className='d-flex justify-content-center pad-20'>
                            <button className="btn" data-animation="fadeInRight" disabled={!bookNowBtn && !valid} data-delay=".8s" style={{width:"182px",height:"50px"}} type='submit'>
                              <p style={{transform:transform}}>
                              {t("booknow")}
                              </p>
                            </button>
                          </div>
                          <br/>
                          <br/>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                {sent? (<div className='pop-up d-flex justify-content-center text-center align-items-center' >
                    <h3 className='pop-up-text'>
                      {t("thankyou")}
                    </h3>
                </div>) : ("")}
              </div>
            </div>
            </section>
    </>
  )
}

export default Make