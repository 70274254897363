import React from 'react'
//import { useLocation } from 'react-router'
import ReactHtmlParser from 'react-html-parser';
import { useParams } from 'react-router';
import axios from 'axios';
import { useState,useEffect } from 'react';
import whatsappheader from '../../assets/andent-data/whatsappimg.jpeg'
import { useTranslation } from 'react-i18next'
import { FloatingWhatsApp } from 'react-floating-whatsapp'
import { ConsentContext } from '../../routes'
import { useContext } from 'react'
import ReactGA from "react-ga4";
import { Helmet,HelmetProvider } from 'react-helmet-async'
import { useLocation } from 'react-router-dom'
import {getCanonicalUrl} from '../../utils/canonicalUrl'

function Main() {

//  const location = useLocation();
// const propsData=location.state;
const {t} = useTranslation();
const value = useContext(ConsentContext)
const location = useLocation();
const canonicalUrl = getCanonicalUrl(location)
const lang = 'en-US';

  const getMessage = (e) => {
    if(value===1){
    /* window.analytics.track("WhatsApp", {
        message : e.target[0].value
      });*/
      ReactGA.event({
        category: "WhatsApp",
        action: "WhatsApp Msg Sent",
        message : e.target[0].value
      });
      window.fbq('trackCustom', 'WhatsApp Msg Sent');
    }
  }

  const waClick = () => {
    if(value===1){
      ReactGA.event({
        category: "WhatsApp",
        action: "WhatsApp Opened",
      });
      window.fbq('trackCustom', 'WhatsApp Opened');
  /*    window.analytics.track("WhatsApp Opened", {
        openedOnPage: window.location.pathname
      });*/
    }
  }


  const {id} = useParams();

  const url=`https://andent-backend.prodbuilds.com/api/blogs/?filters[slug][$eq]=${id}`

  const [blog,setBlog]=useState([]);
  const [connected,setConnected] =useState(false);
  const [isLoading,setIsLoading]=useState(false);

  useEffect(() => {

    setIsLoading(true);

    const fetchData = async () => {
      try {
        const response = await axios.get(url);
        setBlog(response.data.data[0].attributes);
        setConnected(true);
        setIsLoading(false);
      } catch (error) {
        console.log("Error fetching data:", error);
        setIsLoading(false);
      }
    };
  
    fetchData();
  }, [url]);

  return (
    


    <>
    <HelmetProvider>
      <Helmet htmlAttributes={{lang}}>
        <link rel="canonical" href={canonicalUrl} />
        <link rel="alternate" href={canonicalUrl} hrefLang="x-default" />   
      </Helmet>
    

        { isLoading ? (
          <div className='container' style={{padding:"100px"}}>
          <div className='row justify-content-center text-center'>
            <div className='col-lg-12'>
              <h1 className='theme-dark size-60 mob-heading upper-padding'>Loading...</h1>
            </div>
          </div>
          <div style={{height:"auto",width:"auto",zIndex:"1000",position:"absolute",padding:"50px"}}>
          <FloatingWhatsApp avatar={whatsappheader} accountName="Andent" phoneNumber={t("whatsAppNum")} chatMessage={t("whatsAppMsg")} darkMode="true" onClick={waClick} chatboxHeight={450} allowClickAway="true" allowEsc="true" onSubmit={getMessage} buttonStyle={{height:"70px",width:"70px"}}/>
          </div>
        </div>
        ) : (
          <div>
          { connected ? (
            <section className="inner-blog b-details-p pt-120 pb-120 blog-top-pad">
              <div className="container" style={{width: window.innerWidth<786? "100%" : "50%"}}> 
                <div className="row justify-content-center text-center">
                <div className='col-sm-12 col-md-12 col-lg-12'>
                <h1 className='blog-top'>
                { blog.title }
                </h1>
                <div className='api-html'>
                  { ReactHtmlParser(blog.content) }
                </div>
                </div>
                </div>
              </div>
              <div style={{height:"auto",width:"auto",zIndex:"1000",position:"absolute",padding:"50px"}}>
              <FloatingWhatsApp avatar={whatsappheader} accountName="Andent" phoneNumber={t("whatsAppNum")} chatMessage={t("whatsAppMsg")} darkMode="true" onClick={waClick} chatboxHeight={450} allowClickAway="true" allowEsc="true" onSubmit={getMessage} buttonStyle={{height:"70px",width:"70px"}}/>
              </div>
            </section>
          ) : (
            <section className="inner-blog b-details-p pt-120 pb-120 upper-padding">
              <div className="container"> 
                <div className="row">
                <div className='col-sm-12 col-md-12 col-lg-12'>
                <h1 className='theme-dark size-60 mob-heading'>
                  Sorry, we could not find the post your are looking for.
                </h1>
                </div>
                </div>
              </div>
              <div style={{height:"auto",width:"auto",zIndex:"1000",position:"absolute",padding:"50px"}}>
              <FloatingWhatsApp avatar={whatsappheader} accountName="Andent" phoneNumber={t("whatsAppNum")} chatMessage={t("whatsAppMsg")} darkMode="true" onClick={waClick} chatboxHeight={450} allowClickAway="true" allowEsc="true" onSubmit={getMessage} buttonStyle={{height:"70px",width:"70px"}}/>
              </div>
            </section>
            
          )}
        
        </div>
        )}
        
    </HelmetProvider>
        
    </>
  )
}

export default Main