import React from 'react'
import Make from '../MenContact-Us/Make'
import Map from '../MenContact-Us/Map'
import { FloatingWhatsApp } from 'react-floating-whatsapp'
import 'react-floating-whatsapp-button/dist/index.css'
import { useEffect, useState } from 'react'
import { ConsentContext } from '../../routes'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import ReactGA from "react-ga4";
import whatsappheader from '../../assets/andent-data/whatsappimg.jpeg'
import { useLocation } from 'react-router-dom'
import {getCanonicalUrl} from '../../utils/canonicalUrl'
import { HelmetProvider } from 'react-helmet-async'
import { Helmet } from 'react-helmet'

function Main() {

  const value = useContext(ConsentContext);
  const {t, i18n} = useTranslation();
  const location = useLocation();
  const canonicalUrl = getCanonicalUrl(location)
  const [lang, setLang] = useState(i18n.language);

  useEffect(() => {
    // Update the lang state whenever the language changes
    const handleLanguageChange = () => {
      setLang(i18n.language);
    };

    i18n.on('languageChanged', handleLanguageChange);

    return () => {
      i18n.off('languageChanged', handleLanguageChange);
    };
  }, [i18n]);

  useEffect(() => {
    if(value===1){
      //window.analytics.page('ContactUs');
      ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: "Contact Us Page" });
      window.fbq('trackCustom', 'Contact Us Page');
    }
  });

  const getMessage = (e) => {
    if(value===1){
     /* window.analytics.track("WhatsApp", {
        message : e.target[0].value
      });*/
      ReactGA.event({
        category: "WhatsApp",
        action: "WhatsApp Msg Sent",
        message : e.target[0].value
      });
    }
  }

  return (
    <>
    <HelmetProvider>
      <Helmet htmlAttributes={{lang}}>
      <link rel="canonical" href={canonicalUrl} />
            <link rel="alternate" href='https://andent.al/contact-us' hrefLang="x-default" />
            <link rel="alternate" href='https://andent.al/it/contattaci' hrefLang="it" />
            <link rel="alternate" href='https://andent.al/sq/kontakt' hrefLang="sq" />
      </Helmet>
        <Make/>
        <Map/>
        <div style={{height:"auto",width:"auto",zIndex:"1000",position:"absolute",padding:"50px"}}>
      <FloatingWhatsApp avatar={whatsappheader} accountName="Andent" phoneNumber={t("whatsAppNum")} chatMessage={t("whatsAppMsg")}darkMode="true"  chatboxHeight={450} allowClickAway="true" allowEsc="true" onSubmit={getMessage} buttonStyle={{height:"70px",width:"70px"}}/>
      </div>
      </HelmetProvider>
    </>
  )
}

export default Main