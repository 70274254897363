import { useTranslation } from "react-i18next";
import { useEffect } from 'react'

function Review() {
  const {t}=useTranslation();

  useEffect(() => {
    const script1 = document.createElement('script');

    script1.src = "https://cdn.trustindex.io/loader.js?14a092a12fb16057ce863991d95";
    script1.async = true;

    document.head.appendChild(script1);
    
    const script2 = document.createElement('script');

    script2.src = "https://cdn.trustindex.io/loader.js?492565312cf460514b764dc37a2";
    script2.async = true;

    document.head.appendChild(script2);

    return () => {
      document.head.removeChild(script1);
      document.head.removeChild(script2);
    }
  }, []);

  return (
    <div className='container andent-padding'>
    <div className="row justify-content-center">
      <div className="col-lg-12 text-center">
        <h2 className="theme-dark size-60 mob-heading">
          {t("reviews")}
        </h2>
      </div>
    </div>
    <div className="row justify-content-center">
    <div className='col-lg-12 text-center'>
    <script defer async src='https://cdn.trustindex.io/loader.js?14a092a12fb16057ce863991d95'></script>
    </div>
    </div>
    <div className="row justify-content-center">
    <div className='col-lg-12 text-center'>
    <script defer async src='https://cdn.trustindex.io/loader.js?492565312cf460514b764dc37a2'></script>
    </div>
    </div>
    </div>
  );
};
export default Review;