import star from '../../assets/andent-data/star.png'

function Main (props){

    const stars = Array.from({ length: 5 }, (_, index) => (
        <img index={index} key={index} src={star} alt="icons" style={{paddingRight:"10px",transform:"translateY(-5px)",height:"20px",width:"30px"}}  />
      ));

    return(
        <div className="container">
            <div className='row test-upper-padding justify-content-center fadeInUp animated' data-animation="fadeInDown animated" data-delay=".2s">
              <div className='col-lg-4 col-md-12 mob-top-pad'>
                    <div className='row justify-content-center text-center'>
                        <div className='col-lg-6'>
                            <h4 className='size-18 theme-dark text-lg-start move-right test-name-pod' style={{fontWeight:"700"}}>{props.name} &nbsp; &nbsp;</h4>
                        </div>
                        <div className='col-lg-6 text-lg-start'>
                            {stars}
                        </div>
                    </div>
                    <div style={{paddingTop:"15px"}} className='text-center d-flex justify-content-center'>
                    <video
                    controls
                    style={{width:"320px"}}
                    height="400px"
                    playsInline 
                    preload='auto'
                    poster={props.poster}
                    >
                    <source
                        src={props.link}
                        type="video/mp4" // Adjust the type according to your video format
                    />
                    Your browser does not support the video tag.
                    </video>
                    </div>
                </div>
                {!!props.name2 && !!props.poster2 && !!props.link2 &&
                <div className='col-lg-4 col-md-12 mob-top-pad'>
                    <div className='row justify-content-center text-center'>
                        <div className='col-lg-6'>
                            <h4 className='size-18 theme-dark text-lg-start move-right test-name-pod' style={{fontWeight:"700"}}>{props.name2} &nbsp; &nbsp;</h4>
                        </div>
                        <div className='col-lg-6 text-lg-start'>
                            {stars}
                        </div>
                    </div>
                    <div style={{paddingTop:"15px"}} className='text-center d-flex justify-content-center'>
                    <video
                    controls
                    height="400px"
                    playsInline 
                    preload='auto'
                    style={{width:"320px"}}
                    poster={props.poster2}
                    >
                    <source
                        src={props.link2}
                        type="video/mp4" // Adjust the type according to your video format
                    />
                    Your browser does not support the video tag.
                    </video>
                    </div>
                </div>
                }
            </div>
        </div>       
    );
}

export default Main;