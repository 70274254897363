import React, { useContext } from 'react'
import Slider from '../Slider/Main'
import Ourservish from '../Menhome/Ourservices'
import OurDr from '../Menhome/Ourdr'
import Tourism from '../Menhome/tourism'
import Testimonial from '../Menhome/Testimonial'
import { FloatingWhatsApp } from 'react-floating-whatsapp'
import Appointment from '../MenContact-Us/Make'
import Review from '../reviews/trustpilot'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Helmet,HelmetProvider } from 'react-helmet-async'
import { ConsentContext } from '../../routes'
import ReactGA from "react-ga4";
import whatsappheader from '../../assets/andent-data/whatsappimg.jpeg'
import { useLocation } from 'react-router-dom'
import {getCanonicalUrl} from '../../utils/canonicalUrl'

function Main() {
  const {t, i18n}=useTranslation();
  const value = useContext(ConsentContext)
  const location = useLocation();
  const canonicalUrl = getCanonicalUrl(location)
  const [lang, setLang] = useState(i18n.language);

  useEffect(() => {
    // Update the lang state whenever the language changes
    const handleLanguageChange = () => {
      setLang(i18n.language);
    };

    i18n.on('languageChanged', handleLanguageChange);

    return () => {
      i18n.off('languageChanged', handleLanguageChange);
    };
  }, [i18n]);
  
  useEffect(() => {
   // let referrer = document.referrer;
    if(value===1){
      ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: "Home Page" });
      window.fbq('trackCustom', 'Home Page');
      /*window.analytics.page('Home',{
        referrer: referrer // Pass the referrer URL here
    });*/
    }

  },[value]);

  const getMessage = (e) => {
    if(value===1){
     /* window.analytics.track("WhatsApp", {
        message : e.target[0].value
      });*/
      ReactGA.event({
        category: "WhatsApp",
        action: "WhatsApp Msg Sent",
        message : e.target[0].value
      });
      window.fbq('trackCustom', 'WhatsApp Msg Sent');
    }
  }

  const waClick = () => {
    if(value===1){
      ReactGA.event({
        category: "WhatsApp",
        action: "WhatsApp Opened",
      });
      window.fbq('trackCustom', 'WhatsApp Opened');
  /*    window.analytics.track("WhatsApp Opened", {
        openedOnPage: window.location.pathname
      });*/
    }
  }


  return (
    <>
    <HelmetProvider>
      <Helmet htmlAttributes={{lang}}>
        <meta name="description" content={t('homedesc')} />
        <meta name='title' content={t('hometitle')}/>
        <link rel="canonical" href={canonicalUrl} />
        <link rel="alternate" href='https://andent.al/' hrefLang="x-default" />
        <link rel="alternate" href='https://andent.al/it/' hrefLang="it" />
        <link rel="alternate" href='https://andent.al/sq/' hrefLang="sq" />
      </Helmet>
      <Slider heading={t('homehero')} description={t("homeherop")} span={true} quality={t("homeheroh2")} and={t("homehero2")} comfort={t("homeheroh22")} />
      <Review/>
      <Ourservish/>
      <OurDr/>
      <div className='move-up2'>
      <Tourism/>
      </div>
      <Testimonial/>
      <Appointment/>
      <div style={{height:"auto",width:"auto",zIndex:"1000",position:"absolute",padding:"50px"}}>
      <FloatingWhatsApp avatar={whatsappheader} accountName="Andent" phoneNumber={t("whatsAppNum")} chatMessage={t("whatsAppMsg")} darkMode="true"  chatboxHeight={450} allowClickAway="true" allowEsc="true" onSubmit={getMessage} buttonStyle={{height:"70px",width:"70px"}} onClick={waClick}/>
      </div>
    </HelmetProvider>
    </>
     
  )
}

export default Main