import Slider from '../Slider/Main';
import Review from '../reviews/trustpilot';
import Content from './Content'
import ContactUs from '../implants/Contactsm';
import Appt from '../MenContact-Us/Make';
import { FloatingWhatsApp } from 'react-floating-whatsapp';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet,HelmetProvider } from 'react-helmet-async';
import { useContext } from 'react';
import { ConsentContext } from '../../routes';
import ReactGA from "react-ga4";
import whatsappheader from '../../assets/andent-data/whatsappimg.jpeg'
import { useLocation } from 'react-router-dom'
import {getCanonicalUrl} from '../../utils/canonicalUrl'

function Main (){
    const {t, i18n}=useTranslation();
    const value = useContext(ConsentContext);
    const location = useLocation();
    const canonicalUrl = getCanonicalUrl(location)
    const [lang, setLang] = useState(i18n.language);
    
    useEffect(() => {
      // Update the lang state whenever the language changes
      const handleLanguageChange = () => {
        setLang(i18n.language);
      };
  
      i18n.on('languageChanged', handleLanguageChange);
  
      return () => {
        i18n.off('languageChanged', handleLanguageChange);
      };
    }, [i18n]);

    useEffect(() => {
        if(value===1){
          //window.analytics.page('GeneralDentistry');
          ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: "General Dentistry Page" });
          window.fbq('trackCustom', 'General Dentistry Page');
        }
      },[value]);
      
      const getMessage = (e) => {
        if(value===1){
         /* window.analytics.track("WhatsApp", {
            message : e.target[0].value
          });*/
          ReactGA.event({
            category: "WhatsApp",
            action: "WhatsApp Msg Sent",
            message : e.target[0].value
          });
          window.fbq('trackCustom', 'WhatsApp Msg Sent');
        }
      }
    
      const waClick = () => {
        if(value===1){
          ReactGA.event({
            category: "WhatsApp",
            action: "WhatsApp Opened",
          });
          window.fbq('trackCustom', 'WhatsApp Opened');
      /*    window.analytics.track("WhatsApp Opened", {
            openedOnPage: window.location.pathname
          });*/
        }
      }

    return(
        <div>
          <HelmetProvider>
          <Helmet htmlAttributes={{lang}}>
            <meta name="description" content={t('generaldesc')} />
            <meta name='title' content={t('generaltitle')}/>
            <link rel="canonical" href={canonicalUrl} />
            <link rel="alternate" href='https://andent.al/general-dentistry' hrefLang="x-default" />
            <link rel="alternate" href='https://andent.al/it/servizi-odontoiatria' hrefLang="it" />
            <link rel="alternate" href='https://andent.al/sq/stomatologji' hrefLang="sq" />
          </Helmet>
            <Slider heading={t("generaldentistryhero")} description={t("generaldentistryherop")}/>
            <Review/>
            <Content/>
            <ContactUs/>
            <Appt/>
            <div style={{height:"auto",width:"auto",zIndex:"1000",position:"absolute",padding:"50px"}}>
            <FloatingWhatsApp avatar={whatsappheader} accountName="Andent" phoneNumber={t("whatsAppNum")} chatMessage={t("whatsAppMsg")} darkMode="true" onClick={waClick} chatboxHeight={450} allowClickAway="true" allowEsc="true" onSubmit={getMessage} buttonStyle={{height:"70px",width:"70px"}}/>
            </div>
            </HelmetProvider>
        </div>
    );
}

export default Main;