import React from 'react';
import sitemapContent from '../sitemap.xml'
import axios from 'axios';
import { useState } from 'react';

const SitemapXML = () => {

    const [xmlData,setxmlData] = useState(null)

    axios.get(sitemapContent, {
        "Content-Type": "application/xml; charset=utf-8"
     })
     .then((response) => {
        setxmlData(response.data)
     });

  return (
    <div>
      <pre>{xmlData}</pre>
    </div>
  );
  
};

export default SitemapXML;
