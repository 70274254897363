import logo from '../../assets/data/logo.png'
import mail from '../../assets/data/mailicon.png'
import phone from '../../assets/data/phone.png'
import upload from '../../assets/data/upload.png'
import { useEffect, useState } from 'react'
import download from '../../assets/data/download.png'
import del from '../../assets/data/delete.png'
import send from '../../assets/data/invoicewhite.png'
import happy from '../../assets/data/happy.png'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import ReCAPTCHA from "react-google-recaptcha";
import { PhoneInput } from "react-simple-phone-input";
import "react-simple-phone-input/dist/style.css";
import { useLocation } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'
import {getCanonicalUrl} from '../../utils/canonicalUrl'

function BookApt(props){

    const {t,i18n}=useTranslation();
    const [pana,setPana] = useState([]);
    const [submitted,setSubmitted] = useState(false);
    const [seeMore,setSeeMore] = useState(true)
    const [Fname,setFname]=useState("")
    const [Lname,setLname]=useState("")
    const [email,setEmail]=useState("")
    const [num,setNum]=useState("")
    const [comment,setComment]=useState("")
    const [base64Array, setBase64Array] = useState([]);
    const [valid,setValid] = useState(false)
    const [id,setId] = useState("");
    const location = useLocation();
    const canonicalUrl = getCanonicalUrl(location)
//    const [base64,setBase64] = useState(null);

    useEffect(()=>{
        const split = window.location.pathname.split('/')
        const ID = split[split.length-1]
        setId(ID)
    },[])

    const chFN = (e) => {
        setFname(e.target.value)
      }
    
      const chLN = (e) => {
        setLname(e.target.value)
      }
      
      const chEmail = (e) => {
        setEmail(e.target.value)
      }
    
      const chCmnt = (e) => {
        setComment(e.target.value)
      }
    
    /*  const chPano = (e) => {
        const data = new FileReader();
        data.addEventListener('load',()=>{
          setBase64(data.result)
        })
        data.readAsDataURL(e.target.files[0]);

        handlePanoChange(base64);

      }*/

      const convertToBase64 = (file) => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onload = () => resolve(reader.result.substr(reader.result.indexOf(',') + 1));
          reader.onerror = (error) => reject(error);
          reader.readAsDataURL(file);
        });
      };
    
     

    useEffect(()=>{
        // Process the updated pana and update the base64Array state

        const processPanaFiles = async (files) => {
            const newBase64Array = [];
        
            for (const file of files) {
              const base64Data = await convertToBase64(file);
              newBase64Array.push({
                filename: file.name,
                content: base64Data,
                encoding: 'base64'
              });
            }
        
            // Update the state with the new base64Array
            setBase64Array(newBase64Array);
          };

        processPanaFiles(pana);


    },[pana])  

    const handlePanoChange = (event) => {

        const allowedExtensions = ['pdf', 'jpg', 'jpeg', 'png','PNG','JPG','JPEG','PDF'];
        const fileExtension = event.target.files[0].name.split('.').pop().toLowerCase();

        if(event.target.files[0].size < 10000000 && allowedExtensions.includes(fileExtension)){
            const newFiles = event.target.files;
            const newFilesArray = Array.from(newFiles);
            setPana((prevPana) => [...prevPana, ...newFilesArray]);
    
            const convertToBase64 = (file) => {
              return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onload = () => resolve(reader.result.substr(reader.result.indexOf(',') + 1));
                reader.onerror = (error) => reject(error);
                reader.readAsDataURL(file);
              });
            };
    
            const processPanaFiles = async () => {
            const newBase64Array = [];
    
            for (const file of newFilesArray) {
                const base64Data = await convertToBase64(file);
                newBase64Array.push({ path: base64Data });
            }
    
            // Update the state with the new base64Array
            setBase64Array((prevBase64Array) => [...prevBase64Array, ...newBase64Array]);
            };
    
            processPanaFiles();
        }
        else if (event.target.files[0].size > 10000000){
            alert(t("sizeWarn"))
        }
        else if (!allowedExtensions.includes(fileExtension)){
          alert("Supported file formats : png,jpg & pdf")
        }
    };




    const delPana = (index) => {
        document.getElementById('my-file').value = "";
        setPana((prevPana) => {
          const updatedPana = [...prevPana];
          updatedPana.splice(index, 1); // Remove the file at the specified index
          return updatedPana;
        });

        
      };
    
    const api = async () =>{
        try { 
          const requestBody = {
            firstName: Fname,
            lastName: Lname,
            email,
            number: num,
            notes: comment,
            base64Data: base64Array,
            lng: i18n.language,
            magic : id,
            // test : true
          };
    
          const response = await fetch('https://office-backend.andent.al/clients/form', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(requestBody),
          });
    
          const data = await response.json();
          console.log(data);
          setSubmitted(true);
    
          setTimeout(() => {
            window.location.reload();
          }, 4000);
    
        } catch (error) {
          
        }
      }

    const handleSubmit = (e) =>{
        e.preventDefault();
        api();
    }

    const inputProps = {
      required: true,
      minlength: 5
    };
    return(
        <>
         <Helmet htmlAttributes={props.lang}>
         <link rel="canonical" href={canonicalUrl} />
         <link rel="alternate" href={canonicalUrl} hrefLang={props.lang} />
        </Helmet>
            <div className="container appointment-ctr">
                <div className="row justify-content-center align-items-center text-center">
                    <div className="col-lg-12 col-12">
                        <h2>
                        {t("bookaptmnt")}
                        </h2>
                    </div>
                </div>
                <div className="row justify-content-center align-items-center text-center">
                    <div className="col-lg-12 col-12">
                        <img src={logo} alt='andent-logo' className='apt-logo'/>
                    </div>
                </div>
                <form>
                <div className='form'>
                <div className='row justify-content-start' style={{width:'100%'}}>
                <div className='col-md-6 col-12 text-start'>
                    <div>
                    <h2 className='popup-heading-2 text-start'>
                        {t('magicFN')}
                    </h2>
                    <input tabindex="1" className='book-input' required placeholder={t('magicFN1')} onChange={chFN}/>
                    </div>
                    <div style={{marginTop:"8px"}}>
                    <h2 className='popup-heading-2 text-start'>
                    {t('magicE')}
                    </h2>
                    <input tabindex="3" type='email' className='book-input' required placeholder={t('magicE1')} onChange={chEmail} style={{paddingRight:"30px"}}/>
                    <img src={mail} alt='mail-icon' className='small-icon book-mail-icon'/>
                    </div>
                </div>
                <div className='col-md-6 col-12 text-start book-apt-pos'>
                    <div>
                    <h2 className='popup-heading-2 text-start'>
                    {t('magicLN')}
                    </h2>
                    <input tabindex="2" className='book-input' required placeholder={t('magicLN1')} onChange={chLN}/>
                    </div>
                    <div style={{marginTop:"8px"}}>
                    <h2 className='popup-heading-2 text-start'>
                    {t('magicN')}
                    </h2>
                    <div className=''>
                    <PhoneInput country="IT" id="phone-number-magic" tabindex="4" placeholder={t('magicN1')} inputProps={inputProps} onChange={(data) => setNum(data.value)}/>
                    {/* <img src={phone} alt='mail-icon' className='small-icon book-mail-icon'/> */}
                    </div>
                    </div>
                </div>

                <div className='justify-content-start' style={{width:'100%',marginTop:"8px"}}>
                    <div className='col-12 col-lg-12 text-start'>
                        <h2 className='popup-heading-2 text-start'>
                        {t('magicM')}
                        </h2>
                    </div>
                    <div className='col-lg-12 col-12 text-start'>
                        <textarea type='' onChange={chCmnt} className='popup-inputs-5' required placeholder={t('magicM1')} style={{resize:"none"}}></textarea>
                    </div>
                </div>

                <div className='row justify-content-start' style={{width:"100%",marginTop:`${pana.length===0? `0px` : `12px`}`}}>
                {pana? (pana.map((file, index) => (
                        <h2 key={index} className='popup-heading-3 text-start d-flex align-items-center justify-content-start'>
                                {file.name}<a  href={file} download={file.name} style={{marginLeft:"10px"}}>
                                    <img src={download} alt='download-icon' className='small-icon'/>
                                </a>
                                <span style={{marginLeft:"10px"}} onClick={() => delPana(index)}>
                                    <img src={del} alt='delete-icon' className='small-icon'/>
                                </span>
                        </h2>
                    ))) : ""}
                </div>
                <div className='row justify-content-start justify-content-md-start upload-pano-mob' style={{width:"100%",marginTop:"12px"}}>
                    <div className='col-6 col-lg-6 text-start d-flex justify-content-center uploadpano'>
                    <label className={`andent-button-long2`}>
                        <h2 className='button-text' style={{marginTop:"2px"}}>
                        {pana.length===3? 'Limit Reached!' : t('magicUpload')}
                        </h2>
                        <span className=''>
                            <img src={upload} alt='upload-icon' className='small-icon' />
                        </span>
                        <input
                            id="my-file"
                            accept=".pdf, .jpg, .jpeg, .png"
                            onChange={handlePanoChange}
                            multiple
                            disabled={pana.length===3}
                            type="file"
                            style={{ display: 'none' }}
                        />
                        {/* Button triggers file input click */}
                        <button type="button" style={{ display: 'none' }}></button>
                    </label>
                    </div>
                </div>

                {i18n.language==="it"? (window.innerWidth>768? (<>
                            <div className='row justify-content-center align-items-center' style={{paddingTop:"20px"}}>
                          <div className="col-1 d-flex justify-content-end">
                            <input type="checkbox" required />
                          </div>
                          <div className="col-lg-11 text-center text-md-start">
                          <div className='notice' >
                            {seeMore? (<><p>
                            {t("policyNotice")}
                            <Link to="#" onClick={()=>{setSeeMore(false)}}>
                            Vedi altro
                            </Link>
                            </p></>) : (<>
                              <p>
                                {t("policyNotice2")}
                              </p>
                            </>)}                
                          </div>
                          </div>
                          </div>
                          <div className='row justify-content-center align-items-center'>
                          <div className="col-1 d-flex justify-content-end">
                            <input type="checkbox" required />
                          </div>
                          <div className="col-lg-11 text-center text-md-start">
                          <div className='notice' style={{paddingTop:"20px"}}>
                            <p>
                              {t("policyNotice3")}<span>
                                <Link to={`/${t('privacyPolLink')}`}>
                                  {t("policyLink")}
                                </Link>
                              </span>
                            </p>          
                          </div>
                          </div>
                          </div>
                          </>) : (
                            <>
                            <div className='row justify-content-center align-items-center d-flex'>
                          <div className="col-lg-12 text-center text-md-start d-flex">
                          <div className='d-flex align-items-start' style={{paddingTop:"27px"}}>
                          <input type="checkbox" required />
                          </div>
                          <div className='notice' style={{paddingTop:"20px"}}>
                            {seeMore? (<><p>
                            {t("policyNotice")}
                            <Link to="#" onClick={()=>{setSeeMore(false)}}>
                            Vedi altro
                            </Link>
                            </p></>) : (<>
                              <p>
                                {t("policyNotice2")}
                              </p>
                            </>)}                
                          </div>
                          </div>
                          </div>
                          <div className='row justify-content-center align-items-center'>
                          <div className="col-lg-12 text-center text-md-start d-flex">
                          <div className='d-flex align-items-start' style={{paddingTop:"27px"}}>
                          <input type="checkbox" required />
                          </div>
                          <div className='notice' style={{paddingTop:"20px"}}>
                            <p>
                              {t("policyNotice3")}<span>
                                <Link to={`/${t('privacyPolLink')}`}>
                                  {t("policyLink")}
                                </Link>
                              </span>
                            </p>          
                          </div>
                          </div>
                          </div>
                          </>
                          )) : (<>
                            <div className='row justify-content-center align-items-center' style={{paddingTop:"20px",paddingLeft:"12px"}}>
                          <div className="col-lg-12 d-flex align-items-center text-center text-md-start">
                            <p>
                            {t("policyNotice")}<span>
                            <Link to={`/${t('privacyPolLink')}`}>{t("policyLink")}</Link>
                            </span>{t("policyNotice1")}
                            </p>
                          </div>
                          </div>
                          </>)}
                <div className='captcha d-flex justify-content-center col-lg-12 col-12'>
                <ReCAPTCHA
                    sitekey="6LdXID8oAAAAAHscjkYCPO8HkAuh6QBkm8dUwpVx"
                    onChange={()=>{
                    setValid(true)
                    }}
                />
                </div>
                <div className='row justify-content-start d-flex upload-pano-mob' style={{width:'100%',paddingTop:"20px"}}>
                    <div className='col-12 col-lg-12 text-start d-flex justify-content-center submitform' style={{gap:"24px"}}>
                    <button type='submit' disabled={!valid} className='andent-button-long' onClick={handleSubmit}>
                        <h2 className='button-text' style={{marginTop:"2px"}}>
                        {t('magicSubmit')}<span style={{marginLeft:"8px",position:"relative"}}>
                        <img src={send} alt='genlink icon' className='small-icon'/>
                        </span>
                        </h2>
                    </button>
                    </div>
                </div>
            </div>
            </div>
            </form>
            </div>
            <div className={`${submitted? `form-submitted` : `d-none`}`} onClick={()=>{setSubmitted(false)}}>
                <div className='row justify-content-center text-center'>
                    <div className='col-lg-12 col-12'>
                        <img src={happy} alt='success-icon' style={{width:"32px",height:"32px"}} />
                    </div>
                </div>
                <div className='row justify-content-center text-center'>
                    <div className='col-lg-12 col-12'>
                        <h2 className='popup-heading-2'>
                        Your form has been submitted successfully. We will be contacting you soon!<br/>
                        Thank you! 
                        </h2>
                    </div>
                </div>
            </div>
        </>
    )
}

export default BookApt;