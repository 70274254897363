import { useTranslation } from "react-i18next";
import { Helmet,HelmetProvider } from 'react-helmet-async'
import { useLocation } from 'react-router-dom'
import {getCanonicalUrl} from '../../utils/canonicalUrl'
import { useEffect, useState } from "react";

function CookiePolicy(){

    const {t, i18n} = useTranslation();
    const location = useLocation();
    const canonicalUrl = getCanonicalUrl(location)
    const [lang, setLang] = useState(i18n.language);

    useEffect(() => {
        // Update the lang state whenever the language changes
        const handleLanguageChange = () => {
          setLang(i18n.language);
        };
    
        i18n.on('languageChanged', handleLanguageChange);
    
        return () => {
          i18n.off('languageChanged', handleLanguageChange);
        };
      }, [i18n]);

    return(
        <HelmetProvider>
            <Helmet htmlAttributes={{lang}}>
                <link rel="canonical" href={canonicalUrl} />
                <link rel="alternate" href='https://andent.al/cookie-policy' hrefLang="x-default" />
                <link rel="alternate" href='https://andent.al/it/cookie-policy' hrefLang="it" />
            </Helmet>
        <div className="container mob-cookie-pad" style={{paddingTop:"150px",opacity:"1 !important"}}>
            <div className="row">
                <div className="col-lg-12 col-12 d-flex justify-content-md-center text-center">
                    <h1 className="size-60" style={{fontWeight:"700"}}>
                        {t("cookiePolicy")}
                    </h1>
                </div>
                <div className="col-lg-12 text-center text-md-start andent-padding">
                    <p className="theme-dark size-18">
                       {t("cookiePolicy1")}
                    </p>
                    <br/>
                    <p className="theme-dark size-18">
                       {t("cookiePolicy2")}
                    </p>
                    <br/>
                    <p className="theme-dark size-18">
                       {t("cookiePolicy3")}
                    </p>
                </div>
            </div>
        </div>
        </HelmetProvider>
    )
}

export default CookiePolicy;