import { useTranslation } from "react-i18next";
import { useLocation } from 'react-router-dom'
import { Helmet,HelmetProvider } from 'react-helmet-async'
import { useEffect, useState } from "react";
import {getCanonicalUrl} from '../../utils/canonicalUrl'

function PrivatePolicy(){

    const {t,i18n} = useTranslation();
    const location = useLocation();
    const canonicalUrl = getCanonicalUrl(location)
    const [lang, setLang] = useState(i18n.language);

    useEffect(() => {
      // Update the lang state whenever the language changes
      const handleLanguageChange = () => {
        setLang(i18n.language);
      };
  
      i18n.on('languageChanged', handleLanguageChange);
  
      return () => {
        i18n.off('languageChanged', handleLanguageChange);
      };
    }, [i18n]);

    return(
      <HelmetProvider>
         <Helmet htmlAttributes={{lang}}>
         <link rel="canonical" href={canonicalUrl} />
         <link rel="alternate" href='https://andent.al/privacy-policy-en' hrefLang="x-default" />
         <link rel="alternate" href='https://andent.al/it/privacy-policy-it' hrefLang="it" />
         </Helmet>
        <div className="container mob-cookie-pad" style={{paddingTop:"150px",opacity:"1 !important"}}>
            <div className="row">
                <div className="col-lg-12 col-12 d-flex justify-content-md-center text-center">
                    <h1 className="size-60" style={{fontWeight:"700"}}>
                        {t("privacyPol")}
                    </h1>
                </div>
                <div className="col-lg-12 text-center text-md-start andent-padding">
                    <p className="theme-dark size-16">
                       {t("privacyp1")}
                    </p>
                    <br/>
                    <p className="theme-dark size-16">
                       {t("privacyp2")}
                    </p>
                    <br/>
                    <p className="theme-dark size-16">
                       {t("privacyp3")}
                    </p>
                    <br/>
                    <p className="theme-dark size-16">
                       {t("privacyp4")}
                    </p>
                    <br/>
                    <p className="theme-dark size-20">
                       <b>{t("privacyp5")}</b>
                    </p>
                    <br/>
                    <p className="theme-dark size-18">
                       {t("privacyp6")}
                    </p>
                    <br/>
                    <p className="theme-dark size-16 text-start">
                       <li style={{listStyleType:"circle"}}>
                         {t("privacyp61")} 
                       </li>
                       <li style={{listStyleType:"circle"}}>
                         {t("privacyp62")} 
                       </li>
                       <li style={{listStyleType:"circle"}}>
                         {t("privacyp63")} 
                       </li>
                       <li style={{listStyleType:"circle"}}>
                         {t("privacyp64")} 
                       </li>
                       <li style={{listStyleType:"circle"}}>
                         {t("privacyp65")} 
                       </li>
                    </p>
                    <br/>
                    <p className="theme-dark size-16">
                       {t("privacyp7")}
                    </p>
                    <br/>
                    <p className="theme-dark size-16">
                       {t("privacyp8")}
                    </p>
                    <br/>
                    <p className="theme-dark size-20">
                       <b>{t("privacyp9")}</b>
                    </p>
                    <br/>
                    <p className="theme-dark size-16">
                       {t("privacyp10")}
                    </p>
                    <br/>
                    <p className="theme-dark size-16">
                       {t("privacyp11")}
                    </p>
                    <br/>
                    <p className="theme-dark size-16">
                       {t("privacyp12")}
                    </p>
                    <br/>
                    <p className="theme-dark size-20">
                       <b>{t("privacyp13")}</b>
                    </p>
                    <br/>
                    <p className="theme-dark size-16">
                       {t("privacy14")}
                    </p>
                    <br/>
                    <p className="theme-dark size-20">
                       <b>{t("privacy15")}</b>
                    </p>
                    <br/>
                    <p className="theme-dark size-16">
                       {t("privacy16")}
                    </p>
                    <br/>
                    <p className="theme-dark size-16">
                       {t("privacy17")}
                    </p>
                    <br/>
                    <p className="theme-dark size-16">
                       {t("privacy18")}
                    </p>
                    <br/>
                    <p className="theme-dark size-16">
                       {t("privacy19")}
                    </p>
                    <br/>
                    <p className="theme-dark size-16">
                       {t("privacy20")}
                    </p>
                    <br/>
                    <p className="theme-dark size-16">
                       {t("privacy21")}
                    </p>
                    <br/>
                    <p className="theme-dark size-16">
                       {t("privacy22")}
                    </p>
                    {i18n.language==="it"? (
                    <>
                    <br/>
                    <p className="theme-dark size-16">
                       {t("privacy23")}
                    </p>
                    <br/>
                    <p className="theme-dark size-16">
                       {t("privacy24")}
                    </p>
                    <br/>
                    <p className="theme-dark size-16">
                       {t("privacy25")}
                    </p>
                    <br/>
                    <p className="theme-dark size-16">
                       {t("privacy26")}
                    </p>
                    <br/>
                    <p className="theme-dark size-20">
                       <b>{t("privacy27")}</b>
                    </p>
                    <br/>
                    <p className="theme-dark size-16">
                       {t("privacy28")}
                    </p></>) : (<></>) }
                    
                </div>
            </div>
        </div>
        </HelmetProvider>
    )
}

export default PrivatePolicy;