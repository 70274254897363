import React from "react";
import First from "../Menblog/First";

function Blogchild (props) {

    return(
      <>
      {(
        <>
          {props.blogData.length > 0 ? (
            <section className="inner-blog pt-120 pb-120">
              <div className="container">
                <div className="row">
                  <div className="col-lg-8">
                    {props.blogData !== null &&
                      props.blogData.map((data, index) => (
                        <div>
                          <First
                            abstract={data.attributes.abstract}
                            by={data.attributes.by}
                            published={data.attributes.publishedAt}
                            content={data.attributes.content}
                            cover={data.attributes.cover}
                            title={data.attributes.title}
                            id={data.attributes.slug}
                          />
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </section>
          ) : (
            <section className="inner-blog pt-120 pb-120">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <h1 className="size-60 mob-heading theme-dark">LOADING...</h1>
                  </div>
                </div>
              </div>
            </section>
          )}
        </>
      )}
      </>
    );
}

export default Blogchild;