import { useState,useEffect } from "react";
import thumbnail from '../../assets/andent-data/why-us-thumbnail.png';

function Main (){
    
    const [isMobile, setIsMobile] = useState(false);
    const [isTablet, setIsTablet] = useState(false);

    // choose the screen size
    const handleResize = () => {
    const width = window.innerWidth;

    setIsMobile(width < 720);
    setIsTablet(width >= 720 && width < 1024);
    };

    // create an event listener
    useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize(); // Initial call to set initial states

    return () => {
        window.removeEventListener("resize", handleResize);
    };
    }, []);

    return(
        <div>
            <div className="container move-up2" >
            <div className='row justify-content-center'>
                <div className='col-lg-8 col-md-8 col-12 d-flex justify-content-center'>
                    <div
                    className="web-vid"
                    style={{
                        position: 'relative',
                        paddingTop: isMobile ? '56.25%' : isTablet? '56.25%' : '',
                        width: isMobile ? '100%' : '584px', // Width for mobile remains 100%, for others it's 640px
                    }}
                    >
                    <video controls poster={thumbnail} playsInline 
                    preload='auto' style={{ width: "100%", height: "100%", borderRadius: "10px", overflow: "hidden", position: "absolute", top: 0, left: 0}}>
                    <source src="https://d2tn9nq6wb6bbc.cloudfront.net/andent-media/compressed/Andent+Award+Video.mp4" type="video/mp4" />
                    Your browser does not support the video tag.
                    </video>
                    </div>
            </div>
            </div>
        </div>
        </div>
    )
}

export default Main;