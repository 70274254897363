import React, { useState } from 'react'
import Logo from '../../assets/andent-data/logo.png'
import { Link } from 'react-router-dom'
import phone from '../../assets/andent-data/callcalling.png'
import mail from '../../assets/andent-data/mail1.png'
import ig from '../../assets/andent-data/instagram.png'
import fb from '../../assets/andent-data/facebook.png'
import tiktok from '../../assets/andent-data/tiktok.png'
import { useTranslation } from 'react-i18next'
import { useEffect } from 'react'
import { NavLink } from 'react-router-dom'
import { useContext } from 'react'
import { ConsentContext } from '../../routes'
import enflag from '../../assets/andent-data/enflag.png'
import itflag from '../../assets/andent-data/itflag.png'
import sqflag from '../../assets/andent-data/sqflag.png'
import ReactGA from "react-ga4";

function Main() {
    const [mobile, setmobile] = useState(false);
    const [Services, setServices] = useState(false)
    const {t,i18n}=useTranslation();
    const [transform, setTransform] = useState("");
    const value = useContext(ConsentContext);
    const [currentLang,setCurrentLanguage]=useState("");
    const lang = i18n.language;
    const highLight = window.innerWidth<=1400 ? "#6DA7A2" : "white";
    const [topVal,setTopVal] = useState("auto")
    
    useEffect(() => {
        const handleResize = () => {
          if (window.innerHeight > 0) {
            // Check if the viewport height changes
            setTopVal('0'); // Set to '200px' if it changes
          }
        };
    
        window.addEventListener('scroll', handleResize);
    
        return () => {
          window.removeEventListener('scroll', handleResize);
        };
      }, []);

  
    useEffect(() => {

        //"translate(-5px,-5px)"
          const lng= i18n.language;
          //en-US sq it
    
          if (lng==='sq') {
            setTransform("translateX(-8px)");
          } else {
            setTransform("");
          }
      },[i18n,setTransform,lang]);


    const linkClick = (e) =>{
        if(value===1){
           /* window.analytics.track("Link clicked", {
                buttonText: e.currentTarget.title,
                link: e.currentTarget.href,
                clickedOnPage: window.location.pathname
              });*/
              ReactGA.event({
                category: "Clicked on Link",
                action: `Link Clicked : ${e.currentTarget.title}`
              });
              window.fbq('trackCustom', `Link Clicked : ${e.currentTarget.title}`);
        }
          setmobile(false)
    }

    const linkTwoClick = (e) =>{
        if(value===1){
           /* window.analytics.track("Link clicked", {
                buttonText: "ContactUs",
                link: e.currentTarget.href,
                clickedOnPage: window.location.pathname
              });*/
              ReactGA.event({
                category: "Clicked on Link",
                action: 'Link Clicked : ContactUsHeader'
              });
              window.fbq('trackCustom', 'Link Clicked : Contact Us Header');
        }
    }

    const handleLanguageChange = (e) => {
        const lng= e.target.alt;
        if(lng==="english"){
            i18n.changeLanguage('en-US')
            localStorage.setItem('selectedLanguage', 'en-US')
            setCurrentLanguage("english")
            console.log(currentLang)
           // window.location.reload(true)
            window.location.href = '/';
            if(value===1){
                /*  window.analytics.track("Language Changed", {
                      changedTo: e.target.value,
                      changedOnPage: window.location.pathname
                    });*/
                    ReactGA.event({
                      category: "Language Changed",
                      action: `Lang change to : ${lng}`
                    });
                    window.fbq('trackCustom', `Lng change to ${lng}`);
              }
        }
        else if(lng==="italian"){
            i18n.changeLanguage('it')
            localStorage.setItem('selectedLanguage', 'it')
            setCurrentLanguage("italian")
            console.log(currentLang)
          //  window.location.reload(true)
            window.location.href = ('/it');
            if(value===1){
                /*  window.analytics.track("Language Changed", {
                      changedTo: e.target.value,
                      changedOnPage: window.location.pathname
                    });*/
                    ReactGA.event({
                      category: "Language Changed",
                      action: `Lang change to : ${lng}`
                    });
                    window.fbq('trackCustom', `Lng change to ${lng}`);
              }
        }
        else {
            i18n.changeLanguage('sq')
            localStorage.setItem('selectedLanguage', 'sq')
            setCurrentLanguage("albanian")
            console.log(currentLang)
          //  window.location.reload(true)
            window.location.href = ('/sq');
            if(value===1){
                /*  window.analytics.track("Language Changed", {
                      changedTo: e.target.value,
                      changedOnPage: window.location.pathname
                    });*/
                    ReactGA.event({
                        category: "Language Changed",
                        action: `Lang change to : ${lng}`
                      });
                      window.fbq('trackCustom', `Lng change to ${lng}`);
              }
        }
          //en-US english
          //it  italian 
          //sq albanian
    }

    const selectedStyle = {
        border: `2px solid ${highLight}`,
    };

    const notSelectedStyle={
        outlineStyle: "none",
        outlineColor: "none"
    }

    return (
        <>
            <header className="header-area header-three">
                <div className="d-none d-lg-block" style={{backgroundColor:"#6DA7A2"}}>
                    <div className="container" id="" style={{position:"fixed !important"}}>
                        <div className="row align-items-center">
                            <div className="col-lg-8 col-md-8 d-none d-lg-block">
                                <div className="header-cta" style={{position:"relative",right:"45px"}}>
                                    <ul>
                                        <li>
                                            <img src={phone} alt='phone' className='icon' style={{paddingRight:"10px"}}/>
                                            <span style={{color:"white"}}>{t("simNum")}</span>
                                        </li>
                                        <li>
                                            <img src={mail} alt='mail' className='icon1' style={{ paddingRight: "10px" }} />
                                            <a href={`mailto:${t("emailLink")}`} style={{ color: "white", textDecoration: "none" }}>{t("emailLink")}</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-4 d-none d-lg-block text-end" style={{boxSizing: 'border-box', padding: '7px 0 7px 0', height: '100%',display:"flex"}}>
                                <div className="header-social header-btn-xaxis" style={{height: '100%', display: 'flex', justifyContent: 'flex-end', alignItems: 'center', marginTop: '0px'}}>
                                    <div className='languages d-flex justify-content-center align-items-center'>
                                        <div style={{}}>
                                        <Link to="#">
                                        <img src={enflag} alt='english' title="English" className='lang-flag' onClick={handleLanguageChange} style={lang==="en-US"? selectedStyle : notSelectedStyle}/>
                                        </Link>
                                        </div>
                                        <div style={{}}>
                                        <Link to="#">
                                        <img src={itflag} alt='italian' title="Italian" className='lang-flag' onClick={handleLanguageChange} style={lang==="it"? selectedStyle : notSelectedStyle}/>
                                        </Link>
                                        </div>
                                        <div style={{}}>
                                        <Link to="#">
                                        <img src={sqflag} alt='albanian' title="Albanian" className='lang-flag' onClick={handleLanguageChange} style={lang==="sq"? selectedStyle : notSelectedStyle}/>
                                        </Link>
                                        </div>
                                    </div>
                                    <div style={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}}>
                                        <Link to='https://instagram.com/andent_clinic?igshid=MzRlODBiNWFlZA' target="_blank" 
                                        rel="noreferrer"title="Instagram" onClick={linkClick}>
                                        <img src={ig} alt='phone' className='icon2' style={{width:"32px",height:"32px"}}/>
                                        </Link>
                                        <Link to='https://www.facebook.com/andentclinic?mibextid=LQQJ4d' target="_blank"
                                        rel="noreferrer"title="Facebook" onClick={linkClick}>
                                        <img src={fb} alt='phone' className='icon3' style={{width:"32px",height:"32px"}}/>
                                        </Link>
                                        <Link to='https://www.tiktok.com/@andentclinic' target="_blank"
                                        rel="noreferrer"title="Tiktok" onClick={linkClick}>
                                        <img src={tiktok} alt='phone' className='icon2' style={{width:"32px",height:"32px"}} />
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div id="header-sticky" className="menu-area sticky-menu" style={{ backgroundColor: "white",top:`${topVal}` }}>
                    <div className="container">
                        <div className="second-menu">
                            <div className="row align-items-center">
                                <div className="col-xl-1 col-lg-1 justify-content-start move-logo-left-landscape">
                                    <div className="logo header-logo logo-pos">
                                        <Link to={`/${t('homelink')}`} title='AndentLogo' onClick={linkClick}>
                                            <img src={Logo} alt="logo" style={{height:"35px",width:"145px"}}/>
                                        </Link>
                                    </div>
                                </div>
                                <div className="col-xl-10 col-lg-10 move-header-left move-navbar-left-landscape">
                                    <div className="main-menu text-right text-xl-right adjust-header">
                                        <nav id="mobile-menu" className="d-none d-lg-flex justify-content-end">
                                            <ul style={{maxWidth:"90%"}}>
                                                <li className='has-sub'>
                                                    <NavLink to={`/${t('homelink')}`} title='Home' onClick={linkClick} >{t("home")}</NavLink>
                                                </li>
                                                <li className='has-sub'>
                                                    <NavLink to={`/${t('dentalimplantsh')}`} title='Dental Implants' onClick={linkClick}>{t("implants")}</NavLink>
                                                </li>
                                                <li className='has-sub'>
                                                    <NavLink to={`/${t('dentalbridgesh')}`} title='Dental Bridges' onClick={linkClick}>{t("dentalbridges")}</NavLink>
                                                </li>
                                                <li className='has-sub'>
                                                    <NavLink to={`/${t('whyush')}`} title='why andent' onClick={linkClick}>{t("aboutus")}</NavLink>
                                                </li>
                                                <li className='has-sub'>
                                                    <NavLink to={`/${t('dentaltourismh')}`}  title='Dental Tourism' onClick={linkClick}>{t("tourism")} &nbsp; <span style={{position:"relative",bottom:"3px"}}><i className="arrow down"></i></span></NavLink>
                                                    <ul>
                                                    <li className='has-sub'>
                                                        <NavLink to={`/${t('testimonialsh')}`} title='Testimonials' onClick={linkClick}>{t("testimonial")}</NavLink>
                                                    </li>
                                                    <li className='has-sub'>
                                                        <NavLink to="/blog" title='Blogs' onClick={linkClick}>{t("blog")}</NavLink>
                                                    </li>
                                                    </ul>
                                                </li>
                                                <li className='has-sub'>
                                                    <Link to="#" title='Other Services' onClick={linkClick}>{t("otherserv")} &nbsp; <span style={{position:"relative",bottom:"3px"}}><i className="arrow down"></i></span></Link>
                                                    <ul>
                                                        <li>
                                                            <NavLink to={`/${t('orthodonticsh')}`} title='Orthodontics' onClick={linkClick}>{t("orthodontics")}</NavLink>
                                                        </li>
                                                        <li>
                                                            <NavLink to={`/${t('denturesh')}`} title='Dentures' onClick={linkClick}>{t("dentures")}</NavLink>
                                                        </li>
                                                        <li>
                                                            <NavLink to={`/${t('dentalveneersh')}`} title='Dental Veneers' onClick={linkClick}>{t("veneers")}</NavLink>
                                                        </li>
                                                        <li>
                                                            <NavLink to={`/${t('generaldentistryh')}`} title='General Dentistry' onClick={linkClick}>{t("general")}</NavLink>
                                                        </li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </nav>
                                    </div>
                                </div>
                                <div className="col-xl-1 col-lg-1 text-right d-none d-lg-block mt-25 mb-25 text-right text-xl-right move-left">
                                    <div className='header-btn-pos'>
                                        <ul>
                                            <li className='landscape-header-btn'>
                                                <div>
                                                    <Link to={`/${t('contactush')}`} className="header-btn" onClick={linkTwoClick}>
                                                        <p style={{transform:transform}}>
                                                        {t("contactus")}
                                                        </p>
                                                    </Link>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="mobile-menu mean-container">
                                        <div className="mean-bar">
                                            <Link to="#" onClick={() => setmobile(!mobile)} className={`meanmenu-reveal ${mobile && "meanclose"}`} style={{ right: "0px", left: "auto", textAlign: "center", textIndent: "0px", fontSize: "18px" }} title='A toggle menu that contain links to other pages'>
                                                {mobile ? "X" : <span><span><span></span></span></span>}
                                            </Link>
                                            {mobile &&
                                                <nav className="mean-nav">
                                                    <ul style={{ display: "block" }}>
                                                        <li className='lang-sel-pad'>
                                                        <div className='languages d-flex justify-content-start align-items-center'>
                                                            <div style={{padding:"10px 10px 10px 0px"}}>
                                                            <img src={enflag} alt='english' className='lang-flag' onClick={handleLanguageChange} style={lang==="en-US"? selectedStyle : notSelectedStyle}/>
                                                            </div>
                                                            <div style={{padding:"10px 10px 10px 0px"}}>
                                                            <img src={itflag} alt='italian' className='lang-flag' onClick={handleLanguageChange} style={lang==="it"? selectedStyle : notSelectedStyle}/>
                                                            </div>
                                                            <div style={{padding:"10px 10px 10px 0px"}}>
                                                            <img src={sqflag} alt='albanian' className='lang-flag' onClick={handleLanguageChange} style={lang==="sq"? selectedStyle : notSelectedStyle}/>
                                                            </div>
                                                        </div>
                                                        </li>
                                                        <li className="has-sub">
                                                            <NavLink to={`/${t('homelink')}`} title='Home' onClick={linkClick} >{t("home")}</NavLink>
                                                        </li>
                                                        <li>
                                                            <NavLink to={`/${t('dentalimplantsh')}`} title='Dental Implants' onClick={linkClick}>{t("implants")}</NavLink>
                                                        </li>
                                                        <li className="has-sub">
                                                            <NavLink to={`/${t('dentalbridgesh')}`} title='Dental Bridges' onClick={linkClick}>{t("dentalbridges")}</NavLink>
                                                        </li>
                                                        <li className="has-sub">
                                                            <NavLink to={`/${t('whyush')}`} title='Why Andent' onClick={linkClick}>{t("aboutus")}</NavLink>
                                                        </li>
                                                        <li className="has-sub">
                                                            <NavLink to={`/${t('dentaltourismh')}`} title='Dental Tourism' onClick={linkClick}>{t("tourism")}</NavLink>
                                                        </li>
                                                        <li className="has-sub">
                                                            <NavLink to={`/${t('testimonialsh')}`} title='Testimonials' onClick={linkClick}>{t("testimonial")}</NavLink>
                                                        </li>
                                                        <li className='has-sub'>
                                                        <NavLink to="/blog" title='Blogs' onClick={linkClick}>{t("blog")}</NavLink>
                                                        </li>
                                                        <li className="mean-last has-sub">
                                                            <NavLink to="/contact" title='Other Services' onClick={linkClick}>{t("otherserv")}</NavLink>
                                                            {Services &&
                                                            <ul style={{ display: "block" }}>
                                                            <li>
                                                                <NavLink to={`/${t('orthodonticsh')}`}  title='Orthodontics' onClick={linkClick}>{t("orthodontics")}</NavLink>
                                                            </li>
                                                            <li>
                                                                <NavLink to={`/${t('denturesh')}`} title='Dentures' onClick={linkClick}>{t("dentures")}</NavLink>
                                                            </li>
                                                            <li>
                                                                <NavLink to={`/${t('dentalveneersh')}`} title='Dental Veneers' onClick={linkClick}>{t("veneers")}</NavLink>
                                                            </li>
                                                            <li>
                                                                <NavLink to={`/${t('generaldentistryh')}`} title='General Dentistry' onClick={linkClick}>{t("general")}</NavLink>
                                                            </li>
                                                            </ul>  
                                                            }
                                                            <Link className={`mean-expand ${mobile && "mean-clicked"}`} to="#" onClick={() => { setServices(!Services) }} style={{ fontSize: 18 }}>
                                                            {Services ? "-" : "+"}
                                                            </Link>
                                                        </li>
                                                    </ul>
                                                </nav>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </>
    )
}

export default Main