import d1 from '../../assets/andent-data/o1.png'
import d2 from '../../assets/andent-data/o2.png'
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';

function Main () {
    const [bottomPadding, setBottomPadding] = useState(0);
    const [isMobile, setIsMobile] = useState(false)

    const {t,i18n}=useTranslation();

    useEffect(() => {
        const lng= i18n.language;

        if (lng==='it')  {
            if(!isMobile){
                setBottomPadding(230);
            }
            if (window.innerWidth<=1400){
                setBottomPadding(40);
            }
          }
        else  if (lng==='it' && isMobile)  {
            setBottomPadding(50);
          }
          else if (lng==='en-US' && window.innerWidth>=1400){
            setBottomPadding(160)
          }
           else {
            setBottomPadding(0);
          }
    },[bottomPadding,i18n,isMobile]);

     //choose the screen size 
     const handleResize = () => {
        if (window.innerWidth < 720) {
            setIsMobile(true)
        } else {
            setIsMobile(false)
        }
      }
    
      // create an event listener
      useEffect(() => {
        window.addEventListener("resize", handleResize)
  
      })



    return(
        <div className='container mob-top-pad orth-types-bot-pad' style={{paddingBottom:bottomPadding}}>
            <div className='row justify-content-center'>
                <div className='col-lg-10 col-md-10'>
                    <h2 className='theme-dark size-60 mob-heading text-center decent-pad'>
                        {t("orthtypes")}
                    </h2>
                </div>
            </div>
            <div className="row justify-content-center upper-pad-20 orth-type">
                <div className='col-lg-6 col-md-12 move-right'>
                {isMobile ? (
                    <>
                    <div className='decent-pad orth-text-head'>
                    <h3 className='theme-dark size-25 text-center upper-pad-10' style={{ lineHeight: "37.5px" }}>
                        {t("ortht1")}
                    </h3>
                    </div>
                    <div className='orth-types text-center text-lg-start mob-top-pad'>
                    <img src={d1} alt={t("orthtype1")} title={t("orthtype1")} style={{ width: "240px", height: "144px", borderRadius: "10px" }} />
                    </div>
                    <div className='decent-pad orth-text-head'>
                    <div className='tourism-text mob-top-pad'>
                        <p className='size-16 text-center' style={{ lineHeight: "20px", fontWeight: "400", color: "black" }}>
                        {t("ortht1p")}
                        </p>
                    </div>
                    </div>
                    </>
                    ) : (
                    <>
                        <div className='orth-types text-center text-lg-start orth-center'>
                        <img src={d1} alt={t("orthtype1")} title={t("orthtype1")} style={{ width: "240px", height: "144px", borderRadius: "10px" }} />
                        </div>
                        <div className='decent-pad orth-text-head'>
                        <h3 className='theme-dark size-25 text-center upper-pad-10' style={{ lineHeight: "37.5px" }}>
                            {t("ortht1")}
                        </h3>
                        <div className='tourism-text'>
                            <p className='size-16 text-center' style={{ lineHeight: "20px", fontWeight: "400", color: "black" }}>
                            {t("ortht1p")}
                            </p>
                        </div>
                        </div>
                    </>
                )}
                </div>
                <div className='col-lg-6 col-md-12 move-left'>
                {isMobile ? (
                    <>
                    <div className='decent-pad orth-text-head'>
                    <h3 className='theme-dark size-25 text-center upper-pad-10' style={{lineHeight:"37.5px"}}>
                        {t("ortht2")}<br/> {t("ortht21")}
                    </h3>
                    </div>
                    <div className='orth-types text-center text-lg-start mob-top-pad'>
                    <img src={d2} alt={t("orthtype2")} title={t("orthtype2")} style={{ width: "240px", height: "144px", borderRadius: "10px" }} />
                    </div>
                    <div className='decent-pad orth-text-head'>
                    <div className='tourism-text mob-top-pad'>
                        <p className='size-16 text-center' style={{ lineHeight: "20px", fontWeight: "400", color: "black" }}>
                        {t("ortht2p")}
                        </p>
                    </div>
                    </div>
                    </>
                    ) : (
                    <>
                        <div className='orth-types text-center text-lg-start orth-center'>
                        <img src={d2} alt={t("orthtype2")} title={t("orthtype2")} style={{ width: "240px", height: "144px", borderRadius: "10px" }} />
                        </div>
                        <div className='decent-pad orth-text-head'>
                        <h3 className='theme-dark size-25 text-center upper-pad-10' style={{lineHeight:"37.5px"}}>
                            {t("ortht2")}<br/> {t("ortht21")}
                        </h3>
                        <div className='tourism-text'>
                            <p className='size-16 text-center' style={{ lineHeight: "20px", fontWeight: "400", color: "black" }}>
                            {t("ortht2p")}
                            </p>
                        </div>
                        </div>
                        <br></br>
                        <br></br>
                    </>
                )}
                </div>
            </div>
        </div>
    )
}

export default Main;