import Aligner from '../../assets/andent-data/aligners.jpg'
import b1 from '../../assets/andent-data/a1.png'
import b2 from '../../assets/andent-data/a2.png'
import b3 from '../../assets/andent-data/a3.png'
import b4 from '../../assets/andent-data/a4.png'
import arrow from '../../assets/andent-data/arrow.png'
import { useEffect,useState } from 'react'
import { useTranslation } from 'react-i18next';


function Main (){

    const [isMobile, setIsMobile] = useState(false);
    const [isTablet, setIsTablet] = useState(false);
    const { t, i18n } = useTranslation();
    const [topPadding, setTopPadding] = useState(0);
    const [bottom,setBottom] = useState(0);
    const [bottom2,setBottom2] = useState(0);
    const [secondPad,setsecondPad] = useState(0);
    const [bottom4,setBottom4]= useState(0);
    const [bottom5,setBottom5]=useState(0);
  
    useEffect(() => {
      // Update isTablet state based on window width
      const handleResize = () => {
        if (window.innerWidth < 720) {
          setIsMobile(true);
          setIsTablet(false);
        } else if (window.innerWidth >= 720 && window.innerWidth < 1024) {
          setIsMobile(false);
          setIsTablet(true);
        } else {
          setIsMobile(false);
          setIsTablet(false);
        }
      }
  
      // Set up the initial isTablet state and add the resize listener
      handleResize();
      window.addEventListener("resize", handleResize);
  
      // Clean up the event listener when the component unmounts
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }, []);
  
    useEffect(() => {
      // Update topPadding based on language and device type
      const lng = i18n.language;
  
      if (lng === 'sq' && window.innerWidth>=  786) {

        setTopPadding(isTablet ? 60 : (window.innerWidth>=1000 && window.innerWidth<=1400)? 290 : 240);
        setBottom(40)
        setBottom5(isTablet? 20 : 40)
        setBottom2(50)
        setsecondPad(isTablet? 40 : 55)
        setBottom4(17)
      } else if (isMobile && lng === 'it') {
        setTopPadding(0);
      }
    else if (lng === 'it' && window.innerWidth>=  786){
        setTopPadding((window.innerWidth>=1000 && window.innerWidth<=1400)? 250 : 0)
        setBottom(40)
        setBottom2(20)
        setsecondPad(30);
        setBottom4(15);
    }
    else if (lng ==='en-US' && window.innerWidth>=  786){
        setBottom(isTablet? 17 : 40)
        setTopPadding(window.innerWidth<=1000? 30 : window.innerWidth<=1400? 250 : 10);
        setsecondPad(15)
        setBottom4(20)
        setBottom5(isTablet? 25 : 40)
    }
      else {
        setTopPadding(isTablet ? 60 : isMobile? 50 : 160);
        setBottom(40)
        setBottom2(20)
      }
    }, [isTablet, i18n, isMobile]);

    


    return(
        <div>
            <div className="container andent-padding" style={{paddingTop:topPadding}}>
                <div className="row justify-content-center align-items-center text-center">
                    <h2 className='theme-dark size-60 mob-heading'>
                    {t("orthben")}
                    </h2>
                </div>
                <div className="row justify-content-center align-items-center text-center upper-padding">
                    <div className="col-lg-5 col-md-10 move-left" style={{paddingBottom:"50px"}}>
                        <div>
                            <img src={Aligner} alt={t("alignersbenefit1")} title={t("alignersbenefit1")} style={{width:"482px",height:"auto",maxHeight:"322px"}}/>
                        </div>
                    </div> 
                    <br/>
                    <div className="col-lg-3 col-md-10 d-flex justify-content-center text-center move-up orth-benefits-textbox">
                        <div>
                            <h3 className='size-25 theme-dark' style={{fontWeight:"700"}}>
                            {t("orthinfo1")}
                            </h3>
                            <div className='orth-benefits-p text-center'>
                                 <p style={{color:"black",fontWeight:"400",lineHeight:"20px"}}>
                                 {t("orthinfo1p")} <span className='theme-andent'>{t("orthinfo1pspan")}</span> {t("orthinfo1p2")}
                                </p>
                            </div>
                           
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-10 d-flex justify-content-center text-center move-up orth-benefits-textbox move-right">
                        <div>
                            <h3 className='size-25 theme-dark' style={{fontWeight:"700"}}>
                            {t("orthinfo2")}
                            </h3>
                            <div className='orth-benefits-p text-center'>
                            <p style={{color:"black",fontWeight:"400",lineHeight:"20px"}}>
                            {t("orthinfo2p")} <span className='theme-andent'>{t("orthinfo2pspan")}</span>,
                            {t("orthinfo2p2")}
                            </p>
                            </div>
                           
                        </div>
                    </div>     
                </div>
                <div className="row align-items-center">
                <div className="col-lg-12 mob-top-pad">
                    <div className="section-title center-align mb-50 text-center wow fadeInDown animated" data-animation="fadeInDown" data-delay=".4s" >
                    <h2 className='theme-dark mob-heading'>{t("howalignerswork")}</h2>
                    </div>
                </div>
                </div>
                
                {isMobile? ("") : (<div className="row align-items-center">
                <div className="col-lg-12">
                    <div className="section-title center-align mb-50 text-center wow fadeInDown animated" data-animation="fadeInDown" data-delay=".4s">
                    {!isMobile && !isTablet && <img src={arrow} alt='arrow' />}
                    </div>
                </div>
                </div>)}
                

                <div className="row justify-content-around">
                <div className="col-lg-2 col-md-6 d-flex justify-content-center">
                    <div className="fadeInUp animated" data-animation="fadeInUp" data-delay=".4s" >
                    {isMobile ? (
                    <div>
                    <div className='d-flex justify-content-center upper-padding'>
                        <img src={b1} alt="dt1" style={{height:"120px",width:"120px"}} className='img-fluid'/>
                    </div>
                    <div style={{paddingTop:"20px"}}>
                        <h4 className='theme-dark size-18' style={{textAlign:"center"}}>{t("aligners1")}</h4>
                    </div>
                    <div>
                        <p className='size-16 text-center' style={{color:"black",lineHeight:"20px",fontWeight:"400"}}>
                        {t("aligners1p")}
                        </p>
                    </div>
                    </div>
                    ) : (
                        <div>
                    <div style={{paddingTop:"20px"}}>
                        <h4 className='theme-dark size-18' style={{textAlign:"center"}}>{t("aligners1")}</h4>
                    </div>
                    <div>
                        <p className='size-16 text-center' style={{color:"black",lineHeight:"20px",fontWeight:"400"}}>
                        {t("aligners1p")}
                        </p>
                    </div>
                    <div className='d-flex justify-content-center' style={{paddingTop:bottom}}>
                        <img src={b1} alt="dt1" style={{height:"120px",width:"120px"}} className='img-fluid'/>
                    </div>
                    </div>
                    )}
                    </div>
                    </div>
                    <div className="col-lg-2 col-md-6 d-flex justify-content-center">
                    <div className="fadeInUp animated" data-animation="fadeInUp" data-delay=".4s" >
                        {isMobile ? (
                        <div>
                        <div className='d-flex justify-content-center upper-padding'>
                        <img src={b2} alt="dt1" style={{height:"120px",width:"120px"}} className='img-fluid'/>
                        </div>
                        <div style={{paddingTop:"20px"}}>
                        <h4 className='theme-dark size-18' style={{textAlign:"center"}}>{t("aligners2")}</h4>
                        </div>
                        <div>
                            <p className='size-16 text-center' style={{color:"black",lineHeight:"20px",fontWeight:"400"}}>
                            {t("aligners2p")}
                            </p>
                        </div>
                        </div>
                        ) : (
                            <div>
                        <div style={{paddingTop:"20px"}}>
                        <h4 className='theme-dark size-18' style={{textAlign:"center"}}>{t("aligners2")}</h4>
                        </div>
                        <div>
                            <p className='size-16 text-center' style={{color:"black",lineHeight:"20px",fontWeight:"400"}}>
                            {t("aligners2p")}
                            </p>
                        </div>
                        <div className='d-flex justify-content-center' style={{paddingTop:secondPad}}>
                        <img src={b2} alt="dt1" style={{height:"120px",width:"120px"}} className='img-fluid'/>
                        </div>
                        </div>
                        )}
                    </div>
                    </div>
                    <div className="col-lg-2 col-md-6 d-flex justify-content-center">
                    <div className="fadeInUp animated" data-animation="fadeInUp" data-delay=".4s" >
                        {isMobile ? (
                        <div>
                        <div className='d-flex justify-content-center upper-padding'>
                            <img src={b3} alt="dt1" style={{height:"120px",width:"120px"}} className='img-fluid'/>
                        </div>
                        <div style={{paddingTop:"20px"}}>
                        <h4 className='theme-dark size-18' style={{textAlign:"center"}}>{t("aligners3")}</h4>
                        </div>
                        <div>
                            <p className='size-16 text-center' style={{color:"black",lineHeight:"20px",fontWeight:"400"}}>
                            {t("aligners3p")} 
                            </p>
                        </div>
                        </div>
                        ) : (
                            <div>
                        <div style={{paddingTop:"20px"}}>
                        <h4 className='theme-dark size-18' style={{textAlign:"center"}}>{t("aligners3")}</h4>
                        </div>
                        <div>
                            <p className='size-16 text-center' style={{color:"black",lineHeight:"20px",fontWeight:"400"}}>
                            {t("aligners3p")}
                            </p>
                        </div>
                        <div className='d-flex justify-content-center' style={{paddingTop:bottom4}}>
                        <img src={b3} alt="dt1" style={{height:"120px",width:"120px"}} className='img-fluid'/>
                        </div>
                        </div>
                        )}
                    </div>
                    </div>
                    <div className="col-lg-2 col-md-6 d-flex justify-content-center">
                    <div className="fadeInUp animated" data-animation="fadeInUp" data-delay=".4s" >
                        {isMobile ? (
                        <div>
                        <div className='d-flex justify-content-center upper-padding' >
                            <img src={b4} alt="dt1" style={{height:"120px",width:"120px"}} className='img-fluid'/>
                        </div>
                        <div style={{paddingTop:"10px"}}>
                        <h4 className='theme-dark size-18' style={{textAlign:"center"}}>{t("aligners4")}</h4>
                        </div>
                        <div>
                            <p className='size-16 text-center' style={{color:"black",lineHeight:"20px",fontWeight:"400"}}>
                            {t("aligners4p")}
                            </p>
                        </div>
                        </div>
                        ) : (
                            <div>
                            <div style={{paddingTop:"20px"}}>
                            <h4 className='theme-dark size-18' style={{textAlign:"center"}}>{t("aligners4")}</h4>
                            </div>
                            <div>
                                <p className='size-16 text-center' style={{color:"black",lineHeight:"20px",fontWeight:"400"}}>
                                {t("aligners4p")}
                                </p>
                            </div>
                            <div className='d-flex justify-content-center align-items-start' style={{paddingTop:i18n.language === "it" ? bottom2 : bottom5}}>
                            <img src={b4} alt="dt1" style={{height:"120px",width:"120px"}} className='img-fluid'/>
                            </div>
                            </div>
                        )}
                    </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Main;