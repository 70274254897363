import React from 'react'
import Logo from '../../assets/andent-data/logo.png'
import { Link } from 'react-router-dom'
import time from '../../assets/andent-data/timing.png'
import location from '../../assets/andent-data/address.png'
import phone from '../../assets/andent-data/phone.png'
import mail from '../../assets/andent-data/mail.png'
import footerimg from '../../assets/andent-data/footerpoweredby.svg'
import { useTranslation } from 'react-i18next'
import { useState,useEffect } from 'react'
import { useContext } from 'react'
import { ConsentContext } from '../../routes'
import ReactGA from "react-ga4";


function Main() {

    const value = useContext(ConsentContext);

    

    const linkClick = (e) =>{
        if(value===1){
          /*  window.analytics.track("Link clicked", {
                buttonText: e.currentTarget.title,
                link: e.currentTarget.href,
                clickedOnPage: window.location.pathname
            });*/
            ReactGA.event({
                category: "Clicked on Link",
                action: `Link Clicked : ${e.currentTarget.title}`
              });
            window.fbq('trackCustom', `Link Clicked : ${e.currentTarget.title}`);
        }
    }

    const {t,i18n}=useTranslation();
    const [currentLang,setCurrentLanguage]=useState("");
    const width = (i18n.language==="sq"? '250px' : '200px')
    const padValue = (i18n.language==="it" && window.innerWidth>=1400? '15px' : '22px')
    const itWidth = (i18n.language==="it"? "200px" : "auto")

    useEffect(() => {


        const language = i18n.language;
        if(language==="en-US"){
            setCurrentLanguage("english")
        }
        else if(language==="sq"){
            setCurrentLanguage("albanian")
        }
        else {
            setCurrentLanguage("italian")
        }
      },[setCurrentLanguage,i18n]);

    const sendAnalytics = () =>{
        if(value===1){
            ReactGA.event({
                category: "Clicked on Link",
                action: 'Redirected to https://www.iprobo.com/'
              });
            window.fbq('trackCustom', 'Link Clicked : https://www.iprobo.com/');
              
        }

    }

    const handleLanguageChange = (e) => {

        
        
        const lng= e.target.value;
        if(lng==="english"){
            i18n.changeLanguage('en-US')
            localStorage.setItem('selectedLanguage', 'en-US')
            setCurrentLanguage("english")
           // window.location.reload(true)
            window.location.href = '/';
            if(value===1){
                /* window.analytics.track("Language Changed", {
                     changedTo: e.target.value,
                     changedOnPage: window.location.pathname
                   });*/
                   ReactGA.event({
                     category: "Language Changed",
                     action: `Lang change to : ${e.target.value}`
                   });
                   window.fbq('trackCustom', `Lng change to ${e.target.value}`);
             }
        }
        else if(lng==="italian"){
            i18n.changeLanguage('it')
            localStorage.setItem('selectedLanguage', 'it')
            setCurrentLanguage("italian")
          //  window.location.reload(true)
            window.location.href = t('homelink');
            if(value===1){
                /* window.analytics.track("Language Changed", {
                     changedTo: e.target.value,
                     changedOnPage: window.location.pathname
                   });*/
                   ReactGA.event({
                     category: "Language Changed",
                     action: `Lang change to : ${e.target.value}`
                   });
                   window.fbq('trackCustom', `Lng change to ${e.target.value}`);
             }
        }
        else {
            i18n.changeLanguage('sq')
            localStorage.setItem('selectedLanguage', 'sq')
            setCurrentLanguage("albanian")
          //  window.location.reload(true)
            window.location.href = t('homelink');
            if(value===1){
                /* window.analytics.track("Language Changed", {
                     changedTo: e.target.value,
                     changedOnPage: window.location.pathname
                   });*/
                   ReactGA.event({
                     category: "Language Changed",
                     action: `Lang change to : ${e.target.value}`
                   });
                   window.fbq('trackCustom', `Lng change to ${e.target.value}`);
             }
        }
          //en-US english
          //it  italian 
          //sq albanian
    }


  return (
    <>
        <footer className="footer-bg footer-p pt-90" style={{ backgroundColor: "#A7CCC84D" }}>
                <div className="footer-top pb-70">
                    <div className="container">
                        <div className="row justify-content-between">
                            <div className="col-xl-4 col-lg-4 col-sm-6">
                            <div className="footer-widget mb-30">
                                <div className="f-widget-title">
                                <h2>
                                    <img src={Logo} alt="logo" />
                                </h2>
                                </div>
                                <div className="footer-link">
                                <p className='size-16' style={{textAlign:"left",color:"#000000",lineHeight:"21px"}}>
                                    {t("belowlogo")}
                                </p>
                                <div className="f-contact">
                                    <ul>
                                    <li className='theme-dark'>
                                        <img src={time} className='icon' alt='grey'/>
                                        <span  style={{color:"#000000"}}>
                                        {t("monday")} - {t("saturday")}:&nbsp;&nbsp;&nbsp;&nbsp;
                                        <br/>
                                        9:00am - 06:00pm
                                        </span>
                                    </li>
                                    </ul>
                                </div>
                                <div className="f-contact below-logo" style={{ paddingTop: "25px"}}>
                                <h4 style={{ fontSize: "14px" }}>Change Language</h4>
                                <select className='language-dropdown' value={currentLang} onChange={handleLanguageChange} aria-label='Select Language From The Following'>
                                    <option value="english">English</option>
                                    <option value="italian">Italian</option>
                                    <option value="albanian">Albanian</option>
                                </select>
                                </div>
                                </div>
                            </div>
                            </div>
                            <div className="col-xl-2 col-lg-2 col-sm-6">
                            <div className="footer-widget mb-30">
                                <div className="f-widget-title">
                                <h2 style={{fontSize:"25px",color:"black",width:`${itWidth}`}}>{t("otherlinks")}</h2>
                                </div>
                                <div className="footer-link">
                                <ul style={{fontWeight:"600"}}>
                                    <li className='size-16' style={{color:"black"}}>
                                    <Link to={`/${t('homelink')}`} title='Home' onClick={linkClick}>{t("home")}</Link>
                                    </li>
                                    <li  className='size-16' style={{color:"black"}}>
                                    <Link to={`/${t('whyush')}`} title='About Us' onClick={linkClick}>{t("aboutus")}</Link>
                                    </li>
                                    <li  className='size-16' style={{color:"black"}}>
                                    <Link to={`/${t('testimonialsh')}`} title='Testimonials' onClick={linkClick}>{t("testimonial")}</Link>
                                    </li>
                                    <li  className='size-16' style={{color:"black"}}>
                                    <Link to={`/${t('dentaltourismh')}`} title='Dental Tourism' onClick={linkClick}>{t("tourism")}</Link>
                                    </li>
                                </ul>
                                </div>
                            </div>
                            </div>
                            <div className="col-xl-2 col-lg-2 col-sm-6">
                            <div className="footer-widget mb-30">
                                <div className="f-widget-title">
                                <h2 style={{color:"black"}}>{t("ourservices")}</h2>
                                </div>
                                <div className="footer-link">
                                <ul style={{fontWeight:"600"}}>
                                    <li>
                                    <Link to={`/${t('dentalimplantsh')}`} title='Dental Implants' onClick={linkClick}>{t("implants")}</Link>
                                    </li>
                                    <li>
                                    <Link to={`/${t('dentalbridgesh')}`} title='Dental Bridges' onClick={linkClick}>{t("dentalbridges")}</Link>
                                    </li>
                                    <li style={{width:`${width}`}}>
                                    <Link to={`/${t('generaldentistryh')}`} title='General Dentistry' onClick={linkClick} >{t("general")}</Link>
                                    </li>
                                    <li>
                                    <Link to={`/${t('orthodonticsh')}`} title='Orthodontics' onClick={linkClick}>{t("orthodontics")}</Link>
                                    </li>
                                    <li>
                                    <Link to={`/${t('dentalveneersh')}`} title='Dental Veneers' onClick={linkClick}>{t("veneers")}</Link>
                                    </li>
                                    <li>
                                    <Link to={`/${t('denturesh')}`} title='Dentures' onClick={linkClick}>{t("dentures")}</Link>
                                    </li>
                                </ul>
                                </div>
                            </div>
                            </div>
                            <div className="col-xl-3 col-lg-3 col-sm-6">
                            <div className="footer-widget mb-30">
                                <div className="f-widget-title">
                                <h2 style={{color:"black"}}>{t("contactus")}</h2>
                                </div>
                                <div className="f-contact">
                                <ul>
                                    <li>
                                    <img src={location} className='icon' alt='locicon' style={{width:"60px",height:"60px"}}/>
                                    <span>
                                    <p style={{paddingTop:"5px",lineHeight:"20px",color:"black",fontWeight:"400"}}>
                                    Rruga Bardhok Biba, <br/>
                                    Pall. Golden Trema, <br/>
                                    Shk. B, Kati 4, Tiranë
                                    </p>
                                    </span>
                                    </li>
                                    <li>
                                    <img src={phone} className='icon' alt="phoneicon"  style={{width:"60px",height:"60px"}}/>
                                    <span>
                                        <p style={{paddingTop:`${padValue}`,lineHeight:"20px",color:"black",fontWeight:"400"}}>
                                        {t("simNum")}
                                        <br />
                                        {/* {i18n.language === "it"? "+39 351 949 5868" : ""} */}
                                        </p>
                                    </span>
                                    </li>
                                    <li>
                                    <img src={mail} className='icon' alt='mailicon' style={{width:"65px",height:"55px"}}/>
                                    <span>
                                        <Link to="mailto:hello@andent.al" style={{color:"black",fontWeight:"400"}}>
                                            <p style={{paddingTop:"25px",lineHeight:"20px",color:"black"}}>
                                            {t("emailLink")}
                                            </p>
                                        </Link>
                                    </span>
                                    </li>
                                </ul>
                                </div>
                            </div>
                            </div>
                        </div>
                        <div>
                            <center>
                                <p>
                                    Copyright 2024 - Andent Dental Clinic 
                                </p>
                                <p>
                                    <span>
                                        <Link to='https://www.iprobo.com/' target="_blank" onClick={sendAnalytics}
                                        rel="noreferrer" style={{paddingLeft:"8px"}}><img style={{height:"40px",width:"40px"}} src={footerimg} alt='poweredby'/></Link>
                                    </span>
                                </p>
                            </center>
                        </div>
                    </div>
                </div>
        </footer>

    </>
  )
}

export default Main